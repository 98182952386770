<head>
    <title>{{ 'STATIC_PAGE.SECTION_ONE_TITLE' | i18nTranslate }}</title>
    <meta name="description" content="Automate your promotional campaigns with our Hyper-Personalised Marketing Automation platform using AI technology. Join our Shopify marketing automation Platform today.">
    <meta property="og:title" content="">
    <meta property="og:description" content="">

    <meta name="google-site-verification" content="DpWrmMpLA12uGwPRbBz6DFXebgChbGoE4y6u2SzwT0Q" />
</head>

<body>
    <div *ngIf="!loaderRemoved" class="qb-preloader" (transitionend)="loadingComplete()" [ngClass]="hideLoader? 'qb-remove-loader': ''">
        <svg id="qb-preloader-icon" width="214" height="208" viewBox="0 0 214 208" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="qb-preloader-icon-path" d="M177.277 190.168C187.578 190.471 197.665 193.171 206.737 198.051L207.139 198.274L209.467 199.565L212.051 196.635L210.925 195.097C210.924 195.096 210.923 195.094 210.921 195.092C204.681 186.471 200.558 176.501 198.887 165.99C197.216 155.477 198.045 144.718 201.306 134.585L201.311 134.571C208.323 112.251 207.449 88.198 198.835 66.4455C190.221 44.6929 174.39 26.5628 153.998 15.0946C133.605 3.62641 109.89 -0.48275 86.828 3.45602C63.7659 7.39479 42.7588 19.142 27.3285 36.7284C11.8982 54.3148 2.98267 76.6712 2.07657 100.05C1.17046 123.428 8.32887 146.408 22.3517 165.136C36.3746 183.863 56.4094 197.201 79.0975 202.913C101.786 208.625 125.748 206.364 146.967 196.509L146.979 196.503C156.437 192.031 166.819 189.86 177.277 190.168ZM148.4 136.451C149.046 139.837 150.193 143.094 151.79 146.118C148.628 144.915 145.291 144.183 141.888 143.957C135.977 143.565 130.065 144.712 124.73 147.287C114.603 152.136 103.097 153.281 92.2129 150.523C81.3283 147.766 71.7547 141.28 65.1577 132.194C58.5606 123.108 55.3576 111.996 56.1057 100.793C56.8538 89.5895 61.5055 79.0031 69.2516 70.8749C76.7677 63.0049 86.7348 57.9224 97.5186 56.4608C108.303 54.9991 119.263 57.2452 128.603 62.8308C137.943 68.4164 145.108 77.01 148.923 87.2022C152.736 97.3912 152.975 108.573 149.601 118.916C147.697 124.56 147.283 130.6 148.4 136.451Z" fill="#D9D9D9" stroke="black" stroke-width="4" stroke-linecap="square"/>
        </svg>  
    </div>
    <div class="qb-landing-page">
        <app-static-header [coverLoaded]="showCover"></app-static-header>
        <div class="hero-section">
            <div class="hero-left-section">
                    <h1 class="line-one">{{ 'STATIC_PAGE.HERO_CONTENT_PART1' | i18nTranslate }}</h1>
                    <h1 class="line-two">
                      <span class="highlight">{{ 'STATIC_PAGE.HERO_CONTENT_PART2_QUBRIUX' | i18nTranslate }}
                        <span class="qb-highlight-underline">
                            <svg width="240" height="15" viewBox="0 0 240 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 13.8077C94.0589 -1.85149 146.233 -2.68159 239.292 13.8077" stroke="#D71920" stroke-width="2"/>
                                </svg>
                                
                        </span>
                      </span>
                      {{ 'STATIC_PAGE.HERO_CONTENT_PART2_REST' | i18nTranslate }}
                    </h1>
                    <h1 class="line-three">{{ 'STATIC_PAGE.HERO_CONTENT_PART3' | i18nTranslate }}</h1>
                    <!-- <div class="hero-btn">
                        <button class="qb-p-btn qb-sm-btn" (click)="navigateToPath(false, false)">
                            {{'STATIC_PAGE.LOG_IN' | i18nTranslate}}
                        </button>
                    </div> -->
            </div>
            <div class="hero-right-section">
                <img style="width: 100%;" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/laptop+(1).svg" alt="" class="svg-bottom-right" />
            </div>
        </div>

        <div class="qb-about-us">
            <div class="qb-aboutus-heading">
                <h4 style="font-weight: 600;">{{ 'STATIC_PAGE.WELCOME_TO_QUBRIUX' | i18nTranslate }}</h4> <br>
                <p style="text-align: center; padding: 0px 170px;">{{ 'STATIC_PAGE.ABOUT_PARA' | i18nTranslate }}</p>
            </div>
            <div class="grid-container">
                <div class="grid-item" *ngFor="let item of items">
                  <div class="icon" [innerHTML]="item.svg | safeHtml"></div>
                  <h4 class="item-heading">{{ item.heading | i18nDynamicTranslate : 'STATIC_PAGE' }}</h4>
                  <p class="item-paragraph">{{ item.para | i18nDynamicTranslate : 'STATIC_PAGE'}}</p>
                </div>
              </div>
        </div>
        <div class="adv-1">
            <h2 class="adv-1-header">
              {{ 'STATIC_PAGE.ADV_HEADER_LINE1' | i18nTranslate }}<br />
              {{ 'STATIC_PAGE.ADV_HEADER_LINE2' | i18nTranslate }}
            </h2>
            <p class="adv-1-para">
              {{ 'STATIC_PAGE.ADV_PARAGRAPH' | i18nTranslate }}
            </p>
            <div class="adv-1-btn" style="padding-top: 32px;">
              <!-- <button class="qb-p-btn qb-sm-btn" (click)="navigateToPath(false, false)">
                {{ 'STATIC_PAGE.LOG_IN' | i18nTranslate }}
              </button> -->
            </div>
          </div>

          <div class="adv-2">
            <h2 class="adv-2-header">
                {{ 'STATIC_PAGE.DONT_HAVE_QUBRIUX' | i18nTranslate }}
              </h2>
              <p class="adv-2-para">
                {{ 'STATIC_PAGE.DONT_HAVE_QUBRIUX_PARA' | i18nTranslate }}
              </p>
          </div>
           <app-static-footer></app-static-footer>
       
    </div>
</body>