import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, forkJoin } from "rxjs";
import { environment } from "src/environments/environment";
import { TokenStorageService } from "../auth/token-storage.service";

@Injectable()
export class ApiCallService {
  private apiUrl = "https:" + "//" + environment.apiUrl;
  private pygApiUrl = window.location.protocol + "//" + environment.pygApiUrl;
  private qfferAPIURL =
    window.location.protocol + "//" + environment.qfferApiURL;

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService
  ) {}

  getIPinfo() {
    let url =
      "https://api.ipstack.com/check?access_key=9345e1c87a93010778cb40d7daa58b9a";
    return this.http.get(url);
  }

  updatePForm(data) {
    let url = this.apiUrl + "/formPartnership/updateForm";
    return this.http.post(url, data);
  }

  lightspeedAPIOnboard_OLD(code) {
    let url = this.apiUrl + "/lightspeedAPI/onboardlightspeedAccount";
    let data = {
      merchantCode: code,
    };
    return this.http.post(url, data);
  }
  lightspeedAPIOnboard(code) {
    let url = this.apiUrl + "/lightspeedAPI/onboardLightspeedAccount";
    let data = {
      code: code,
      shop: null,
      accessToken: this.tokenStorage.getToken(),
      merchantId: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, data);
  }

  isLightspeedAPIOnboard(merchantCode) {
    let url = this.apiUrl + "/lightspeedAPI/islightspeedMerchantOnboarded";
    let params = new HttpParams();
    params = params.append("merchantCode", merchantCode);
    return this.http.get(url, { params: params });
  }

  getOffers(cId) {
    // let paramValue = "7888167709"
    let paramValue = cId;
    let params = new HttpParams().set("mobileNumber", paramValue);
    let url = this.apiUrl + "/getCustomerOffer";
    return this.http.get(url, { params: params });
  }

  getItems() {
    let url = this.apiUrl + "/getAllItems";
    return this.http.get(url);
  }

  purchaseUpdate(data) {
    data = data[0];
    let url = this.apiUrl + "/purchaseUpdate";
    return this.http.post(url, data);
  }

  getConstantProducts() {
    let url = this.apiUrl + "/getOfferManagementConstants";
    let params = new HttpParams();
    let id: string = this.tokenStorage.getMerchantId();
    params = params.append("merchantId", id);
    return this.http.get(url, { params: params });
  }

  offerUpdate(datas) {
    let data = datas;
    let url = this.apiUrl + "/OfferMasterUpdate";
    return this.http.post(url, data);
  }

  getCreditsThreshold(channel, requiredCredits) {
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (requiredCredits !== null) {
      params = params.append("requiredCredits", requiredCredits);
    }
    if (channel !== null) {
      params = params.append("channel", channel);
    }
    let url = this.apiUrl + "/credits/isThresholdReached";
    return this.http.get(url, { params: params });
  }

  getRemainingCredits() {
    let params = new HttpParams().set(
      "merchantId",
      this.tokenStorage.getMerchantId()
    );
    let url = this.apiUrl + "/credits/getMerchantCredits";
    return this.http.get(url, { params: params });
  }

  getMasterOffers() {
    let url = this.apiUrl + "/getAllMasterOffers";
    return this.http.get(url);
  }

  getMasterOfferDetails(id: string) {
    let params = new HttpParams().set("offerId", id);
    let url = this.apiUrl + "/getMasterOfferDetails";
    return this.http.get(url, { params: params });
  }

  deleteOffer(id) {
    let params = new HttpParams();
    params = params.append("offerId", id);
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    let url = this.apiUrl + "/deleteMasterOffer";
    return this.http.delete(url, { params: params });
  }

  getVariantObjbyId(variantIdArray) {
    let url = this.apiUrl + "/getVariantsByIds";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    for (var i = 0; i < variantIdArray.length; i++) {
      params = params.append("variantIds", variantIdArray[i]);
    }
    return this.http.get(url, { params: params });
  }

  getShopifyData(data) {
    let url = this.apiUrl + "/getShopifyData";
    return this.http.post(url, data);
  }

  fetchUserMerchantData(mid?) {
    let url = this.apiUrl + "/userSettings/getUserMerchantDetails";
    if (mid != "0" && this.tokenStorage.getIsSuperAdmin() == "true") {
      let params = new HttpParams();
      let id: string;
      if (mid == null) {
        id = this.tokenStorage.getMerchantId();
      } else {
        id = mid;
      }
      params = params.append("merchantId", id);
      return this.http.get(url, { params: params });
    } else {
      return this.http.get(url);
    }
  }
  getSuperAdminList() {
    let url = this.apiUrl + "/auth/admin/getMerchants";
    return this.http.get(url);
  }

  getMerchantDetails() {
    let url = this.apiUrl + "/userSettings/getMerchantDetails";
    let params = new HttpParams();
    let id: string = this.tokenStorage.getMerchantId();
    params = params.append("merchantId", id);
    return this.http.get(url, { params: params });
  }

  onboardShopifyUser(shop, code) {
    let url = this.apiUrl + "/shopifyAPI/onboardShopifyAccountV2";
    let data = {
      shop: shop,
      code: code,
      merchantId: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, data);
  }

  emailToShopLink(email) {
    let url = this.apiUrl + "/emailToShopLink";
    let data = {
      email: email,
    };
    return this.http.post(url, data);
  }

  getSuppressedContacts(requestBody, pageSize, pageIndex) {
    let url = this.apiUrl + "/suppressions/getPaginatedList";
    let requestParams = new HttpParams();
    requestParams = requestParams.append("recordsPerPage", pageSize);
    requestParams = requestParams.append("pageNumber", pageIndex);
    return this.http.post(url, requestBody, { params: requestParams });
  }

  searchSuppressedContacts(requestBody, pageSize, pageIndex) {
    let url = this.apiUrl + "/suppressions/getPaginatedSearchList";
    let requestParams = new HttpParams();
    requestParams = requestParams.append("recordsPerPage", pageSize);
    requestParams = requestParams.append("pageNumber", pageIndex);
    return this.http.post(url, requestBody, { params: requestParams });
  }

  addEmailToSuppressionList(requestBody) {
    let url = this.apiUrl + "/suppressions/addContact";
    return this.http.post(url, requestBody);
  }

  deleteEmailFromSuppressionList(requestBody) {
    console.log(requestBody);
    let url = this.apiUrl + "/suppressions/deleteContact";
    return this.http.post(url, requestBody);
  }

  getsmsSuppressionListDomain() {
    let url = this.apiUrl + "/merchantDomain/headerIds";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getObjectiveStatus() {
    let url = this.apiUrl + "/getBrandingAndDomainStatus";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  updateObjective(name) {
    let url =
      this.apiUrl +
      "/objectives/updateObjectives?" +
      "merchantId=" +
      this.tokenStorage.getMerchantId() +
      "&platform=" +
      this.tokenStorage.getMerchantOnlineStore() +
      "&objectiveName=" +
      name;
    return this.http.put(url, null);
  }

  getOnboardingProgressStatus() {
    let url = this.apiUrl + "/getOnboardingProgressStatus";
    let params = new HttpParams();
    let id: string = this.tokenStorage.getMerchantId();
    params = params.append("merchantId", id);

    return this.http.get(url, { params: params });
  }

  // fetchMockedCategotyData() {
  //   let url = "http://www.mocky.io/v2/5d4930d132000084006009f8";
  //   return this.http.get(url);
  // }

  sendTestEngageEmail(
    type,
    channel,
    templateID,
    emailID,
    phone,
    isReward,
    rewardType
  ) {
    let request = {
      type: type,
      merchantId: this.tokenStorage.getMerchantId(),
      channel: channel,
      templateId: templateID,
      emailId: emailID,
      phoneNo: phone,
      isReward: isReward,
      rewardType: rewardType,
    };
    if (this.tokenStorage.getqId() !== "null") {
      request["storeId"] = this.tokenStorage.getqId();
    }
    let url = this.apiUrl + "/sendTestMailSMS";
    return this.http.post(url, request);
  }

  sendTestEngageEmailHTML(
    type,
    channel,
    templateID,
    emailID,
    phone,
    isReward,
    rewardType,
    html
  ) {
    console.log(html);
    let request = {
      type: type,
      merchantId: this.tokenStorage.getMerchantId(),
      channel: channel,
      templateId: templateID,
      emailId: emailID,
      phoneNo: phone,
      isReward: isReward,
      rewardType: rewardType,
      emailLayouHtml: html,
    };
    if (this.tokenStorage.getqId() !== "null") {
      request["storeId"] = this.tokenStorage.getqId();
    }
    let url = this.apiUrl + "/sendTestMailSMS";
    return this.http.post(url, request);
  }

  addEmailtemplate(data) {
    let url = this.apiUrl + "/campaign/MessageTemplateUpdate";
    return this.http.post(url, data);
  }

  getMasterCampaigns() {
    let params = new HttpParams();
    let id: string = this.tokenStorage.getMerchantId();
    params = params.append("merchantId", id);
    let url = this.apiUrl + "/campaign/getAllCampaignTemplates";
    return this.http.get(url, { params: params });
  }

  getEmailTemplates() {
    let params = new HttpParams();
    let id: string = this.tokenStorage.getMerchantId();
    params = params.append("merchantId", id);
    params = params.append("messageTemplateType", "EMAIL_TEMPLATE");
    let url = this.apiUrl + "/campaign/getMessageTemplates";
    return this.http.get(url, { params: params });
  }

  getMessageTemplate(id) {
    let params = new HttpParams();
    params = params.append("templateId", id);
    let url = this.apiUrl + "/campaign/getMessageTemplate";
    return this.http.get(url, { params: params });
  }

  deleteMessageTemplate(id) {
    let url = this.apiUrl + "/campaign/deleteMessageTemplate";
    let params = new HttpParams();
    params = params.append("templateId", id);
    return this.http.delete(url, { params: params });
  }

  setCampaign(data) {
    let url = this.apiUrl + "/campaign/CampaignTemplateUpdate";
    return this.http.post(url, data);
  }

  getDetailMasterOffers() {
    let params = new HttpParams();
    let id: string = this.tokenStorage.getMerchantId();
    let url = this.apiUrl + "/getAllMasterOffersInfo";
    params = params.append("merchantId", id);
    return this.http.get(url, { params: params });
  }

  getAllCustomerSegments() {
    let params = new HttpParams();
    let id: string = this.tokenStorage.getMerchantId();
    let url = this.apiUrl + "/campaign/getCustomerSegments";
    params = params.append("merchantId", id);
    return this.http.get(url, { params: params });
  }

  deleteCampaignTemplate(campaigntemplateId) {
    let params = new HttpParams();
    let url = this.apiUrl + "/campaign/deleteCampaignTemplate";
    params = params.append("campaignId", campaigntemplateId);
    return this.http.delete(url, { params: params });
  }

  createCampaign(campaign) {
    let url = this.apiUrl + "/campaign/CampaignUpdate";
    return this.http.post(url, campaign);
  }

  launchCampaign(campaign) {
    let url = this.apiUrl + "/campaign/CampaignLaunch";
    return this.http.post(url, campaign);
  }

  getTotalRevenue(data) {
    let url = this.apiUrl + "/analytics/email/strategy/revenue";
    return this.http.post(url, data);
  }

  getSearchCampaign(
    merchantID,
    name,
    page,
    size,
    statusList,
    segmentList,
    tierList,
    customerList,
    favourite
  ) {
    let baseApiUrl =
      "/campaign-strategy/merchant/" + merchantID + "/strategies/search?";
    if (name != null)
      baseApiUrl += "name=" + name + "&page=" + page + "&size=" + size;
    else baseApiUrl += "&page=" + page + "&size=" + size;
    if (segmentList != null)
      segmentList.forEach((element) => {
        baseApiUrl += "&segment=" + element;
      });
    if (tierList != null)
      tierList.forEach((element) => {
        baseApiUrl += "&tier=" + element;
      });
    if (customerList != null)
      customerList.forEach((element) => {
        baseApiUrl += "&customerList=" + element;
      });
    if (statusList != null)
      statusList.forEach((status) => {
        baseApiUrl += "&status=" + status;
      });
    if (favourite != null) baseApiUrl += "&isFavorite=" + favourite;
    if (this.tokenStorage.getqId() != "null") {
      baseApiUrl += "&storeId=" + this.tokenStorage.getqId();
    }
    let url = this.apiUrl + baseApiUrl;
    return this.http.get(url);
  }

  getRevenueGraph(startDate, endDate, type) {
    let url = this.apiUrl + "/analytics/getRevenueTimeSeries";
    let data = {
      startDate: startDate,
      endDate: endDate,
      type: type,
      merchantId: this.tokenStorage.getMerchantId(),
      maxDataPointCount: 30,
    };
    return this.http.post(url, data);
  }

  addStrategyTofavourite(merchantID, strategyID, value) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      merchantID +
      "/strategies/" +
      strategyID +
      "/favorite/" +
      value;
    return this.http.put(url, null);
  }

  getAllCampaigns() {
    let id: string = this.tokenStorage.getMerchantId();
    let url = this.apiUrl + "/campaign/getAllCampaignBasicDetails";
    let params = new HttpParams();
    params = params.append("merchantId", id);
    return this.http.get(url, { params: params });
  }

  getcampaignDetails(id) {
    let url = this.apiUrl + "/campaign/getCampaignDetails";
    let params = new HttpParams();
    params = params.append("campaignId", id);
    return this.http.get(url, { params: params });
  }

  getAllMessageTemplateVariables(type, subtype) {
    let url = this.apiUrl + "/campaign/getMessageTemplateVariables";
    let params = new HttpParams();
    params = params.append("messageTemplateType", type);
    params = params.append("messageTemplateSubType", subtype);
    return this.http.get(url, { params: params });
  }

  getAllMessageTemplateVariablesforOffer(offerId) {
    let url = this.apiUrl + "/campaign/getMessageTemplateVariablesForOffer";
    let params = new HttpParams();
    // params = params.append("messageTemplateType", type);
    // if (type === "EMAIL_DELIVERY") {
    //   params = params.append("messageTemplateSubType", subtype);
    // }
    if (offerId !== null) {
      params = params.append("offerId", offerId);
    }
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getCampaignInstances(id) {
    let url = this.apiUrl + "/campaign/getCampaignInstances";
    let params = new HttpParams();
    params = params.append("campaignId", id);
    return this.http.get(url, { params: params });
  }

  uploadImage(data: FormData) {
    let url = this.apiUrl + "/images/ImageUpload";
    return this.http.post(url, data);
  }

  createEmailLayout(data) {
    let url = this.apiUrl + "/campaign/emailLayoutCreate";
    return this.http.post(url, data);
  }

  updateEmailLayout(data) {
    let url = this.apiUrl + "/campaign/emailLayoutUpdate";
    return this.http.put(url, data);
  }

  addEmailContent(data) {
    let url = this.apiUrl + "/campaign/campaignEmailLayoutCreate";
    return this.http.post(url, data);
  }

  getEmailContent(id) {
    let url = this.apiUrl + "/campaign/getCampaignEmailLayout";
    let mId = this.tokenStorage.getMerchantId();
    let params = new HttpParams();
    params = params.append("id", id);
    params = params.append("merchantId", mId);
    return this.http.get(url, { params: params });
  }

  updateEmailContent(data) {
    let url = this.apiUrl + "/campaign/campaignEmailLayoutUpdate";
    // let  mId = this.tokenStorage.getMerchantId()
    // let params = new HttpParams();
    // params = params.append('id',id);
    // params = params.append('merchantId',mId);
    return this.http.post(url, data);
  }

  listEmailLayout() {
    let url = this.apiUrl + "/campaign/getAllEmailLayouts";
    let id: string = this.tokenStorage.getMerchantId();
    let params = new HttpParams();
    params = params.append("merchantId", id);
    params = params.append("type", "GENERIC");
    params = params.append("type", "SURVEY");
    return this.http.get(url, { params: params });
  }
  getSurveyEmailTemplate() {
    let url = this.apiUrl + "/campaign/getAllEmailLayouts";
    let id: string = this.tokenStorage.getMerchantId();
    let params = new HttpParams();
    params = params.append("merchantId", id);
    params = params.append("type", "SURVEY");
    return this.http.get(url, { params: params });
  }
  listEmailLayoutFilter(type) {
    let url = this.apiUrl + "/campaign/getAllEmailLayouts";
    let id: string = this.tokenStorage.getMerchantId();
    let params = new HttpParams();
    params = params.append("merchantId", id);
    params = params.append("type", type);
    return this.http.get(url, { params: params });
  }

  getEmailLayoutDetail(id) {
    let url = this.apiUrl + "/campaign/getEmailLayout";
    let params = new HttpParams();
    let mId = this.tokenStorage.getMerchantId();
    params = params.append("id", id);
    params = params.append("merchantId", mId);
    return this.http.get(url, { params: params });
  }

  deleteEmailLayout(id) {
    let url = this.apiUrl + "/campaign/deleteEmailLayout";
    let params = new HttpParams();
    let mId = this.tokenStorage.getMerchantId();
    params = params.append("id", id);
    params = params.append("merchantId", mId);
    return this.http.delete(url, { params: params });
  }

  getCustomerSegmentVariables() {
    let url = this.apiUrl + "/getCustSegmentAttributeVariables";
    let params = new HttpParams();
    let id = this.tokenStorage.getMerchantId();
    params = params.append("merchantId", id);
    return this.http.get(url, { params: params });
  }

  updateCustomerSegment(data) {
    let url = this.apiUrl + "/customerSegmentUpdate";
    return this.http.post(url, data);
  }

  getCustomerSegments() {
    let url = this.apiUrl + "/campaign/getCustomerSegments";
    let params = new HttpParams();
    let id = this.tokenStorage.getMerchantId();
    params = params.append("merchantId", id);
    return this.http.get(url, { params: params });
  }

  deleteCustomerSegment(segmentId) {
    let url = this.apiUrl + "/deleteCustomerSegment";
    let params = new HttpParams();
    params = params.append("segmentId", segmentId);
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.delete(url, { params: params });
  }

  getCustomerSegment(segmentIdn) {
    let url = this.apiUrl + "/getCustomerSegment";
    let params = new HttpParams();
    params = params.append("segmentId", segmentIdn);
    let id: string = this.tokenStorage.getMerchantId();
    params = params.append("merchantId", id);
    return this.http.get(url, { params: params });
  }

  getSearchCount(customerName, segmentIDs) {
    let url =
      this.apiUrl + "/merchantData/getMultiSegmentSearchedCustomerCount";
    if (segmentIDs.indexOf("ALL") == -1 && segmentIDs.length != 0) {
      let params = new HttpParams()
        .append("merchantId", this.tokenStorage.getMerchantId())
        .append("customerName", customerName)
        .append("segmentIds", segmentIDs);
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
      return this.http.get(url, { params: params });
    } else {
      let params = new HttpParams()
        .append("merchantId", this.tokenStorage.getMerchantId())
        .append("customerName", customerName);
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
      return this.http.get(url, { params: params });
    }
  }

  getViewCustomers(recordsPerPage, pageNumber) {
    let url = this.apiUrl + "/merchantData/getCustomersWithPagination";
    let params = new HttpParams();
    let id: string = this.tokenStorage.getMerchantId();
    params = params.append("merchantId", id);
    params = params.append("recordsPerPage", recordsPerPage);
    params = params
      .append("pageNumber", pageNumber)
      .append("userId", this.tokenStorage.getUserId());
    return this.http.get(url, { params: params });
  }

  getTotalCustomerCount() {
    let url = this.apiUrl + "/merchantData/getCustomerCount";
    let params = new HttpParams();
    let id: string = this.tokenStorage.getMerchantId();
    params = params.append("merchantId", id);
    return this.http.get(url, { params: params });
  }

  getRevenue(startDate, endDate) {
    let url = this.apiUrl + "/analytics/getTotalRevenue";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (startDate != null && endDate != null) {
      params = params.append("startDate", startDate);
      params = params.append("endDate", endDate);
    }
    return this.http.get(url, { params: params });
  }

  getThisWeekCustomerCount(startDate, endDate, segmentIDs) {
    let url = this.apiUrl + "/analytics/getThisWeekCustomerCount";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("startDate", startDate);
    params = params.append("endDate", endDate);
    if (segmentIDs != null) params = params.append("segmentId", segmentIDs);
    return this.http.get(url, { params: params });
  }

  setSigninCustomer(dataGroup) {
    let url = this.apiUrl + "/auth/signin";
    let data = dataGroup;
    return this.http.post(url, data);
  }
  setSignupShopifyCustomer(data) {
    let url = this.apiUrl + "/auth/signup";
    return this.http.post(url, data);
  }
  setSignUpCustomer(dataGroup) {
    let url = this.apiUrl + "/auth/signup";
    let data = {
      name: dataGroup.name,
      email: dataGroup.email.toLowerCase(),
      phone: dataGroup.phone,
      role: ["admin"],
      password: dataGroup.password,
      posProvider: dataGroup.posProvider,
      posAuthentication: dataGroup.posAuthentication,
      platformGenerated: dataGroup.platformGenerated,
    };
    return this.http.post(url, data);
  }

  getCustomers(
    merchantID,
    revenueTime,
    noOfOrderTime,
    recordsPerPage,
    pageNumber,
    id
  ) {
    let url = this.apiUrl + "/merchantData/getCustomersWithPagination";
    if (id.indexOf("ALL") == -1 && id.length != 0) {
      let params = new HttpParams()
        .append("merchantId", merchantID)
        .append("recordsPerPage", recordsPerPage)
        .append("pageNumber", pageNumber)
        .append("revenueTime", revenueTime)
        .append("noOfOrderTime", noOfOrderTime)
        .append("segmentIds", id)
        .append("userId", this.tokenStorage.getUserId());
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
      return this.http.get(url, { params: params });
    } else {
      let params = new HttpParams()
        .append("merchantId", merchantID)
        .append("recordsPerPage", recordsPerPage)
        .append("pageNumber", pageNumber)
        .append("revenueTime", revenueTime)
        .append("noOfOrderTime", noOfOrderTime)
        .append("userId", this.tokenStorage.getUserId());
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
      return this.http.get(url, { params: params });
    }
  }

  getCustomersV2(merchantID, recordsPerPage, pageNumber, id) {
    let url = this.apiUrl + "/merchantData/getCustomersWithPagination/V2";
    if (id.indexOf("ALL") == -1 && id.length != 0) {
      let params = new HttpParams()
        .append("merchantId", merchantID)
        .append("recordsPerPage", recordsPerPage)
        .append("pageNumber", pageNumber)
        .append("segmentIds", id)
        .append("userId", this.tokenStorage.getUserId());
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
      return this.http.get(url, { params: params });
    } else {
      let params = new HttpParams()
        .append("merchantId", merchantID)
        .append("recordsPerPage", recordsPerPage)
        .append("pageNumber", pageNumber)
        .append("userId", this.tokenStorage.getUserId());
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
      return this.http.get(url, { params: params });
    }
  }

  getGraphStats(startDate, endDate, type) {
    let url =
      this.apiUrl + "/analytics/getStandardSegmentCustomerCountTimeSeries";
    let data = {
      startDate: startDate,
      endDate: endDate,
      type: type,
      merchantId: this.tokenStorage.getMerchantId(),
      maxDataPointCount: 30,
    };
    return this.http.post(url, data);
  }

  getRecentOrders(merchantID, customerID) {
    let url = this.apiUrl + "/merchantData/getRecentOrder";
    let params = new HttpParams()
      .append("merchantId", merchantID)
      .append("customerId", customerID);
    return this.http.get(url, { params: params });
  }

  getReviews(merchantID, merchantCustomerID) {
    let url = this.apiUrl + "/merchantData/getRecentSurvey";
    let params = new HttpParams()
      .append("merchantId", merchantID)
      .append("merchantCustomerId", merchantCustomerID);
    return this.http.get(url, { params: params });
  }

  searchCustomers(
    merchantID,
    customerName,
    revenueTime,
    noOfOrderTime,
    size,
    index,
    segemntIDs,
    isLead?
  ) {
    let url = this.apiUrl + "/merchantData/advanceCustomerSearch/";
    if (segemntIDs.indexOf("ALL") != -1 || segemntIDs.length == 0) {
      let data = {
        merchantId: merchantID,
        searchString: customerName,
        noOfOrderTime: noOfOrderTime,
        revenueTime: revenueTime,
        recordsPerPage: size,
        pageNumber: index,
        isLead: isLead ? isLead : false,
        userId: this.tokenStorage.getUserId(),
      };
      if (this.tokenStorage.getqId() !== "null") {
        data["storeId"] = this.tokenStorage.getqId();
      }
      return this.http.post(url, data);
    } else {
      let data = {
        merchantId: merchantID,
        searchString: customerName,
        noOfOrderTime: noOfOrderTime,
        revenueTime: revenueTime,
        recordsPerPage: size,
        pageNumber: index,
        segmentIds: segemntIDs,
        isLead: isLead ? isLead : false,
        userId: this.tokenStorage.getUserId(),
      };
      if (this.tokenStorage.getqId() !== "null") {
        data["storeId"] = this.tokenStorage.getqId();
      }
      return this.http.post(url, data);
    }
  }

  getCustomerDetails(customerId) {
    let url = this.apiUrl + "/merchantData/getCustomerDetails/";
    let params = new HttpParams()
      .append("merchantId", this.tokenStorage.getMerchantId())
      .append("userId", this.tokenStorage.getUserId())
      .append("customerId", customerId)
      .append("revenueTime", "ALL")
      .append("noOfOrderTime", "ALL");
    return this.http.get(url, { params: params });
  }

  advanceCustomerSearch(merchantID, searchString, recordsPerPage, pageNumber) {
    let url = this.apiUrl + "/merchantData/advanceCustomerSearch/";
    let params = new HttpParams()
      .append("merchantId", merchantID)
      .append("searchString", searchString)
      .append("recordsPerPage", recordsPerPage)
      .append("pageNumber", pageNumber)
      .append("userId", this.tokenStorage.getUserId());
    return this.http.get(url, { params: params });
  }

  getSegementPaginated(merchantID, segments) {
    let url = this.apiUrl + "/merchantData/getSegmentCustomerCount";
    if (segments.indexOf("ALL") == -1 && segments.length != 0) {
      let params = new HttpParams()
        .append("merchantId", merchantID)
        .append("segmentIds", segments);
      return this.http.get(url, { params: params });
    }
  }

  setPurchaseOrder(paymentGatewayProvider, userId, currency) {
    let url = this.apiUrl + "/purchaseOrder/CreatePurchaseOrder";

    let id = this.tokenStorage.getMerchantId();

    let data = {
      paymentGatewayProvider: paymentGatewayProvider,
      currency: currency,
      merchantid: id,
      subscriptionMasterPlanId: userId,
    };
    return this.http.post(url, data);
  }

  setPaymentStatus(response) {
    let url = this.apiUrl + "/purchaseOrder/payment/success";

    let params = new HttpParams()
      .append("razorpay_payment_id", response.razorpay_payment_id)
      .append("razorpay_order_id", response.razorpay_order_id)
      .append("razorpay_signature", response.razorpay_signature);

    let data = {
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_order_id,
      razorpay_signature: response.razorpay_signature,
    };
    return this.http.post(url, params);
  }

  productionTest() {
    let url = this.apiUrl + "/purchaseOrder/test";
    let data;
    return this.http.post(url, data);
  }

  setRegistrationForm(token: string) {
    let url = this.apiUrl + "/auth/registrationConfirm";

    let params = new HttpParams().append("token", token);
    return this.http.post(url, params);
  }

  getAllSubscriptionMasterPlan(currency: string) {
    let url = this.apiUrl + "/purchaseOrder/getAllSubscriptionMasterPlans";
    let params = new HttpParams().append("currency", currency);
    return this.http.get(url, { params: params });
  }

  getSubscribedPlans() {
    let url = this.apiUrl + "/purchaseOrder/getAllSubscriptionPlans";
    let id = this.tokenStorage.getMerchantId();
    let params = new HttpParams()
      .append("merchantId", id)
      .append("status", "ACTIVE");
    return this.http.get(url, { params: params });
  }
  sendContactInfo(dataform) {
    let url = this.apiUrl + "/sendEmail";
    let data = {
      toEmail: "greyfoxsupport@skellam.ai",
      fromEmail: dataform[1],
      subject: dataform[0],
      body: dataform[2],
    };
    return this.http.post(url, data);
  }

  sendContactInfoforDemo(Toaddress, fromaddress, subject, body) {
    let url = this.apiUrl + "/sendEmail";
    let data = {
      toEmail: Toaddress,
      fromEmail: fromaddress,
      subject: subject,
      body: body,
    };
    return this.http.post(url, data);
  }

  getStandardCampaignTemplates() {
    let id = this.tokenStorage.getMerchantId();
    let params = new HttpParams();
    params = params.append("merchantId", id);
    let url = this.apiUrl + "/campaign/getStandardCampaignTemplates";
    return this.http.get(url, { params: params });
  }

  resetPasswordrequest(id: string) {
    let url = this.apiUrl + "/auth/sendResetPasswordEmail";
    let data = {
      email: id["emailid"],
    };
    return this.http.post(url, data);
  }
  resetPasswordConf(token: string, newpassword: string) {
    let url = this.apiUrl + "/auth/resetPassword";
    let data = {
      token: token,
      password: newpassword,
    };
    return this.http.post(url, data);
  }

  getCurrentSubscriptionPlan() {
    let url = this.apiUrl + "/purchaseOrder/getCurrentSubscriptionPlan";
    let params = new HttpParams();
    let userName: string = this.tokenStorage.getUserEmail();
    let data = {
      userEmail: userName,
      merchantId: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, data);
  }

  getAnalytics(startDate, endDate, isLead?, isCustomer?,storeId?) {
    let url = this.apiUrl + "/analytics/getAnalytics";
    let data = {
      startDate: startDate,
      endDate: endDate,
      merchantId: this.tokenStorage.getMerchantId(),
      storeId: storeId ?? (this.tokenStorage.getqId() === "null" ? null : this.tokenStorage.getqId()),
      isLead: isLead ? isLead : false,
      isCustomer:
        isCustomer === null || isCustomer === undefined || isCustomer
          ? true
          : false,
    };
    return this.http.post(url, data);
  }

  getActiveCampaignTimeSeries(startDate, endDate, period) {
    let url = this.apiUrl + "/analytics/getActiveCampaignsTimeSeries";
    let data = {
      startDate: startDate,
      endDate: endDate,
      type: period,
      maxDataPointCount: 30,
      merchantId: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, data);
  }

  getRewardsRedeemedTimeSeries(startDate, endDate, period) {
    let url = this.apiUrl + "/analytics/getRewardsRedeemedTimeSeries";
    let data = {
      startDate: startDate,
      endDate: endDate,
      type: period,
      maxDataPointCount: 30,
      merchantId: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, data);
  }

  getRevenueTimeSeries(startDate, endDate, period) {
    let url = this.apiUrl + "/analytics/getRevenueTimeSeries";
    let data = {
      startDate: startDate,
      endDate: endDate,
      type: period,
      maxDataPointCount: 30,
      merchantId: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, data);
  }

  getcountryClient() {
    const headerDict = {
      // 'Content-Type': 'application/json',
      // 'Accept': 'application/json',
      "Access-Control-Allow-Headers": "*",
      // 'Origin':'gd.geobytes.com'
    };

    // 'Accept': 'application/json',
    //   'Access-Control-Allow-Headers': 'Content-Type',

    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    // ,{"headers": { "Origin": "cloudflare.com"}}
    // , {"headers":{"origin":"cloudflare.com"}}

    return this.http.get("www.cloudflare.com/cdn-cgi/trace", {
      headers: { Origin: "https://www.cloudflare.com" },
    });
    // return this.http.get("https://www.cloudflare.com/cdn-cgi/trace", Option: {header:{["Orgin:"]:"https://www.cloudflare.com"}})
  }

  // fetchMockedSegmentDashboardData() {
  //   let url = "http://www.mocky.io/v2/5e4cd7122d00008400c0d6a8";
  //   return this.http.get(url);
  // }

  // fetchMockedSegmentAnalyticsTimeSeries() {
  //   let url = "http://www.mocky.io/v2/5e5ca05d320000690043c20c";
  //   return this.http.get(url);
  // }

  couponCodeValidation(code, offerId: any, barrierIndex: any) {
    let url = this.apiUrl + "/isDuplicateCouponCode";
    let params = new HttpParams();
    let id = this.tokenStorage.getMerchantId();
    let posProvider = this.tokenStorage.getMerchantOnlineStore();
    params = params.append("merchantId", id);
    params = params.append("couponCode", code);
    params = params.append("posProvider", posProvider);
    if (offerId) {
      params = params.append("offerId", offerId);
      params = params.append("barrierIndex", barrierIndex);
    }
    return this.http.get(url, { params: params });
  }

  offerNameValidation(offerName: string, offerId: string) {
    let url = this.apiUrl + "/isDuplicateOfferName";
    let params = new HttpParams();
    let id = this.tokenStorage.getMerchantId();
    let posProvider = this.tokenStorage.getMerchantOnlineStore();
    params = params.append("merchantId", id);
    params = params.append("offerName", offerName);
    params = offerId ? params.append("offerId", offerId) : params;
    return this.http.get(url, { params: params });
  }

  beeEditorAuth(url, data) {
    return this.http.post(url, data);
  }

  getSegmentAnalyticsTimeSeries(startDate, endDate, period) {
    let url =
      this.apiUrl + "/analytics/getStandardSegmentCustomerCountTimeSeries";
    let data = {
      startDate: startDate,
      endDate: endDate,
      type: period,
      maxDataPointCount: 30,
      merchantId: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, data);
  }

  getSegmentAnalyticsCustomerCount() {
    let url = this.apiUrl + "/analytics/getStandardSegmentCustomerCount";
    let mId = this.tokenStorage.getMerchantId();
    let params = new HttpParams();
    params = params.append("merchantId", mId);
    return this.http.get(url, { params: params });
  }

  getRewardEmailList() {
    let url = this.apiUrl + "/campaign/getRewardEmails";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getRewardEmailDetail(id) {
    let url = this.apiUrl + "/campaign/getRewardEmail";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("id", id);
    return this.http.get(url, { params: params });
  }

  updateRewardEmail(data) {
    let url = this.apiUrl + "/campaign/updateRewardEmail";
    return this.http.put(url, data);
  }

  saveSurveyTemplate(data, operation) {
    let url = this.apiUrl + "/survey/template";
    if (operation === "CREATE") {
      return this.http.post(url, data);
    } else {
      return this.http.put(url, data);
    }
  }

  getAllSurveyTemplates() {
    let url = this.apiUrl + "/survey/templates";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getSurveyTemplate(id) {
    let url = this.apiUrl + "/survey/template";
    let params = new HttpParams();
    params = params.append("id", id);
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  updateSurveyTemplate(data) {
    let url = this.apiUrl + "/survey/template";
    return this.http.put(url, data);
  }

  scheduleSurvey(data) {
    let url = this.apiUrl + "/survey/schedule";
    return this.http.post(url, data);
  }
  getScheduledSurveyDetails(surveyID) {
    let url = this.apiUrl + "/survey/schedule/" + surveyID;
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getScheduledSurveys(page, size, storeQid?) {
    let url = this.apiUrl + "/survey/schedules";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("page", page);
    params = params.append("size", size);
    if (storeQid) {
      params = params.append("storeId", storeQid);
    } else {
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
    }
    return this.http.get(url, { params: params });
  }

  surveyScheduleStatusUpdate(data) {
    let url = this.apiUrl + "/survey/schedule/status";
    return this.http.put(url, data);
  }

  deleteSurveyTemplate(surveyId) {
    let url = this.apiUrl + "/survey/template";
    let params = new HttpParams();
    params = params.append("id", surveyId);
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.delete(url, { params: params });
  }

  getOverallSurveydashboard(storeQid?) {
    let url = this.apiUrl + "/survey/dashboard";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (storeQid) {
      params = params.append("storeId", storeQid);
    } else {
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
    }
    return this.http.get(url, { params: params });
  }

  getIndividualSurveyDashboard(surveyId) {
    let url = this.apiUrl + "/survey/dashboard/" + surveyId;
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getCustomerCountInSegmentList(segments) {
    let url = this.apiUrl + "/getCustomerCountInSegments";
    let params = new HttpParams();
    for (let i = 0; i < segments.length; i++) {
      params = params.append("segmentId", segments[i]["id"]);
    }
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (this.tokenStorage.getqId() !== "null") {
      params = params.append("storeId", this.tokenStorage.getqId());
    }
    return this.http.get(url, { params: params });
  }

  getCustomerCountInSegmentAndTiers(selectedSegmentsOrTiers) {
    let url = this.apiUrl + "/getCustomerCountInSegmentAndTiers";
    let params = new HttpParams();
    for (let i = 0; i < selectedSegmentsOrTiers.length; i++) {
      if (selectedSegmentsOrTiers[i].category.toLowerCase() == "tier") {
        params = params.append("tierId", selectedSegmentsOrTiers[i]["id"]);
      }
      if (selectedSegmentsOrTiers[i].category.toLowerCase() == "customerlist") {
        params = params.append(
          "customerList",
          selectedSegmentsOrTiers[i]["id"]
        );
      }
      if (selectedSegmentsOrTiers[i].category.toLowerCase() == "employeelist") {
        params = params.append(
          "employeeList",
          selectedSegmentsOrTiers[i]["id"]
        );
      }
      if (selectedSegmentsOrTiers[i].category.toLowerCase() == "segment")
        params = params.append("segmentId", selectedSegmentsOrTiers[i]["id"]);
    }
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (this.tokenStorage.getqId() !== "null") {
      params = params.append("storeId", this.tokenStorage.getqId());
    }
    return this.http.get(url, { params: params });
  }

  getCustomerSegmentsAndTiers() {
    let url = this.apiUrl + "/campaign/getCustomerSegmentsAndTiers";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getCustomerCountSegmentList(segments) {
    let url = this.apiUrl + "/getCustomerCountInSegments";
    let params = new HttpParams();
    for (let i = 0; i < segments.length; i++) {
      params = params.append("segmentId", segments[i]);
    }
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getSurveyNotifications(offset, limit) {
    let url = this.apiUrl + "/survey/fetchNotifications/shopify/reviews/";
    let params = new HttpParams();
    url = url + this.tokenStorage.getMerchantId();
    params = params.append("offset", offset);
    params = params.append("limit", limit);
    return this.http.get(url, { params: params });
  }

  updateSurveyNotifications(id, status, offset, limit) {
    let url = this.apiUrl + "/survey/updateNotification/shopify/reviews/";
    let params = new HttpParams();
    url = url + this.tokenStorage.getMerchantId();
    url = url + "/" + id;
    params = params.append("status", status);
    params = params.append("offset", offset);
    params = params.append("limit", limit);
    return this.http.get(url, { params: params });
  }

  checkDataOnboardStatus(shopURL) {
    let url = this.apiUrl + "/shopifyAPI/isShopifyMerchantOnboardedV2";
    let params = new HttpParams();
    params = params.append("shopURL", shopURL);
    return this.http.get(url, { params: params });
  }

  linkShopifyToUser(shopifyUrl) {
    let url = this.apiUrl + "/shopifyAPI/linkShopifyShopToUser";
    let params = new HttpParams();
    params = params.append("shopifyAuthentication", shopifyUrl);
    return this.http.get(url, { params: params });
  }

  createShopifyBillingOrder(planId, ChargeType) {
    let url = this.apiUrl + "/shopifyAPI/createShopifyBillingOrder";

    // let params = new HttpParams();
    // params = params.append('merchantId', this.tokenStorage.getMerchantId());
    // params = params.append('planId', planId);
    // params = params.append('chargeType', ChargeType);
    // return this.http.get(url, { params: params });
    let data = {
      userEmail: this.tokenStorage.getUsername(),
      chargeType: ChargeType,
      subPlanId: planId,
      merchantid: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, data);
  }

  getPaymentHistoryOld() {
    let url = this.apiUrl + "/shopifyAPI/paymentHistory";

    // let url =  "http://dcda8a2860b8.ngrok.io"+ "/shopifyAPI/paymentHistory";
    let requestBody = {
      userEmail: this.tokenStorage.getUsername(),
      merchantId: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, requestBody);
  }

  cancelSubscription() {
    let url = this.apiUrl + "/shopifyAPI/cancelCurrentPlan";

    // let url =  "http://dcda8a2860b8.ngrok.io"+ "/shopifyAPI/paymentHistory";
    let requestBody = {
      userEmail: this.tokenStorage.getUsername(),
      merchantId: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, requestBody);
  }

  getCurrentPlan() {
    let url = this.apiUrl + "/pricing/getCurrentPlan";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());

    let requestBody = {
      userEmail: this.tokenStorage.getUsername(),
      merchantId: this.tokenStorage.getMerchantId(),
    };

    return this.http.post(url, requestBody);
  }

  createStrategy(data) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/strategies/";
    return this.http.post(url, data);
  }

  cloneStrategy(data) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/clone-strategy";
    return this.http.post(url, data);
  }

  createNode(data, strategyId) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/strategies/" +
      strategyId +
      "/nodes/";
    return this.http.post(url, data);
  }

  updateNode(strategyId, nodeId, data) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/strategies/" +
      strategyId +
      "/nodes/" +
      nodeId;
    return this.http.put(url, data);
  }

  getCustomerEvents() {
    let url = this.apiUrl + "/campaign-strategy/customer-events";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  activateStrategy(strategyId, data) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/strategies/" +
      strategyId +
      "/activate";

    return this.http.post(url, data);
  }

  deactivateStrategy(strategyId) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/strategies/" +
      strategyId +
      "/deactivate";
    return this.http.get(url);
  }

  updateStrategy(strategyId, data) {
    const finalData = data;
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/strategies/" +
      strategyId;
    return this.http.put(url, finalData);
  }

  deleteNode(strategyId, nodeId, deleteType) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/strategies/" +
      strategyId +
      "/nodes/" +
      nodeId;

    let params = new HttpParams();
    params = params.append("childNodeDeleteType", deleteType);
    return this.http.delete(url, { params: params });
  }

  getOfferDispatchOffers() {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/offers";
    return this.http.get(url);
  }

  getIndividualSegmentTimeseries(segmentName) {
    let url = this.apiUrl + "/analytics/segmentTimeSeries";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      segmentName: segmentName,
    };
    return this.http.post(url, data);
  }

  getMerchantProfileData() {
    let url = this.apiUrl + "/merchantData/profile";
    let mId = this.tokenStorage.getMerchantId();
    let params = new HttpParams();
    params = params.append("merchantId", mId);
    return this.http.get(url, { params: params });
  }
  uploadAvatar(dataInput: FormData) {
    let url = this.apiUrl + "/merchantData/avatar";
    let mId = this.tokenStorage.getMerchantId();
    let params = new HttpParams();
    params = params.append("merchantId", mId);
    let data = dataInput;

    return this.http.post(url, data, { params: params });
  }

  getStrategy(strategyId) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/strategies/" +
      strategyId;
    return this.http.get(url);
  }

  getStrategies() {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/strategies/";
    return this.http.get(url);
  }

  getAllOfferList() {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/offers";
    return this.http.get(url);
  }

  getAllOfferListWithPagination(
    page,
    size,
    status,
    channel,
    sortby,
    order,
    offerName?
  ) {
    console.log("paginate");
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/offers";

    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("size", size);
    params = params.append("sortBy", sortby);
    params = params.append("order", order);
    if (this.tokenStorage.getqId() !== "null") {
      params = params.append("storeId", this.tokenStorage.getqId());
    }
    for (var i = 0; i < status.length; i++) {
      params = params.append("status", status[i]);
    }
    for (var i = 0; i < channel.length; i++) {
      params = params.append("channel", channel[i]);
    }
    if (offerName) {
      params = params.append("offerName", offerName);
    }

    return this.http.get(url, { params: params });
  }

  getParticularOfferDetails(offerId: number) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/offers/" +
      offerId;
    return this.http.get(url);
  }

  getParticularOfferDetailsOfCustomer(offerId: number, customerId) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/offers/" +
      offerId+"/"+customerId;
    return this.http.get(url);
  }

  deleteParticularOffer(offerId: number) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/offers/" +
      offerId;
    return this.http.delete(url);
  }
  updateParticularOfferDetails(offerId: number, OfferData) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/offers/" +
      offerId;
    return this.http.put(url, OfferData);
  }

  createParticularOffer(Offerdata) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/offers";
    let data = Offerdata;
    return this.http.post(url, data);
  }
  launchOffer(Offerdata, offerId) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/offers/" +
      offerId +
      "/launch";
    Offerdata["platform"] = this.tokenStorage.getMerchantOnlineStore();
    let data = Offerdata;
    return this.http.post(url, data);
  }

  getOfferManagementProductsList(productLetter, page?, size?) {
    let url =
      this.apiUrl +
      "/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/getProducts";
    let params = new HttpParams();
    params = params.append("productName", productLetter);
    if (this.tokenStorage.getqId() !== "null") {
      params = params.append("storeId", this.tokenStorage.getqId());
    }
    if (page || size) {
      params = params.append("page", page);
      params = params.append("size", size);
    }
    return this.http.get(url, { params: params });
  }

  getCategoryBasedProductList(productLetter, page, size, data) {
    let url =
      this.apiUrl +
      "/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/getProductsByCategory";
    let params = new HttpParams();
    params = params.append("productName", productLetter);
    if (this.tokenStorage.getqId() !== "null") {
      params = params.append("storeId", this.tokenStorage.getqId());
    }
    if (page || size) {
      params = params.append("page", page);
      params = params.append("size", size);
    }
    return this.http.post(url, data, { params: params });
  }

  getModifierList(modifierLetter, page?, size?) {
    let url =
      this.apiUrl +
      "/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/getModifiers";
    let params = new HttpParams();
    params = params.append("modifierName", modifierLetter);
    if (this.tokenStorage.getqId() !== "null") {
      params = params.append("storeId", this.tokenStorage.getqId());
    }
    if (page || size) {
      params = params.append("page", page);
      params = params.append("size", size);
    }
    return this.http.get(url, { params: params });
  }

  getEmailSenderList() {
    let url = `${
      this.apiUrl
    }/merchant/${this.tokenStorage.getMerchantId()}/getModuleList/all`;
    return this.http.get(url);
  }

  getModuleList(status: string): Observable<any> {
    let url = `${
      this.apiUrl
    }/merchant/${this.tokenStorage.getMerchantId()}/getModuleList/${status}`;
    return this.http.get(url);
  }

  getRewardOfferTags(rewardType) {
    let url = this.apiUrl + "/campaign/getRewardEmailVariables";
    let params = new HttpParams();
    params = params.append("rewardType", rewardType);
    params = params.append("merchantId", this.tokenStorage.getMerchantId());

    return this.http.get(url, { params: params });
  }

  deleteStrategy(strategyId) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/strategies/" +
      strategyId;
    return this.http.delete(url);
  }

  getNotification(page, size) {
    let url = this.apiUrl + "/notifications/merchant";
    let mId = this.tokenStorage.getMerchantId();
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("size", size);
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      channel: "WEB_APP",
    };
    return this.http.post(url, data, { params: params });
  }
  updateNotification(id) {
    let url = this.apiUrl + "/notifications/merchant";
    let mId = this.tokenStorage.getMerchantId();
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      channel: "WEB_APP",
      status: "SEEN",
      notificationId: id,
    };
    return this.http.put(url, data);
  }
  updateAllNotification() {
    let url = this.apiUrl + "/notifications/merchant";
    let mId = this.tokenStorage.getMerchantId();
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      channel: "WEB_APP",
      status: "SEEN",
    };
    return this.http.put(url, data);
  }

  updatesurveyrecommendation(id, status, excludedList) {
    let url = this.apiUrl + "/survey/updateRecommendationAlertStatus";
    var exlist;
    if (excludedList.length == 0) {
      exlist = "";
    } else {
      exlist = excludedList.map((x) => x).join(",");
    }

    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      id: id,
      status: status,
      excludedCustomerList: exlist,
    };
    return this.http.post(url, data);
  }

  updateEmailRecommendation(layoutId, customerIds) {
    let url = this.apiUrl + "/survey/sendEmailForRecommendationAlert";
    let data = {
      layoutId: layoutId,
      customerIds: customerIds,
      merchantId: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, data);
  }

  changePassword(oldPassword, newpassword) {
    let url = this.apiUrl + "/auth/change/password";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      userName: this.tokenStorage.getUsername(),
      oldPassword: oldPassword,
      newPassword: newpassword,
    };
    return this.http.post(url, data);
  }

  analyticsTopCunsumers(pageIndex, pageSize) {
    let url = this.apiUrl + "/analytics/topConsumers";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      recordsPerPage: pageSize,
      pageNumber: pageIndex,
    };
    return this.http.post(url, data);
  }

  getTopicDivision(storeid?) {
    let url = this.apiUrl + "/survey/getTopicAndSentiments";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (storeid) {
      params = params.append("storeId", storeid);
    } else {
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
    }
    return this.http.get(url, { params: params });
  }

  getTopicTimeSeries(timeSeriesType, storeid?) {
    let url = this.apiUrl + "/survey/getTopicCountTimeseries";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("type", timeSeriesType);
    if (storeid) {
      params = params.append("storeId", storeid);
    } else {
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
    }
    return this.http.get(url, { params: params });
  }
  getTextAnalysisTopicCount(storeid?) {
    let url = this.apiUrl + "/survey/getTopicsCount";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (storeid) {
      params = params.append("storeId", storeid);
    } else {
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
    }
    return this.http.get(url, { params: params });
  }

  getSegmentQuery(data) {
    let url = this.apiUrl + "/segment/query";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, data, { params: params });
  }

  analyticsHotLead(pageSize, pageIndex) {
    let url = this.apiUrl + "/analytics/hotLeads";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      recordsPerPage: pageSize,
      pageNumber: pageIndex,
    };
    return this.http.post(url, data);
  }

  getWinbackRateTimeSeries() {
    let url = this.apiUrl + "/analytics/winRate";
    let param = new HttpParams();
    param = param.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: param });
  }

  getTopStrategies() {
    let url = this.apiUrl + "/analytics/topStrategies";
    let param = new HttpParams();
    param = param.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: param });
  }

  getRecommendationAlert(recordsPerPage, pageNumber) {
    let url = this.apiUrl + "/survey/getRecommendationAlert";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      recordsPerPage: recordsPerPage,
      pageNumber: pageNumber,
    };
    return this.http.post(url, data);
  }

  ignoreRecommendationAlert(alertId) {
    let url = this.apiUrl + "/survey/updateRecommendationAlertStatus";
    // let param = new HttpParams();
    // param = param.append('id', alertId);
    // param = param.append('merchantId',this.tokenStorage.getMerchantId());
    // param = param.append('status','IGNORED');
    // return this.http.get(url, { params: param });

    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      id: alertId,
      status: "",
      excludedCustomerList: "",
    };
    return this.http.post(url, data);
  }

  getStandardCampaigns() {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/strategies/standard";
    return this.http.get(url);
  }

  addCustomerToCampaign(strategyId, customerIds) {
    let url = this.apiUrl + "/survey/addCustomerListToCampaign";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      strategyId: strategyId,
      customerIds: customerIds,
    };
    return this.http.post(url, data);
  }

  getAllForms(recordsPerPage, pageNumber, sortOrderByAsc, status, type, name) {
    let url =
      this.apiUrl + "/forms/getForms/" + this.tokenStorage.getMerchantId();
    let params = new HttpParams();
    params = params.append("recordsPerPage", recordsPerPage);
    params = params.append("pageNumber", pageNumber);
    params = params.append("sortOrderByAsc", sortOrderByAsc);
    for (var i = 0; i < status.length; i++) {
      params = params.append("status", status[i]);
    }
    params = params.append("type", type);
    params = params.append("name", name);
    return this.http.get(url, { params: params });
  }

  getFormAnalytics(formId) {
    let url =
      this.apiUrl +
      "/forms/getAnalyticsData/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      formId;
    return this.http.get(url);
  }

  submitHSForm(portalId, guidid, form) {
    let url =
      "https://api.hsforms.com/submissions/v3/integration/submit/" +
      portalId +
      "/" +
      guidid;

    return this.http.post(url, form, {
      headers: { "Content-Type": "application/json;charset=UTF-8" },
    });
  }

  cloverAPIOauthURL(code) {
    let url =
      "https://sandbox.dev.clover.com/oauth/token?client_id=W2H8J16KNFC80&client_secret=d06d7688-0f3b-edf6-8ef5-75b02d2acdb5&code=" +
      code;
    return this.http.get(url);
  }

  cloverAPIOnboard(code, shop) {
    let url = this.apiUrl + "/cloverAPI/onboardCloverAccount";
    let data = {
      code: code,
      shop: shop,
    };
    return this.http.post(url, data);
  }
  isCloverAPIOnboard(merchantCode) {
    let url = this.apiUrl + "/cloverAPI/isCloverMerchantOnboarded";
    let params = new HttpParams();
    params = params.append("merchantCode", merchantCode);
    return this.http.get(url, { params: params });
  }
  fetchAllValidWidgets(merchantStoreName) {
    let url = this.apiUrl + "/getWidgetPOSMapping";
    let params = new HttpParams();
    params = params.append("pos", merchantStoreName);
    return this.http.get(url, { params: params });
  }

  moveNode(strategyId, data) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/strategies/" +
      strategyId +
      "/nodes/move";
    return this.http.post(url, data);
  }

  getAllDomain() {
    let url = this.apiUrl + "/merchantDomain/getAllDomains";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  addDomain(data) {
    let url = this.apiUrl + "/merchantDomain/addDomain";
    return this.http.post(url, data);
  }

  getDomain(data) {
    let url = this.apiUrl + "/merchantDomain/getDomain";
    return this.http.post(url, data);
  }

  verifyDomain(data) {
    let url = this.apiUrl + "/merchantDomain/verifyDomain";
    return this.http.post(url, data);
  }
  updateDomainStatus(data) {
    let url = this.apiUrl + "/merchantDomain/updateDomain";
    return this.http.put(url, data);
  }
  deleteDomain(data) {
    let url = this.apiUrl + "/merchantDomain/deleteDomain";
    return this.http.post(url, data);
  }

  getDomainRecords(data) {
    let url = this.apiUrl + "/merchantDomain/getDomainRecords";
    return this.http.post(url, data);
  }

  getInsightAllEmail(start, end) {
    let url = this.apiUrl + "/analytics/email/stats";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      startDate: start + " 00:00:00",
      endDate: end + " 00:00:00",
    };
    return this.http.post(url, data);
  }

  getAnalyticsAllSMS(start, end) {
    let url = this.apiUrl + "/analytics/sms/stats";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      startDate: start + " 00:00:00",
      endDate: end + " 00:00:00",
    };
    return this.http.post(url, data);
  }

  getInsightSurveyEmails(start, end, id) {
    let url = this.apiUrl + "/analytics/email/survey";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      startDate: start + " 00:00:00",
      endDate: end + " 00:00:00",
      moduleId: id,
    };
    return this.http.post(url, data);
  }

  getAnalyticsSurveySMS(start, end, id) {
    let url = this.apiUrl + "/analytics/sms/survey";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      startDate: start + " 00:00:00",
      endDate: end + " 00:00:00",
      moduleId: id,
    };
    return this.http.post(url, data);
  }

  getInsightOfferEmails(start, end, id) {
    let url = this.apiUrl + "/analytics/email/offer";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      startDate: start + " 00:00:00",
      endDate: end + " 00:00:00",
      moduleId: id,
    };
    return this.http.post(url, data);
  }

  getAnalyticsOfferSMS(start, end, id) {
    let url = this.apiUrl + "/analytics/sms/offer";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      startDate: start + " 00:00:00",
      endDate: end + " 00:00:00",
      moduleId: id,
    };
    return this.http.post(url, data);
  }

  getInsightCampaignEmails(start, end, id) {
    let url = this.apiUrl + "/analytics/email/strategies";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      startDate: start + " 00:00:00",
      endDate: end + " 00:00:00",
      moduleId: id,
    };
    return this.http.post(url, data);
  }

  getAnalyticsCampaignSMS(start, end, id) {
    let url = this.apiUrl + "/analytics/sms/strategies";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      startDate: start + " 00:00:00",
      endDate: end + " 00:00:00",
      moduleId: id,
    };
    return this.http.post(url, data);
  }

  getInsightGraphData(start, end, dataObject, event?) {
    let url = this.apiUrl + "/analytics/email/time-series";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      startDate: start + " 00:00:00",
      endDate: end + " 00:00:00",
      channel: dataObject.channel,
      type: dataObject.type,
      event: event ? event : dataObject.event,
      timePeriod: dataObject.timePeriod,
      moduleId: dataObject.moduleId,
    };
    return this.http.post(url, data);
  }

  getSMSGraphData(start, end, dataObject, event?) {
    let url = this.apiUrl + "/analytics/sms/time-series";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      startDate: start + " 00:00:00",
      endDate: end + " 00:00:00",
      channel: dataObject.channel,
      type: dataObject.type,
      event: event ? event : dataObject.event,
      timePeriod: dataObject.timePeriod,
      moduleId: dataObject.moduleId,
    };
    return this.http.post(url, data);
  }

  getNotificationBanner() {
    let url = this.apiUrl + "/notifications/banner";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }
  dismissBanner(id) {
    let url = this.apiUrl + "/notifications/banner/dismiss";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("bannerId", id);
    return this.http.get(url, { params: params });
  }

  getMerchantGameConfigPYG() {
    let url = this.apiUrl + "/merchant-game/getMerchantGameConfig";
    // let url ='http://7746630aabb8.ngrok.io/merchant-game/getMerchantGameConfig'

    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("platform", "shopify");
    return this.http.get(url, { params: params });
  }

  setMerchantGameConfigPYG(data) {
    let url = this.apiUrl + "/merchant-game/defineGame";
    // let url = 'http://7746630aabb8.ngrok.io/merchant-game/defineGame';
    return this.http.post(url, data);
  }

  // getStoreCustomerSegments() {
  //   let url = this.apiUrl + "/merchant-game/getStoreCustomerSegments";
  //   let params = new HttpParams();
  //   params = params.append("merchantId", this.tokenStorage.getMerchantId());
  //   params = params.append("platform", "shopify");
  //   return this.http.get(url, { params: params });
  // }

  setMerchantConfigureGame(data) {
    let url = this.apiUrl + "/merchant-game/configureGameWithSchedule";
    // let url = 'http://7746630aabb8.ngrok.io/merchant-game/defineGame';
    return this.http.post(url, data);
  }

  getStoreCustomerSegments() {
    let url = this.apiUrl + "/merchant-game/getStoreCustomerSegments";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("platform", "shopify");
    return this.http.get(url, { params: params });
  }

  setGameSchedule(data) {
    let url = this.apiUrl + "/merchant-game/addGameSchedule";
    let params = new HttpParams();
    return this.http.post(url, data);
  }
  getGameProducts() {
    let url = this.apiUrl + "/merchant-game/getGameProducts";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("platform", "shopify");
    return this.http.get(url, { params: params });
  }
  addGameProducts(data) {
    let url = this.apiUrl + "/merchant-game/addGameProducts";
    //let url="https://d0f1546f6f37.ngrok.io/merchant-game/addGameProducts"
    let params = new HttpParams();
    return this.http.post(url, data);
  }

  getGameSchedule() {
    let url = this.apiUrl + "/merchant-game/getGameSchedule";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("platform", "shopify");
    return this.http.get(url, { params: params });
  }

  // getSegmentAnalytics(segmentId) {
  //   let url = this.apiUrl + "/analytics/getSegmentAnalytics";
  //   let params = new HttpParams();
  //   params = params.append("merchantId", this.tokenStorage.getMerchantId());
  //   params = params.append("segmentId", segmentId);
  //   return this.http.get(url, { params: params });
  // }

  getSegmentAnalytics(segmentId) {
    let url = this.apiUrl + "/analytics/getSegmentAnalytics";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("segmentId", segmentId);
    if (this.tokenStorage.getqId() !== "null") {
      params = params.append("storeId", this.tokenStorage.getqId());
    }
    return this.http.get(url, { params: params });
  }

  getLifeTimeGameAnalytics() {
    let url = this.apiUrl + "/voss-merchant-analytics/getLifetimeGameAnalytics";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }
  getGameAnalyticsForCurrentSchedule() {
    let url =
      this.apiUrl +
      "/voss-merchant-analytics/getGameAnalyticsForCurrentSchedule";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  createSmsTemplate(data) {
    let url = this.apiUrl + "/template";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());

    return this.http.post(url, data, { params: params });
  }

  deleteSmsTemplate(templateId) {
    let url = this.apiUrl + "/template";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("id", templateId);
    return this.http.delete(url, { params: params });
  }

  updateSmsTemplate(data) {
    let url = this.apiUrl + "/template";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.put(url, data, { params: params });
  }

  getSmsTemplates(channel, type) {
    let url = this.apiUrl + "/templates";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("channel", channel);
    params = params.append("layoutType", "STANDARD");
    params = params.append("layoutType", "CUSTOM");
    return this.http.get(url, { params: params });
  }

  scheduleSMS(data) {
    let url = this.apiUrl + "/schedule";
    data["merchantId"] = this.tokenStorage.getMerchantId();
    return this.http.post(url, data);
  }

  getSmsTemplate(id) {
    let url = this.apiUrl + "/template";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("id", id);
    return this.http.get(url, { params: params });
  }

  getGreyfoxVersion() {
    let url = this.apiUrl + "/greyfoxProductDetails";
    return this.http.get(url);
  }

  getOfferSMSTemplate(id, type) {
    let url = this.apiUrl + "/templates/offer";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("offerType", type);
    params = params.append("channel", "SMS");
    params = params.append("offerId", id);
    return this.http.get(url, { params: params });
  }

  getAllTemplates() {
    let id: string = this.tokenStorage.getMerchantId();
    let segmentParams = new HttpParams();
    let segmentURL = this.apiUrl + "/campaign/getCustomerSegmentsAndTiers";
    segmentParams = segmentParams.append("merchantId", id);
    let segment = this.http.get(segmentURL, { params: segmentParams });

    let surveyURL = this.apiUrl + "/survey/templates";
    let surveyParams = new HttpParams();
    surveyParams = surveyParams.append("merchantId", id);
    let survey = this.http.get(surveyURL, { params: surveyParams });

    let offerURL = this.apiUrl + "/offer/merchant/" + id + "/offers";
    let offer = this.http.get(offerURL);

    let emailURL = this.apiUrl + "/campaign/getAllEmailLayouts";
    let emailParams = new HttpParams();
    emailParams = emailParams.append("merchantId", id);
    emailParams = emailParams.append("type", "GENERIC");
    let email = this.http.get(emailURL, { params: emailParams });

    let smsURL = this.apiUrl + "/templates";
    let smsParams = new HttpParams();
    smsParams = smsParams.append(
      "merchantId",
      this.tokenStorage.getMerchantId()
    );
    smsParams = smsParams.append("channel", "SMS");
    let sms = this.http.get(smsURL, { params: smsParams });

    let surveyEmailURL = this.apiUrl + "/campaign/getAllEmailLayouts";
    let surveyEmailTemplateParams = new HttpParams();
    surveyEmailTemplateParams = surveyEmailTemplateParams.append(
      "merchantId",
      this.tokenStorage.getMerchantId()
    );
    surveyEmailTemplateParams = surveyEmailTemplateParams.append(
      "type",
      "SURVEY"
    );
    let surveyEmail = this.http.get(surveyEmailURL, {
      params: surveyEmailTemplateParams,
    });

    return new Promise((resolve) => {
      forkJoin([segment, survey, offer, email, sms, surveyEmail]).subscribe(
        (response) => {
          resolve(response);
        }
      );
    });
  }
  saveSMSTemplate(details) {
    let url = this.apiUrl + "/Sms/saveSms";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    let data = {
      type: details["type"],
      layoutName: details["name"],
      channelType: details["channelType"],
      layoutType: "CUSTOM",
      body: details["body"],
      bodyText: details["bodyText"],
    };
    return this.http.post(url, data, { params: params });
  }

  getFormConstants() {
    let url =
      this.apiUrl +
      "/forms/getFormsConfigurations/" +
      this.tokenStorage.getMerchantId();
    return this.http.get(url);
  }

  uploadFormImage(imgFile: FormData, formId) {
    let url =
      this.apiUrl +
      "/forms/upload/images/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      formId;
    //let params = new HttpParams();
    //params = params.append("file", imgFile);
    return this.http.post(url, imgFile);
  }

  createForm(formData) {
    let url =
      this.apiUrl + "/forms/create/" + this.tokenStorage.getMerchantId();
    return this.http.post(url, formData);
  }

  updateForm(formData, formId) {
    let url =
      this.apiUrl +
      "/forms/update/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      formId;
    return this.http.post(url, formData);
  }

  getFormData(formId) {
    let url =
      this.apiUrl +
      "/forms/getFormDetails/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      formId;
    return this.http.get(url);
  }

  deleteForm(formId) {
    let url =
      this.apiUrl +
      "/forms/delete/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      formId;
    return this.http.get(url);
  }

  sendEmailFromFormAnalytics(merchantID, requestData) {
    let url =
      this.apiUrl + "/forms/performActionOnForm?merchantId=" + merchantID;
    return this.http.post(url, requestData);
  }

  getProspectList(merchantID, recordsPerpage, pageNumber) {
    let url =
      this.apiUrl +
      "/forms/getProspectListData?merchantId=" +
      merchantID +
      "&recordsPerPage=" +
      recordsPerpage +
      "&pageNumber=" +
      pageNumber;
    return this.http.get(url);
  }

  getWelcomeWidgetStatus() {
    let url = this.apiUrl + "/getWelcomeWidgetStatus";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }
  updateWelcomeWidgetStatus() {
    let url =
      this.apiUrl +
      "/updateWelcomeWidgetStatus?merchantId=" +
      this.tokenStorage.getMerchantId() +
      "&isWidgetShown=true";
    return this.http.put(url, null);
  }

  getVariantProductDetails(idArray) {
    let url = this.apiUrl + "/getVariantsByIds";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("variantIds", idArray);
    return this.http.get(url, { params: params });
  }
  isEmailVerified() {
    let url = this.apiUrl + "/userSettings/isVerified";
    let params = new HttpParams();
    params = params.append("verificationType", "PARENT_EMAIL_VERIFICATION");
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  AddUserManagementUser(email, name, sendagain, userId, role, stores, number) {
    let url = this.apiUrl + "/merchantData/addUserToAccount";
    // let params = new HttpParams();
    // params = params.append("merchantId", this.tokenStorage.getMerchantId());
    // params = params.append("offerType", type);
    let data = {
      userId: userId,
      merchantId: this.tokenStorage.getMerchantId(),
      username: name,
      emailId: email,
      sendAgain: sendagain,
      role: role,
      storeId: stores.length > 0 ? stores : null,
      phoneNumber: number,
    };
    return this.http.post(url, data);
  }
  GetUserManagementUser() {
    let url = this.apiUrl + "/merchantData/getUsers";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }
  DeleteUserManagementUser(email) {
    let url = this.apiUrl + "/merchantData/deleteUser";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      email: email,
    };
    return this.http.post(url, data);
  }
  NewUserManagementUser(token: string, newpassword: string) {
    let url = this.apiUrl + "/merchantData/newUserVerification";
    let data = {
      token: token,
      password: newpassword,
    };
    return this.http.post(url, data);
  }

  resendVerificationEmail() {
    let url = this.apiUrl + "/userSettings/resendVerificationEmail";
    let params = new HttpParams();
    params = params.append("verificationType", "PARENT_EMAIL_VERIFICATION");
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }
  getConsentStats() {
    let url = this.apiUrl + "/consent/stats";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("channel", "SMS");
    return this.http.get(url, { params: params });
  }
  getConsentDetails(pageSize, pageIndex) {
    let url = this.apiUrl + "/consent/details";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("channel", "SMS");
    params = params.append("page", pageIndex);
    params = params.append("size", pageSize);
    return this.http.get(url, { params: params });
  }
  searchConsnetDetails(pageSize, pageIndex, searchString) {
    let url = this.apiUrl + "/consent/details/search";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      searchString: searchString,
      pageNumber: pageIndex,
      recordsPerPage: pageSize,
    };
    return this.http.post(url, data);
  }
  getAllCustomersCount() {
    let url = this.apiUrl + "/customerSegment/mobileNumber/count/consent";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (this.tokenStorage.getqId() !== "null") {
      params = params.append("storeId", this.tokenStorage.getqId());
    }
    return this.http.get(url, { params: params });
  }
  getSegmentCustomersCount(segments, tiers?) {
    let url = this.apiUrl + "/customerSegment/mobileNumber/count/consent";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (this.tokenStorage.getqId() !== "null") {
      params = params.append("storeId", this.tokenStorage.getqId());
    }
    if (segments.length > 0) {
      for (let i = 0; i < segments.length; i++) {
        params = params.append("segmentIds", segments[i]);
      }
    }
    if (tiers?.length > 0) {
      for (let i = 0; i < tiers.length; i++) {
        params = params.append("tierIds", tiers[i]["id"]);
      }
    }
    return this.http.get(url, { params: params });
  }
  getSegmentCustomersCountOffer(
    segments,
    tiers?,
    customerlist?,
    employeelist?
  ) {
    let url = this.apiUrl + "/customerSegment/mobileNumber/count/consent";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (this.tokenStorage.getqId() !== "null") {
      params = params.append("storeId", this.tokenStorage.getqId());
    }
    if (segments.length > 0) {
      for (let i = 0; i < segments.length; i++) {
        params = params.append("segmentIds", segments[i]["id"]);
      }
    }
    if (tiers?.length > 0) {
      for (let i = 0; i < tiers.length; i++) {
        params = params.append("tierIds", tiers[i]["id"]);
      }
    }
    if (customerlist?.length > 0) {
      for (let i = 0; i < customerlist.length; i++) {
        params = params.append("customerListIds", customerlist[i]["id"]);
      }
    }
    if (employeelist?.length > 0) {
      for (let i = 0; i < employeelist.length; i++) {
        params = params.append("employeeListIds", employeelist[i]["id"]);
      }
    }
    return this.http.get(url, { params: params });
  }

  getCustomerCount(data) {
    let url = this.apiUrl + "/sms/suppressionlist";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (this.tokenStorage.getqId() !== "null") {
      params = params.append("storeId", this.tokenStorage.getqId());
    }
    return this.http.post(url, data, { params: params });
  }

  sendConsentSMS(segments, reminder, templeteName, templeteId) {
    let url = this.apiUrl + "/schedule";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      templateId: templeteId,
      scheduleType: "CONSENT",
      scheduleName: templeteName,
      channel: "SMS",
      segmentIds: segments,
      scheduleConfig: {
        sendReminder: reminder,
      },
      status: "SCHEDULED",
    };
    return this.http.post(url, data);
  }
  getSMSConsentTemplate(type) {
    let url = this.apiUrl + "/templates";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("channel", "SMS");
    params = params.append("channelType", "CONSENT");
    params = params.append("type", type);
    // params = params.append("type",'CONSENT_FIRST_TIME')
    // params = params.append("type",'CONSENT_REMINDER')
    return this.http.get(url, { params: params });
  }

  checkSMSEditAllowed() {
    let url = this.apiUrl + "/template/isEditAllowed";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  uploadLogoForBranding(data) {
    let url = this.apiUrl + "/branding/addBranding";
    return this.http.post(url, data);
  }

  getBrandingDetails() {
    let url = this.apiUrl + "/branding/getBrandingDetails";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    let storeid = this.tokenStorage.getqId();
    if (storeid != "null") {
      params = params.append("storeId", storeid);
    }
    return this.http.get(url, { params: params });
  }

  deleteBrandingLogo(data) {
    let params = new HttpParams();
    params = params.append("brandingRequest", JSON.stringify(data));
    let url = this.apiUrl + "/branding/deleteBranding";
    return this.http.post(url, data);
  }

  updateBrandingDetails(data) {
    let url = this.apiUrl + "/branding/updateBranding";
    let params = new HttpParams();
    params = params.append("brandingRequest", JSON.stringify(data));
    return this.http.post(url, data);
  }

  getCurrentPlanData() {
    let url = this.apiUrl + "/pricing/getCurrentPlan";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    let requestBody = {
      userEmail: this.tokenStorage.getUsername(),
      merchantId: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, requestBody);
  }

  getAllPlanData() {
    let url = this.apiUrl + "/pricing/getAllPlans";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getTopupPriceInfo(requestedCredits, subPlanId) {
    let url = this.apiUrl + "/pricing/getTopUpCalculatedPrice";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      username: this.tokenStorage.getUsername(),
      subPlanId: subPlanId,
      smsCredits: requestedCredits.sms,
      emailCredits: requestedCredits.email,
      customerCredit: requestedCredits.contact,
    };
    return this.http.post(url, data);
  }

  createPricingPlan(subplanId) {
    let url = this.apiUrl + "/branding/updateBranding";
    let data = {
      userEmail: this.tokenStorage.getUsername(),
      merchantId: this.tokenStorage.getMerchantId(),
      subPlanId: subplanId,
    };
    return this.http.post(url, data);
  }

  changePricingPlan(
    masterPlanIdOld,
    masterPlanIdNew,
    subplanIdOld,
    subplanIdNew
  ) {
    let url = this.apiUrl + "/pricing/changePlan";
    let data = [
      {
        userEmail: this.tokenStorage.getUsername(),
        merchantId: this.tokenStorage.getMerchantId(),
        subscriptionMasterPlanId: masterPlanIdOld,
        subPlanId: subplanIdOld,
      },
      {
        userEmail: this.tokenStorage.getUsername(),
        merchantId: this.tokenStorage.getMerchantId(),
        subscriptionMasterPlanId: masterPlanIdNew,
        subPlanId: subplanIdNew,
      },
    ];
    return this.http.post(url, data);
  }
  getPaymentHistory() {
    let url = this.apiUrl + "/pricing/paymentHistory";

    // let url =  "http://dcda8a2860b8.ngrok.io"+ "/shopifyAPI/paymentHistory";
    let requestBody = {
      userEmail: this.tokenStorage.getUsername(),
      merchantId: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, requestBody);
  }
  shopifyPaymentStatus(chargeId, chargeType) {
    let url = this.apiUrl + "/pricing/shopifyPayment/success";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    chargeId ? (params = params.append("chargeId", chargeId)) : null;
    chargeType
      ? (params = params.append("chargeType", "MONTHLY_SUBSCRIPTION"))
      : null;
    return this.http.get(url, { params: params });
  }

  createTopUpCharge(reqData) {
    let url = this.apiUrl + "/pricing/createTopUpCharge";

    // let url =  "http://dcda8a2860b8.ngrok.io"+ "/shopifyAPI/paymentHistory";
    let requestBody = {
      username: this.tokenStorage.getUsername(),
      merchantId: this.tokenStorage.getMerchantId(),
      subPlanId: reqData.subPlanId,
      smsCredits: reqData.smsCredits,
      emailCredits: reqData.emailCredits,
      customerCredit: reqData.customerCredit,
      price: reqData.price,
    };
    return this.http.post(url, requestBody);
  }

  createCharge(subplanId) {
    let url = this.apiUrl + "/pricing/createCharge";

    // let url =  "http://dcda8a2860b8.ngrok.io"+ "/shopifyAPI/paymentHistory";
    let requestBody = {
      username: this.tokenStorage.getUsername(),
      merchantId: this.tokenStorage.getMerchantId(),
      subPlanId: subplanId,
    };
    return this.http.post(url, requestBody);
  }

  getTopupPricingPlan(chargeId) {
    let url = this.apiUrl + "/pricing/shopifyPayment/topup/success";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("chargeId", chargeId);
    return this.http.get(url, { params: params });
  }
  getRecommendedPlan() {
    let url = this.apiUrl + "/pricing/recommendPlan";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    let requestBody = {
      // merchantId: this.tokenStorage.getMerchantId(),
    };

    return this.http.post(url, {}, { params: params });
  }

  getProratedCredits(subscriptionMasterPlanId, subPlanId) {
    let url = this.apiUrl + "/pricing/changePlan/getProratedCredits";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    // params = params.append("chargeId", chargeId);
    let requestBody = {
      userEmail: this.tokenStorage.getUsername(),
      merchantId: this.tokenStorage.getMerchantId(),
      subscriptionMasterPlanId: subscriptionMasterPlanId,
      subPlanId: subPlanId,
    };
    return this.http.post(url, requestBody, { params: params });
  }

  createGame(data) {
    let url =
      this.apiUrl + "/gamify/games/m/" + this.tokenStorage.getMerchantId();

    // params = params.append("chargeId", chargeId);
    return this.http.post(url, data);
  }
  updateGame(data, gameId) {
    let url =
      this.apiUrl +
      "/gamify/games/m/" +
      this.tokenStorage.getMerchantId() +
      "/g/" +
      gameId;

    // params = params.append("chargeId", chargeId);
    return this.http.put(url, data);
  }
  getGameData(gameId) {
    let url =
      this.apiUrl +
      "/gamify/games/m/" +
      this.tokenStorage.getMerchantId() +
      "/g/" +
      gameId;
    return this.http.get(url);
  }

  getGameList(title, page, size, sort, order, statusArray) {
    let url =
      this.apiUrl + "/gamify/games/m/" + this.tokenStorage.getMerchantId();
    let params = new HttpParams();
    params = params.append("title", title);
    params = params.append("page", page);
    params = params.append("size", size);
    params = params.append("sortBy", sort);
    params = params.append("order", order);
    for (var i = 0; i < statusArray.length; i++) {
      params = params.append("status", statusArray[i]);
    }
    return this.http.get(url, { params: params });
  }
  getNegotiatorEmailTemplate(gameId) {
    let url =
      this.apiUrl +
      "/gamify/email-templates/m/" +
      this.tokenStorage.getMerchantId() +
      "/g/" +
      gameId;
    return this.http.get(url);
  }
  getNegotiatorAnalytics(gameid?) {
    if (gameid) {
      let url =
        this.apiUrl +
        "/gamify/analytics/m/" +
        this.tokenStorage.getMerchantId() +
        "/g/" +
        gameid;
      return this.http.get(url);
    } else {
      let url =
        this.apiUrl +
        "/gamify/analytics/m/" +
        this.tokenStorage.getMerchantId();
      return this.http.get(url);
    }
  }
  getAllGamesNegotiatiorRevenueAnalytics(
    PeriodEnum,
    PeriodicityEnum?,
    startdate?,
    enddate?
  ) {
    if (PeriodEnum === "CUSTOM") {
      let url =
        this.apiUrl +
        "/gamify/analytics/m/" +
        this.tokenStorage.getMerchantId() +
        "/revenue";
      let params = new HttpParams();
      params = params.append("period", PeriodEnum);
      params = params.append("periodicity", PeriodicityEnum);
      params = params.append("from", startdate);
      params = params.append("to", enddate);
      return this.http.get(url, { params: params });
    } else {
      let url =
        this.apiUrl +
        "/gamify/analytics/m/" +
        this.tokenStorage.getMerchantId() +
        "/revenue";
      let params = new HttpParams();
      params = params.append("period", PeriodEnum);
      return this.http.get(url, { params: params });
    }
  }
  getSpecificGameNegotiatiorRevenueAnalytics(
    PeriodEnum,
    gameId,
    PeriodicityEnum?,
    startdate?,
    enddate?
  ) {
    if (PeriodEnum === "CUSTOM") {
      let url =
        this.apiUrl +
        "/gamify/analytics/m/" +
        this.tokenStorage.getMerchantId() +
        "/revenue/g/" +
        gameId;
      let params = new HttpParams();
      params = params.append("period", PeriodEnum);
      params = params.append("periodicity", PeriodicityEnum);
      params = params.append("from", startdate);
      params = params.append("to", enddate);
      return this.http.get(url, { params: params });
    } else {
      let url =
        this.apiUrl +
        "/gamify/analytics/m/" +
        this.tokenStorage.getMerchantId() +
        "/revenue/g/" +
        gameId;
      let params = new HttpParams();
      params = params.append("period", PeriodEnum);
      return this.http.get(url, { params: params });
    }
  }
  getAllGameList(recordsPerPage, pageNumber, sortBy, status, order, name) {
    let url =
      this.apiUrl + "/gamify/games/m/" + this.tokenStorage.getMerchantId();
    let params = new HttpParams();
    params = params.append("title", name);
    for (var i = 0; i < status.length; i++) {
      params = params.append("status", status[i]);
    }
    params = params.append("page", pageNumber);
    params = params.append("size", recordsPerPage);
    params = params.append("sortBy", sortBy);
    params = params.append("order", order);

    return this.http.get(url, { params: params });
  }
  getSingleGameDetails(gameId, responseType?) {
    let url =
      this.apiUrl +
      "/gamify/games/m/" +
      this.tokenStorage.getMerchantId() +
      "/g/" +
      gameId;
    if (responseType) {
      let params = new HttpParams();
      params = params.append("responseType", responseType);
      return this.http.get(url, { params: params });
    } else return this.http.get(url);
  }

  deleteGameData(gameId) {
    let url =
      this.apiUrl +
      "/gamify/games/m/" +
      this.tokenStorage.getMerchantId() +
      "/g/" +
      gameId;
    return this.http.delete(url);
  }
  getCategoryGame(pageNumber, recordsPerPage, search) {
    let url =
      this.apiUrl +
      "/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/getCategories";
    let params = new HttpParams();
    params = params.append("page", pageNumber);
    params = params.append("size", recordsPerPage);
    params = params.append("name", search);
    return this.http.get(url, { params: params });
  }
  getProductOfCategoryGame(pageNumber, recordsPerPage, categoryId) {
    let url =
      this.apiUrl +
      "/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/getProductsOfCategory/" +
      categoryId;
    let params = new HttpParams();
    params = params.append("page", pageNumber);
    params = params.append("size", recordsPerPage);
    return this.http.get(url, { params: params });
  }
  getProductNotInCategory(pageNumber, recordsPerPage) {
    let url =
      this.apiUrl +
      "/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/getProductsNotInAnyCategory";
    let params = new HttpParams();
    params = params.append("page", pageNumber);
    params = params.append("size", recordsPerPage);
    return this.http.get(url, { params: params });
  }
  getNegoriatorMergeTag() {
    let url =
      this.apiUrl +
      "/gamify/email-templates/m/" +
      this.tokenStorage.getMerchantId() +
      "/variables";
    return this.http.get(url);
  }
  saveNegotiatorMail(gameId, html, json) {
    let url =
      this.apiUrl +
      "/gamify/email-templates/m/" +
      this.tokenStorage.getMerchantId() +
      "/g/" +
      gameId;
    let data = {
      html: html,
      json: json,
    };
    return this.http.post(url, data);
  }

  getMerchantStoreWebsiteSnapshotImage() {
    let url =
      this.apiUrl +
      "/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/storePreview";
    return this.http.get(url);
  }
  refreshMerchantStoreWebsiteSnapshotImage() {
    let url =
      this.apiUrl +
      "/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/storePreview";
    return this.http.put(url, null);
  }
  createPatnershipForm(data) {
    let url = this.apiUrl + "/formPartnership/createForm";
    return this.http.post(url, data);
  }

  getPartnershipFormConfigurations(id, type, offerIDs) {
    let url = this.apiUrl + "/formPartnership/getFormConfiguration";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("pFormId", id);
    params = params.append("type", type);
    if (offerIDs !== null) params = params.append("offerId", offerIDs[0]);
    return this.http.get(url, { params: params });
  }

  submitConfig(data) {
    let url = this.apiUrl + "/formPartnership/submitFormConfiguration";
    return this.http.post(url, data);
  }

  getPartnershipForms(pageNumber, type) {
    let url = this.apiUrl + "/formPartnership/getForms";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("recordsPerPage", "12");
    params = params.append("pageNumber", pageNumber);
    // params = params.append("sortOrderByAsc", "true");
    // params = params.append("status", "ACTIVE");
    // params = params.append("status", "DRAFT");
    // params = params.append("status", "ENDED");
    // if(type === 'ALL') {
    //   params = params.append("type", 'CUSTOM');
    //   params = params.append("type", 'STANDARD')
    // } else {
    params = params.append("type", type);
    // }
    return this.http.get(url, { params: params });
  }

  checkIfEmailExists(data) {
    let url = this.apiUrl + "/partnership/sendPartnershipInviteEmail";
    return this.http.post(url, data);
  }

  getPartnershipOffersList(pageNumber, pageSize, offerName) {
    let url = this.apiUrl + "/partnershipOffer/detailedOffers";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("pageNumber", pageNumber);
    params = params.append("pageSize", pageSize);
    if (offerName.length > 0) {
      params = params.append("offerName", offerName);
    }
    return this.http.get(url, { params: params });
  }

  createPartnerOffer(data) {
    let url = this.apiUrl + "/partnershipOffer/offer";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, data, { params: params });
  }

  getPartnershipFormsSnapshot(type, id, offerId) {
    let url = this.apiUrl + "/formPartnership/getFormMedia";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("type", type);
    params = params.append("pFormId", id);
    params = params.append("offerId", "12");
    return this.http.get(url, { params: params });
  }

  addPartnershipBarrier(data) {
    let url = this.apiUrl + "/partnershipOffer/barrier";
    return this.http.post(url, data);
  }

  deletePartnershipOffer(offerId) {
    let url = this.apiUrl + "/partnershipOffer/offer";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("offerId", offerId);
    return this.http.delete(url, { params: params });
  }
  getPotentialPartnerList(pageIndex, pageSize, searchString) {
    let url = this.apiUrl + "/partnership/getPotentialPartnerList";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (searchString.length > 0) {
      params = params.append("searchString", searchString);
    }
    params = params.append("pageNumber", pageIndex);
    params = params.append("recordsPerPage", pageSize);
    return this.http.get(url, { params: params });
  }
  getPartnerRequestList(pageIndex, pageSize, searchString) {
    let url = this.apiUrl + "/partnership/getRequestList";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("pageNumber", pageIndex);
    params = params.append("recordsPerPage", pageSize);
    params = params.append("filter", searchString);
    return this.http.get(url, { params: params });
  }
  addPartnershipConsent() {
    let url = this.apiUrl + "/partnership/addPartnershipConsent";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }
  processPartnershipRequest(
    partnershipRequestId,
    actionPerformed,
    actionPerformedId,
    offerIds,
    details,
    barrierID,
    reason,
    sendOfferAgain
  ) {
    if (sendOfferAgain === "true") sendOfferAgain = true;
    if (sendOfferAgain === "false") sendOfferAgain = false;
    let url = this.apiUrl + "/partnership/processPartnershipRequest";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      partnershipRequestId: partnershipRequestId,
      actionPerformed: actionPerformed,
      formId: actionPerformedId,
      actionPerformedIds: offerIds,
      formOfferDetails: details,
      barrierId: barrierID,
      actionPerformedReason: reason,
      sendOfferAgain: sendOfferAgain,
    };
    return this.http.post(url, data);
  }
  getPartnerList(pageNumber, pageSize, searchString, filter) {
    let url = this.apiUrl + "/partnership/getPartnerList";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("pageNumber", pageNumber);
    params = params.append("recordsPerPage", pageSize);
    params = params.append("filter", searchString);
    return this.http.get(url, { params: params });
  }
  CreatePartnershipReward(rewardName, rewardvalue) {
    let url = this.apiUrl + "/partnershipOffer/merchantReward";
    let data = {
      id: null,
      rewardName: rewardName,
      merchantId: this.tokenStorage.getMerchantId(),
      status: "DRAFT",
      rewardvalue: rewardvalue,
    };
    return this.http.post(url, data);
  }
  getPartnershipRewardList() {
    let url = this.apiUrl + "/partnershipOffer/merchantRewards";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }
  CreatePartnerShip(targetMerchantId, partnershipRewardId) {
    let url = this.apiUrl + "/partnership/createPartnership";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      sourceMerchantId: this.tokenStorage.getMerchantId(),
      targetMerchantId: targetMerchantId,
      partnershipRewardId: partnershipRewardId,
    };
    return this.http.post(url, data);
  }

  deletePartnershipForm(id) {
    let url = this.apiUrl + "/formPartnership/deletePForm";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("pFormId", id);
    return this.http.get(url, { params: params });
  }

  getPartnershipFormDetails(id, type) {
    let url = this.apiUrl + "/formPartnership/getFormDetail";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("pFormId", id);
    params = params.append("type", type);
    return this.http.get(url, { params: params });
  }

  getMyAnalyticsGraphStats(data) {
    let url = this.apiUrl + "/partnershipAnalytics/getTotalStats";
    return this.http.post(url, data);
  }

  getPartnershipRewards(pageNumber, pageSize, rewardName) {
    let url = this.apiUrl + "/partnershipOffer/merchantDetailedRewards";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("pageNumber", pageNumber);
    params = params.append("pageSize", pageSize);
    params = params.append("rewardName", rewardName);
    return this.http.get(url, { params: params });
  }

  getSourceMerchantStats(data) {
    let url = this.apiUrl + "/partnershipAnalytics/getSourceMerchantStats";
    return this.http.post(url, data);
  }

  getTargetMerchantStats(data) {
    let url = this.apiUrl + "/partnershipAnalytics/getTargetMerchantStats";
    return this.http.post(url, data);
  }

  getAllPartnershipFormOffers(formId, edit) {
    let url = this.apiUrl + "/partnership/getAllOffersForFormCreation";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("edit", edit);
    if (formId !== null) {
      params = params.append("formId", formId);
    }
    return this.http.get(url, { params: params });
  }

  getOfferDescription(data) {
    let url = this.apiUrl + "/partnership/getOfferDescription";
    return this.http.post(url, data);
  }

  getPOffers() {
    let url = this.apiUrl + "/partnershipOffer/offers";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getPaypalID() {
    let url = this.apiUrl + "/partnership/getPayPalId";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  createPaypalID(data) {
    let url = this.apiUrl + "/partnership/updatePayPalId";
    return this.http.post(url, data);
  }

  createPartnershipChargeForTrialAndBasicPlan(chargetype) {
    let url = this.apiUrl + "/pricing/createPartnershipCharge";
    let data = {
      userEmail: this.tokenStorage.getUsername(),
      merchantId: this.tokenStorage.getMerchantId(),
      chargeType: chargetype,
    };
    return this.http.post(url, data);
  }
  checkPartnershipIfMerchantEligibleForBasic(
    masterPlanIdOld,
    masterPlanIdNew,
    subplanIdOld,
    subplanIdNew
  ) {
    let url = this.apiUrl + "/pricing/checkIfMerchantEligibleForBasic";
    let data = [
      {
        userEmail: this.tokenStorage.getUsername(),
        merchantId: this.tokenStorage.getMerchantId(),
        subscriptionMasterPlanId: masterPlanIdOld,
        subPlanId: subplanIdOld,
      },
      {
        userEmail: this.tokenStorage.getUsername(),
        merchantId: this.tokenStorage.getMerchantId(),
        subscriptionMasterPlanId: masterPlanIdNew,
        subPlanId: subplanIdNew,
      },
    ];
    return this.http.post(url, data);
  }

  activatePartnership(chargeId?) {
    let url = this.apiUrl + "/pricing/shopifyPayment/partnership/success";
    let params = new HttpParams();
    chargeId ? (params = params.append("chargeId", chargeId)) : null;
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getOfferConfig() {
    let url = this.apiUrl + "/partnershipOffer/getOfferConfig";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getFormDetailEdit(formId) {
    let url = this.apiUrl + "/formPartnership/getFormDetailEdit";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("formId", formId);
    return this.http.get(url, { params: params });
  }

  checkFormName(formName) {
    let url = this.apiUrl + "/formPartnership/checkValidFormName";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("formName", formName);
    return this.http.get(url, { params: params });
  }
  getCountOfGames() {
    let url =
      this.apiUrl +
      "/gamify/games/m/" +
      this.tokenStorage.getMerchantId() +
      "/count";
    return this.http.get(url);
  }
  getExcludedProductsCount(gameId) {
    let url =
      this.apiUrl +
      "/gamify/games/m/" +
      this.tokenStorage.getMerchantId() +
      "/g/" +
      gameId +
      "/excludedProductsCount";
    return this.http.get(url);
  }

  notificationRedirect(linkedId, type) {
    let url = this.apiUrl + "/notifications/notificationRedirect";
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      linkedId: linkedId,
      moduleName: "PARTNERSHIP",
      type: type,
    };
    return this.http.post(url, data);
  }

  deactivePForm(id) {
    let url = this.apiUrl + "/formPartnership/deactivateForm";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("pFormId", id);
    return this.http.get(url, { params: params });
  }
  getExcludedCategories(gameId, isPreview) {
    let url =
      this.apiUrl +
      "/gamify/games/m/" +
      this.tokenStorage.getMerchantId() +
      "/g/" +
      gameId +
      "/excludedCategories";
    let params = new HttpParams();
    params = params.append("isPreview", isPreview);
    return this.http.get(url, { params: params });
  }
  getExcludedProducts(gameId, isPreview, categoryId) {
    let url =
      this.apiUrl +
      "/gamify/games/m/" +
      this.tokenStorage.getMerchantId() +
      "/g/" +
      gameId +
      "/excludedProducts/" +
      categoryId;
    let params = new HttpParams();
    params = params.append("isPreview", isPreview);
    return this.http.get(url, { params: params });
  }
  signupForNewsLetter(email) {
    let url = this.apiUrl + "/subscribe/newsletter";
    let data = {
      email: email,
    };
    return this.http.post(url, data);
  }
  getShopDetails() {
    let url =
      this.apiUrl +
      "/merchantData/getShopDetails/" +
      this.tokenStorage.getMerchantId();
    return this.http.get(url);
  }
  uploadImageStore(data, isLogo) {
    let url = this.apiUrl + "/images/uploadImage";
    data.append("merchantId", this.tokenStorage.getMerchantId());
    data.append("isLogo", isLogo);
    return this.http.post(url, data);
  }

  editStoreDetails(data) {
    let url = this.apiUrl + "/MerchantDetailsUpdate";
    return this.http.post(url, data);
  }

  getMerchantDetails1() {
    let url = this.apiUrl + "/merchantData/getShopDetails/111";
    return this.http.get(url);
  }

  getTags(id) {
    let url = this.apiUrl + "/merchantGroupTags";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("categoryId", id);
    return this.http.get(url, { params: params });
  }
  getStoreData() {
    let url = this.apiUrl + "/merchantData/getMerchantStores";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append(
      "platform",
      this.tokenStorage.getMerchantOnlineStore()
    );
    return this.http.get(url, { params: params });
  }
  getPOSPlanDetails() {
    let url = this.apiUrl + "/getPOSPlans";
    let params = new HttpParams();
    return this.http.get(url);
  }
  planSubscriptionURL(data1) {
    let url = this.apiUrl + "/chargebee/getPlanSubscriptionURL";
    let params = new HttpParams();
    let data = {
      id: data1["plan"]["id"],
      planName: data1["plan"]["planName"],
      price: data1["plan"]["price"],
      active: data1["plan"]["active"],
      emailCredits: data1["plan"]["emailCredits"],
      smsCredits: data1["plan"]["smsCredits"],
      validity: data1["plan"]["validity"],
      planNameChargeBee: data1["plan"]["planNameChargeBee"],
      region: data1["region"],
    };
    params = params.append("userId", this.tokenStorage.getUserId());
    return this.http.post(url, data, { params: params });
  }
  getAddOnPlans() {
    let url = this.apiUrl + "/getPOSAddons";
    return this.http.get(url);
  }
  addOnSubscriptionURL(data1) {
    let url = this.apiUrl + "/chargebee/getAddonSubscriptionURL";
    let params = new HttpParams();
    let data = {
      addonType: data1["plan"]["addonType"],
      addonName: data1["plan"]["addonName"],
      price: data1["plan"]["price"],
      active: data1["plan"]["active"],
      addonNameChargeBee: data1["plan"]["addonNameChargeBee"],
      units: data1["plan"]["units"],
      region: data1["region"],
    };
    params = params.append("userId", this.tokenStorage.getUserId());
    return this.http.post(url, data, { params: params });
  }
  getCurrentPlanPos() {
    let url = this.apiUrl + "/pricing/getCurrentPlanPOS";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("userId", this.tokenStorage.getUserId());
    return this.http.get(url, { params: params });
  }
  getPaymentHistoryPos() {
    let url = this.apiUrl + "/pricing/paymentHistoryPOS";
    let params = new HttpParams();
    params = params.append("userId", this.tokenStorage.getUserId());
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getSocialMediaPagesCode(channel, code) {
    // let url = this.apiUrl + "/socialMedia/getSocialMediaPages";
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/meta/code";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    let data = {
      channel: channel,
      code: code,
    };
    return this.http.post(url, data);
  }
  saveFbPages(accountList, channel, ads) {
    // let url = this.apiUrl + "/socialMedia/getSocialMediaPages";
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/fb/save-pageid";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    let data = {
      fbPages: accountList,
      channel: channel,
      merchantId: this.tokenStorage.getMerchantId(),
      adAccountList: ads,
    };
    return this.http.post(url, data);
  }

  getSocialMediaUserLisingAndAnalyticsData() {
    // let url = this.apiUrl + "/socialMedia/getSocialMediaPages";
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/social-media-users/analytics/short";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());

    return this.http.get(url);
  }

  getSocialMediaUserWithField(fields) {
    // let url = this.apiUrl + "/socialMedia/getSocialMediaPages";
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/social-media-users/fields";
    let params = new HttpParams();

    params = params.append("fields", this.tokenStorage.getMerchantId());

    return this.http.get(url);
  }

  getSocialMdiaUsersList() {
    // let url = this.apiUrl + "/socialMedia/getSocialMediaPages";
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/social-media-users";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());

    return this.http.get(url);
  }

  getPageAllAnalytics(data) {
    // let url = this.apiUrl + "/socialMedia/getSocialMediaPages";
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/social-media-users/analytics/long";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, data);
  }

  uploadImages(data: FormData) {
    let headers: { "Content-Type": "multipart/form-data" };
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/upload/images";
    // let mId = this.tokenStorage.getMerchantId();

    return this.http.post(url, data, { headers: headers });
  }

  uploadVideo(data: FormData) {
    let headers: { "Content-Type": "multipart/form-data" };
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/upload/video";
    // let mId = this.tokenStorage.getMerchantId();

    return this.http.post(url, data, { headers: headers });
  }

  publishSocialMediaPost(data) {
    // let url = this.apiUrl + "/socialMedia/getSocialMediaPages";
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/post";

    return this.http.post(url, data);
  }

  getSocialMediaPosts(page, size) {
    // let url = this.apiUrl + "/socialMedia/getSocialMediaPages";
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/post";
    let params = new HttpParams();
    params = params.append("size", size);
    params = params.append("page", page);

    return this.http.get(url, { params: params });
  }

  getPostAnalytics(postId, channel) {
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/social-media-post/analytics";
    let params = new HttpParams();
    params = params.append("postId", postId);
    params = params.append("channel", channel);

    return this.http.get(url, { params: params });
  }
  getPostAnalyticsAndData(postId, channel) {
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/social-media-post/recent/analytics";
    let params = new HttpParams();

    params = params.append("type", "single");

    return this.http.get(url, { params: params });
  }
  getRecentPostAnalyticsAndData() {
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/social-media-post/post-data-analytics/recent";
    let params = new HttpParams();

    params = params.append("merchantId", this.tokenStorage.getMerchantId());

    return this.http.get(url, { params: params });
  }
  getPostData(id) {
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/post/" +
      id;

    return this.http.get(url);
  }
  updateSocialMediaContentPost(data, id) {
    // let url = this.apiUrl + "/socialMedia/getSocialMediaPages";
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/post/" +
      id;

    return this.http.put(url, data);
  }
  getWhatsAppTemplates(type) {
    let url = this.apiUrl + "/templates/whatsapp";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("type", type);
    return this.http.get(url, { params: params });
  }
  getOfferWhatsAppTemplate(id, offerType, type) {
    let url = this.apiUrl + "/templates/offer/whatsapp";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("offerType", offerType);
    params = params.append("channel", "WHATSAPP");
    params = params.append("offerId", id);
    params = params.append("type", type);
    return this.http.get(url, { params: params });
  }
  sendWhatsAppClientDetails(clientId, channel) {
    let url = this.apiUrl + "/whatsapp/onboarding";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    let data = {
      clientId: clientId,
      channelId: channel,
    };
    return this.http.post(url, data, { params: params });
  }
  checkWhatsAppOnboarded() {
    let url = this.apiUrl + "/whatsapp/isOnboarded";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getSubCampaignAnalytics(id) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/strategies/" +
      id +
      "/getSubCampaignAnalytics";
    return this.http.get(url);
  }

  getOnboardedStores(userID, pageIndex, pageSize) {
    let url =
      this.qfferAPIURL + "internal/" + userID + "/all-stores/short/list";
    let params = new HttpParams();
    params = params.append("page", pageIndex);
    params = params.append("size", pageSize);

    return this.http.get(url, { params: params });
  }

  getOnboardedStoreDetails(userID, storeID) {
    let url =
      this.qfferAPIURL + "internal/" + userID + "/store/details/" + storeID;
    return this.http.get(url);
  }

  getCategories() {
    let url = this.qfferAPIURL + "merchant/category/list";
    return this.http.get(url);
  }

  updateStore(userID, storeID, storeDetails) {
    let url =
      this.qfferAPIURL + "internal/" + userID + "/store/details/" + storeID;
    return this.http.put(url, storeDetails);
  }

  getAddresses(placeID, latitude, longitude) {
    let url = this.qfferAPIURL + "merchant/location/autocomplete";
    let params = new HttpParams();
    params = params.append("input", placeID);
    params = params.append("la", latitude);
    params = params.append("lo", longitude);
    return this.http.get(url, { params: params });
  }

  getAddonStatus(channel) {
    let url = this.apiUrl + "/pricing/getAddonPurchaseStatus";
    let params = new HttpParams();
    params = params.append("userId", this.tokenStorage.getUserId());
    params = params.append("channel", channel);
    return this.http.get(url, { params: params });
  }

  getPlanStatus() {
    let url = this.apiUrl + "/pricing/getPlanPurchaseStatus";
    let params = new HttpParams();
    params = params.append("userId", this.tokenStorage.getUserId());
    return this.http.get(url, { params: params });
  }

  exportSegment(data) {
    let url = this.apiUrl + "/customAudience/exportSegment";
    return this.http.post(url, data);
  }

  createFromCampaign(data) {
    let url = this.apiUrl + "/customAudience/createFromCampaign";
    return this.http.post(url, data);
  }

  cancelSubscriptionPOS() {
    let url = this.apiUrl + "/pricing/cancelPlan";
    let params = new HttpParams();
    params = params.append("userId", this.tokenStorage.getUserId());
    return this.http.get(url, { params: params });
  }

  getAIEmailTemplate(data) {
    let url = this.apiUrl + "/gpt/emailtemplate";

    return this.http.post(url, data);
  }

  getAIAnalytics(text) {
    let url = this.apiUrl + "/gpt/analytics/merchant-order";
    let data = {
      text: text,
      merchant_id: this.tokenStorage.getMerchantId(),
    };
    return this.http.post(url, data);
  }
  setRedirectPath() {
    let url = this.apiUrl + "/whatsapp/setRedirectUrl";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, null, { params: params });
  }
  getProductListAndSegmentList(search, type) {
    let url =
      this.apiUrl +
      "/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/ProductCollectionAndSegmentList";
    let params = new HttpParams();
    params = params.append("name", search);
    params = params.append("page", 1);
    params = params.append("size", 3);
    params = params.append("type", type);
    return this.http.get(url, { params: params });
  }
  getChatGptOffer(data) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/nlq/offers";
    return this.http.post(url, data);
  }
  getEmailTemplateOffers(id, offerType, type) {
    let url = this.apiUrl + "/templates/offer/email";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("offerType", offerType);
    params = params.append("channel", "EMAIL");
    params = params.append("offerId", id);
    return this.http.get(url, { params: params });
  }
  createWhatsAppMerchantTemplate(details) {
    let url = this.apiUrl + "/whatsapp/createMerchantTemplate";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("channelType", details["channelType"]);
    let data = {
      type: details["type"],
      layoutName: details["layoutName"],
      channelType: details["channelType"],
      layoutType: details["layoutType"],
      templateType: "CUSTOM",
      status: "SUBMITTED",
      templateName: details["templateName"],
      body: details["body"],
      bodyText: details["bodyText"],
      header: details["header"],
      headerText: details["headerText"],
      headerImage: details["headerImage"],
      headerDocument: details["headerDocument"],
      headerVideo: details["headerVideo"],
      footer: details["footer"] ? details["footer"] : null,
      buttons: details["buttons"] ? details["buttons"] : [],
    };
    return this.http.post(url, data, { params: params });
  }
  saveWhatsAppMerchantTemplate(details) {
    let url = this.apiUrl + "/whatsapp/saveMerchantTemplate";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    let data = {
      type: details["type"],
      layoutName: details["layoutName"],
      channelType: details["channelType"],
      layoutType: details["layoutType"],
      templateType: "CUSTOM",
      status: "SUBMITTED",
      templateName: details["templateName"],
      body: details["body"],
      bodyText: details["bodyText"],
      header: details["header"],
      headerText: details["headerText"],
      headerImage: details["headerImage"],
      headerDocument: details["headerDocument"],
      headerVideo: details["headerVideo"],
      footer: details["footer"] ? details["footer"] : null,
      buttons: details["buttons"] ? details["buttons"] : [],
    };
    return this.http.post(url, data, { params: params });
  }
  getwhatsAppFilteredCustomer(segmentId) {
    let url = this.apiUrl + "/whatsapp/filteredCustomers";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("segmentId ", segmentId);
    return this.http.get(url, { params: params });
  }
  uploadImageWhatsApp(file: FormData) {
    let url = this.apiUrl + "/whatsapp/upload/image";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, file, { params: params });
  }
  uploadVideoWhatsapp(file: FormData) {
    let url = this.apiUrl + "/whatsapp/upload/video";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, file, { params: params });
  }
  uploadDocumentWhatsapp(file: FormData) {
    let url = this.apiUrl + "/whatsapp/upload/document";
    // let mId = this.tokenStorage.getMerchantId();
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, file, { params: params });
  }
  getMergeTageWhatsapp(type, templateName) {
    let url = this.apiUrl + "/whatsapp/mergeTags";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    let data = {
      type: type,
      discountType: templateName,
      channel: "whatsapp",
    };
    return this.http.post(url, data, { params: params });
  }

  getMergeTagSMS(subLayout, type, channel) {
    let url = this.apiUrl + "/whatsapp/mergeTags";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    let data = {
      discountType: type,
      type: subLayout,
      channel: "SMS",
    };
    return this.http.post(url, data, { params: params });
  }

  getCoMarketingOffers() {
    let url = this.apiUrl + "/partnership/getComarketingOffers";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("module", "campaign");
    return this.http.get(url, { params: params });
  }
  getComarketingChannelDetails(barrierId, offer_list) {
    let url = this.apiUrl + "/partnership/getComarketingChannelDetails";
    let data = {
      formOfferDetailsList: offer_list,
      merchantId: this.tokenStorage.getMerchantId(),
      channel: "FORM",
      barrierId: barrierId,
    };
    return this.http.post(url, data);
  }
  createComarketingOffer(data) {
    let url = this.apiUrl + "/partnership/createComarketingOffers";
    return this.http.post(url, data);
  }
  scheduleOfferPartnershipPOS(data) {
    let url = this.apiUrl + "/partnership/scheduleCOMOffer";
    return this.http.post(url, data);
  }
  getComOfferDescription(id) {
    let url = this.apiUrl + "/partnership/getComOfferDescription";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("comOfferId", id);
    return this.http.get(url, { params: params });
  }
  generateOTP(data) {
    let url =
      this.apiUrl +
      "/store/" +
      this.tokenStorage.getStoreId() +
      "/customer/verify";
    return this.http.post(url, data);
  }
  verifyOTP(otp, data) {
    let url =
      this.apiUrl +
      "/store/" +
      this.tokenStorage.getStoreId() +
      "/customer/verify/" +
      otp;
    return this.http.post(url, data);
  }
  signUpCustomer(data) {
    let url =
      this.apiUrl +
      "/store/" +
      this.tokenStorage.getStoreId() +
      "/customer/signup";
    return this.http.post(url, data);
  }
  brinkSignUpMerchant(data) {
    let url = this.apiUrl + "/brinkAPI/signUpMerchant";
    return this.http.post(url, data);
  }
  brinkCreateStore(data) {
    let url = this.apiUrl + "/brinkAPI/createStore";
    return this.http.post(url, data);
  }
  brinkSyncOrder(data) {
    let url = this.apiUrl + "/brinkAPI/syncOrders";
    return this.http.post(url, data);
  }
  petPoojaSignUpMerchant(data) {
    let url = this.apiUrl + "/petpoojaAPI/signUpMerchant";
    return this.http.post(url, data);
  }
  flipOfficeSignUpMerchant(data) {
    let url = this.apiUrl + "/flipofficeAPI/signUpMerchant";
    return this.http.post(url, data);
  }
  signUpMerchantPOS(data) {
    let url = this.apiUrl + "/posMerchantAPI/signUpMerchant";
    return this.http.post(url, data);
  }
  uploadLoyaltyProgramCoinImg(data: FormData) {
    // let headers = { "Content-Type": "multipart/form-data"};
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/logo/upload";
    return this.http.post(url, data);
  }
  getLoyaltyProgramDropdowns() {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/dropdowns";
    return this.http.get(url);
  }
  saveLoyaltyProgramConfig(data) {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/save/config";
    return this.http.post(url, data);
  }
  getLoyaltyProgramConfig() {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/get/config";
    return this.http.get(url);
  }
  getLoyaltyProgramDashboardData() {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/dashboard";
    return this.http.get(url);
  }
  toggleLoyaltyProgramActivity(statusFlag: boolean) {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/changeActiveStatus/" +
      statusFlag;
    return this.http.get(url);
  }
  validateCampaignLoyaltyNodeRule(data) {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/campaign/validate";
    return this.http.post(url, data);
  }
  saveLoyaltyRule(data) {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/campaign/saveRules";
    return this.http.post(url, data);
  }
  giveRewardCoins(data) {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/giveRewardCoins";
    return this.http.post(url, data);
  }
  changeNewLoyaltyMembersTimeFrame(timeFrame) {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/dashboard/newLoyaltyMembers";
    let params = new HttpParams();
    params = params.append("timeFrame", timeFrame);
    return this.http.get(url, { params: params });
  }
  earnedByTierDropdownChange(timeFrame, type) {
    type = type == "points" ? "coins" : type;
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/dashboard/filteredDataForTiers";
    let params = new HttpParams();
    params = params.append("timeFrame", timeFrame).append("dataFor", type);
    return this.http.get(url, { params: params });
  }
  getLoyaltyDashboardAllMembersPopupDetails(
    recordsPerPage,
    pageNumber,
    tierId,
    sortBy,
    order,
    customerSearch?
  ) {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/paginatedLoyaltyCustomers";
    let params = new HttpParams();
    params = params
      .append("recordsPerPage", recordsPerPage)
      .append("pageNumber", pageNumber)
      .append("tierId", tierId)
      .append("sortBy", sortBy)
      .append("order", order);
    if (customerSearch?.length > 0) {
      params = params.append("customerName", customerSearch);
    }
    return this.http.get(url, { params: params });
  }

  deleteTierCheck(tierId) {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/deleteTierCheck/" +
      tierId;
    return this.http.get(url);
  }

  deleteChannel(data) {
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/delete";
    return this.http.post(url, data);
  }
  getIsDuplicateSurvey(surveyToken) {
    let url = this.apiUrl + "/survey/duplicateSurvey/pos/reviews";
    let params = new HttpParams();
    params = params.append("surveyToken", surveyToken);
    return this.http.get(url, { params: params });
  }
  getSurveyChannelDetails() {
    let url = this.apiUrl + "/survey/getSurveyChannelDetails";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }
  getPosDiscountCode() {
    let url = this.apiUrl + "/posMerchantAPI/getMerchantOffer";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }
  refreshSocialMediaPagesCode(channel, code) {
    // let url = this.apiUrl + "/socialMedia/getSocialMediaPages";
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/meta/refresh";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    let data = {
      channel: channel,
      code: code,
    };
    return this.http.post(url, data);
  }
  deleteAdChannel(id) {
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/adsAccount/delete";
    return this.http.delete(url);
  }
  getAdsAccount() {
    let url =
      this.apiUrl +
      "/social-media/" +
      this.tokenStorage.getMerchantId() +
      "/adsAccount";
    return this.http.get(url);
  }
  getStoreDetails() {
    let url =
      this.apiUrl +
      "/merchantData/getStoreDetails/" +
      this.tokenStorage.getStoreId();
    return this.http.get(url);
  }
  updateMerchantStoreDetails(data) {
    let url =
      this.apiUrl +
      "/merchantData/updateStoreDetails/" +
      this.tokenStorage.getStoreId();
    return this.http.post(url, data);
  }
  getCountryCode() {
    let url = this.apiUrl + "/countryCodes";
    return this.http.get(url);
  }
  generateSignupOtp(data) {
    let url = this.apiUrl + "/otp/generate";
    return this.http.post(url, data);
  }
  validateSignupOtp(data) {
    let url = this.apiUrl + "/otp/verify";
    return this.http.post(url, data);
  }
  getIntroductoryDataForUserId(userId: string) {
    let url = this.apiUrl + "/userData/getIntroductoryDataForUserId";
    let params = new HttpParams();
    params = params.append("userId", userId);
    return this.http.get(url, { params: params });
  }
  saveIntroductoryDataForUserId(data, userId) {
    let url = this.apiUrl + "/userData/saveIntroductoryData";
    let params = new HttpParams();
    params = params.append("userId", userId);
    return this.http.post(url, data, { params: params });
  }
  getOnboardingSteps(userId: string) {
    let url = this.apiUrl + "/userData/getOnboardingSteps";
    let params = new HttpParams();
    params = params.append("userId", userId);
    return this.http.get(url, { params: params });
  }
  updateOnboardingSteps(step: string, userId: string) {
    let url = this.apiUrl + "/userData/updateOnboardingSteps";
    let params = new HttpParams();
    params = params.append("userId", userId);
    params = params.append("step", step);

    return this.http.get(url, { params: params });
  }
  getOnboardingCheck(userId: string) {
    let url = this.apiUrl + "/userData/onboardingCheck";
    let params = new HttpParams();
    params = params.append("userId", userId);
    return this.http.get(url, { params: params });
  }
  getBasicStoreData(storeInfoId) {
    let url = this.apiUrl + "/onboardingStore/getBasicStoreData";
    let params = new HttpParams();
    params = params.append("storeInfoId", storeInfoId);
    params = params.append("merchantId", this.tokenStorage.getMerchantId());

    return this.http.get(url, { params: params });
  }

  createBasicStoreData(data) {
    let url = this.apiUrl + "/onboardingStore/createBasicStoreData";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, data, { params: params });
  }

  getStoreAccessData(storeId) {
    let url = this.apiUrl + "/onboardingStore/getStoreAccessData";
    let params = new HttpParams();
    params = params.append("storeId", storeId);
    params = params.append("merchantId", this.tokenStorage.getMerchantId());

    return this.http.get(url, { params: params });
  }

  saveStoreAccessData(data) {
    let url = this.apiUrl + "/onboardingStore/saveStoreAccessData";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, data, { params: params });
  }

  exportCustomerData(segmentId, type, filePath, storeName, sendToEmailId) {
    let url = this.apiUrl + "/export/data";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    let data = {
      featureName: "SEGMENTATION",
      featureId: segmentId,
      type: type,
      filePath: filePath,
      storeName: storeName,
      sendToEmailId: sendToEmailId,
    };
    return this.http.post(url, data, { params: params });
  }
  getListStore() {
    let url = this.apiUrl + "/account/listUserAccounts";
    return this.http.get(url);
  }
  selectStore() {
    let url =
      this.apiUrl + "/account/selectStore/" + this.tokenStorage.getqId();
    return this.http.post(url, null);
  }
  getSpecificStoreOffer() {
    let url =
      this.apiUrl +
      "/merchantData/getStoreDetails/" +
      this.tokenStorage.getqId();
    return this.http.get(url);
  }
  summarizeStats() {
    let url = this.apiUrl + "/cache/summarystats";
    let params = new HttpParams().set(
      "merchantId",
      this.tokenStorage.getMerchantId()
    );
    return this.http.post(url, null, { params: params });
  }
  getCustomerListDetail(id, page, size) {
    let url = this.apiUrl + "/customer-list/getCustomerListDetail";
    let params = new HttpParams();
    params = params.append("customerListId", id);
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("page", page);
    params = params.append("size", size);
    if (this.tokenStorage.getqId() !== "null") {
      params = params.append("storeId", this.tokenStorage.getqId());
    }
    return this.http.get(url, { params: params });
  }
  searchCustomerListDetail(id, page, size, customername) {
    let url = this.apiUrl + "/customer-list/searchCustomerInCustomerList/";
    let params = new HttpParams();
    params = params.append("customerListId", id);
    params = params.append("customerName", customername);
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("pageNumber", page);
    params = params.append("recordsPerPage", size);
    return this.http.get(url, { params: params });
  }
  getCustomerLists() {
    let url = this.apiUrl + "/customer-list/getCustomerLists";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }
  customerListUpdate(data) {
    let url = this.apiUrl + "/customer-list/customerListUpdate";
    return this.http.post(url, data);
  }
  deleteCustomerList(id) {
    let url = this.apiUrl + "/customer-list/deleteCustomerList";
    let params = new HttpParams();
    params = params.append("customerListId", id);
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.delete(url, { params: params });
  }
  deleteCustomersFromList(data) {
    let url = this.apiUrl + "/customer-list/deleteCustomersFromList";
    return this.http.post(url, data);
  }
  preotpverification(data) {
    let url = this.apiUrl + "/otp/pre-otp-verification";
    return this.http.post(url, data);
  }
  saveToggleCommunication(data) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/toggleCommunications";
    return this.http.post(url, data);
  }
  getToggleCommunicationdata() {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/toggleCommunications";
    return this.http.get(url);
  }
  deleteCustomerFromAll(data) {
    let url = this.apiUrl + "/merchantData/deletePOSCustomer";
    return this.http.post(url, data);
  }
  getCustomerOffer(data) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/customerOffers";
    return this.http.post(url, data);
  }
  redeemCustomerOffer(data) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/reward/redeemOffer";
    return this.http.post(url, data);
  }
  getCustomerLoginUrl() {
    let url = this.apiUrl + "/merchantData/loginLink";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }
  sendOrVerifyCustomerOTP(data, otp) {
    let url = this.apiUrl + "/auth/signin/verifyEmailOtp";
    let params = null;
    if (otp) {
      params = new HttpParams();
      params = params.append("otp", otp);
    }
    return this.http.post(url, data, { params: params });
  }

  getTotalCustomerSalesMonthlyAnalytics(data) {
    let url =
      this.apiUrl +
      "/analytics/monthlychart/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/" +
      this.tokenStorage.getMerchantId();
    return this.http.post(url, data);
  }

  getTotalCustomerSalesQuartlyAnalytics(data) {
    let url =
      this.apiUrl +
      "/analytics/quarterlychart/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/" +
      this.tokenStorage.getMerchantId();
    return this.http.post(url, data);
  }

  getFilterForAnalytics(type) {
    let url =
      this.apiUrl +
      "/analytics/filters/" +
      type +
      "/" +
      this.tokenStorage.getMerchantId();
    return this.http.get(url);
  }

  // getReportAnalytics(data, type){
  //   let url =  this.apiUrl + "/analytics/saleschart/" + this.tokenStorage.getMerchantId();
  //   return this.http.post(url,data);
  // }

  getReportChart(data, graphType, requestType, type?) {
    let url =
      this.apiUrl +
      "/analytics/" +
      graphType +
      "/" +
      this.tokenStorage.getMerchantId();
    let params = null;
    if (type) {
      params = new HttpParams();
      params = params.append("type", type);
    }
    if (requestType === "POST")
      return this.http.post(url, data, { params: params });
    else return this.http.get(url, { params: params });
  }

  getCustomerTrendCustomerList(data, graphType, type) {
    let url =
      this.apiUrl +
      "/analytics/" +
      graphType +
      "/" +
      this.tokenStorage.getMerchantId();
    let params = null;
    params = new HttpParams();
    params = params.append("type", type);
    params = params.append("typeValue", data);
    return this.http.get(url, { params: params });
  }

  getCustomerLoyatyDetails(id, page, size) {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/transactions/" +
      id +
      "/ " +
      size +
      "/ " +
      page +
      "/DESC";
    return this.http.get(url);
  }

  getWalletBalance(data) {
    let url = this.apiUrl + "/wallet/get-wallet-balance";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, data, { params: params });
  }

  getWalletHistory(data) {
    let url = this.apiUrl + "/wallet/get-wallet-history";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, data, { params: params });
  }

  addToWallet(data) {
    let url = this.apiUrl + "/wallet/add-to-wallet";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, data, { params: params });
  }

  deductFromWallet(data) {
    let url = this.apiUrl + "/wallet/deduct-balance";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, data, { params: params });
  }

  burnRewardCoins(data) {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/burnRewardCoins";
    return this.http.post(url, data);
  }

  getCustomerWalletId(id) {
    let url = this.apiUrl + "/wallet/getWalletIdFromCustomerId";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("customerId", id);
    return this.http.get(url, { params: params });
  }

  getReportSampleData(data) {
    let url = this.apiUrl + "/export/data";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, data, { params: params });
  }

  getReportFilters() {
    let url =
      this.apiUrl +
      "/loyalty/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getMerchantOnlineStore() +
      "/reports/dropdowns";
    return this.http.get(url);
  }

  getGiftCardDetails(data) {
    let url = this.apiUrl + "/list-cards";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, data, { params: params });
  }

  uploadCustomerData(data) {
    let url = this.apiUrl + "/uploadfile/customerfileupload";
    return this.http.post(url, data);
  }

  sendOffertoCustomer(data) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      this.tokenStorage.getUserId() +
      "/sendOffer";
    return this.http.post(url, data);
  }

  getABNodeMetrices() {
    let url = this.apiUrl + "/campaign/ab/metrices";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getABTestingCampaignList(recordsPerPage, pageNumber, searchValue) {
    let url = `${
      this.apiUrl
    }/campaign/ab/strategies/search/${this.tokenStorage.getMerchantId()}`;
    let params = new HttpParams();
    if (searchValue) params = params.append("name", searchValue);
    params = params.append("size", recordsPerPage);
    params = params.append("page", pageNumber);
    return this.http.get(url, { params: params });
  }

  getABPathAnalytics(strategyId, nodeId) {
    let url = `${
      this.apiUrl
    }/campaign/ab/path-analytics/merchant/${this.tokenStorage.getMerchantId()}`;
    let params = new HttpParams();
    params = params.append("strategyId", strategyId);
    params = params.append("nodeId", nodeId);
    return this.http.get(url, { params: params });
  }

  getABGraphAnalytics(data) {
    data.merchantId = this.tokenStorage.getMerchantId();
    let url = `${this.apiUrl}/campaign/ab/graph-analytics`;
    return this.http.post(url, data);
  }

  getSharableRewardEmail(mid, id) {
    let url = this.apiUrl + "/campaign/getSharableRewardEmail";
    let params = new HttpParams();
    params = params.append("merchantId", mid);
    params = params.append("id", id);
    return this.http.get(url, { params: params });
  }

  getSharableEmail(mid, id) {
    let url = this.apiUrl + "/campaign/getSharableEmailLayout";
    let params = new HttpParams();
    params = params.append("merchantId", mid);
    params = params.append("id", id);
    return this.http.get(url, { params: params });
  }

  getUploadHistory(page, size,storeId,leadsParam?) {
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("storeId", storeId);
    params = params.append("page", page);
    params = params.append("size", size);
    params = params.append("leadsUpload", leadsParam.toString());
    let url = this.apiUrl + "/uploadfile/getUploadHistory";
    return this.http.get(url, { params: params });
  }
  getCustomerOfferTxnDetails(id) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/transactions/" +
      id;
    return this.http.get(url);
  }
  updateCustomer(customerData) {
    let url = `${this.apiUrl}/merchantData/updatecustomer`;
    let data = {
      customerid: customerData["merchantCustomerId"],
      emailaddress: customerData["email"],
      mobilenumber: customerData["phone"],
      firstName: customerData["firstName"],
      lastName: customerData["lastName"],
      birthday: customerData["birthday"],
      anniversary: customerData["anniversary"],
    };
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.post(url, data, { params: params });
  }

  getEmployeeListDetail(id, page, size) {
    let url = this.apiUrl + "/employee/getEmployeeListDetail";
    let params = new HttpParams();
    params = params.append("employeeListId", id);
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("page", page);
    params = params.append("size", size);
    return this.http.get(url, { params: params });
  }

  searchEmployeeListDetail(id, page, size, customername) {
    let url = this.apiUrl + "/employee/searchEmployeeInEmployeeList";
    let params = new HttpParams();
    params = params.append("employeeListId", id);
    params = params.append("customerName", customername);
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("pageNumber", page);
    params = params.append("recordsPerPage", size);
    return this.http.get(url, { params: params });
  }

  getEmployeeLists() {
    let url = this.apiUrl + "/employee/getEmployeeList";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  employeeListUpdate(data) {
    let url = this.apiUrl + "/employee/employeeListUpdate";
    return this.http.post(url, data);
  }

  deleteEmployeeList(id) {
    let url = this.apiUrl + "/employee/deleteEmployeeList";
    let params = new HttpParams();
    params = params.append("employeeListId", id);
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.delete(url, { params: params });
  }

  deleteEmployeeFromList(data) {
    let url = this.apiUrl + "/employee/deleteEmployeesFromList";
    return this.http.post(url, data);
  }

  checkDuplicateNameInTemplate(layoutName, channel) {
    let url = this.apiUrl + "/campaign/checkTemplateNameExists";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("layoutName", layoutName);
    params = params.append("channel", channel);
    return this.http.get(url, { params: params });
  }

  getOffersOfParticularCustomer(id) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/getActiveAndRedeemedOfferOfCustomerId";
    let params = new HttpParams();
    params = params.append("customerId", id);
    return this.http.get(url, { params: params });
  }

  getCampaignsOfParticularCustomer(id) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/getAllCampaignsOfCustomerId";
    let params = new HttpParams();
    params = params.append("customerId", id);
    return this.http.get(url, { params: params });
  }

  getSurveysOfParticularCustomer(id) {
    let url = this.apiUrl + "/survey/getCustomerFeedbacks";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("customerId", id);
    return this.http.get(url, { params: params });
  }

  getOrderRevenueChartValue(id, type) {
    let url = this.apiUrl + "/analytics/customer360/orderrevenuemultiline";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("customerId", id);
    params = params.append("groupType", type);
    return this.http.get(url, { params: params });
  }

  getCustomerAllDetails(id) {
    let url =
      this.apiUrl +
      "/analytics/customer360/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      id;
    return this.http.get(url);
  }

  getSubscribedChannel(id) {
    let url = this.apiUrl + "/suppressions/getSuppressionlistOfCustomerId";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("customerId", id);
    return this.http.get(url, { params: params });
  }

  deleteCampaigns(ids) {
    let url =
      this.apiUrl +
      "/campaign-strategy/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/deleteStrategies";
    let params = new HttpParams();
    params = params.append(
      "storeId",
      this.tokenStorage.getqId() !== "null" ? this.tokenStorage.getqId() : null
    );
    return this.http.post(url, ids, { params: params });
  }

  deleteOffers(ids) {
    let url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/deleteOffers";
    let params = new HttpParams();
    params = params.append(
      "storeId",
      this.tokenStorage.getqId() !== "null" ? this.tokenStorage.getqId() : null
    );
    return this.http.post(url, ids, { params: params });
  }

  getLeads(index, size) {
    let url = this.apiUrl + "/merchantData/getLeadsWithPagination";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("pageNumber", index);
    params = params.append("recordsPerPage", size);
    if (this.tokenStorage.getqId() !== "null") {
      params = params.append("storeId", this.tokenStorage.getqId());
    }
    return this.http.get(url, { params: params });
  }

  deleteSurveys(ids) {
    let url =
      this.apiUrl + "/survey/delete/" + this.tokenStorage.getMerchantId();
    let options = {
      body: ids,
    };
    return this.http.delete(url, options);
  }

  getCustomerTag() {
    let url =
      this.apiUrl +
      "/merchant/getCustomerTagConstants/" +
      this.tokenStorage.getMerchantId();
    return this.http.get(url);
  }

  getOrChangeAutoTopUp(change?) {
    let url = this.apiUrl + "/pricing/getOrSetAutomaticTopUp";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (change !== undefined) {
      params = params.append("isAutomaticTopUp", change);
    }
    return this.http.get(url, { params: params });
  }

  getTransactionHistory(page, size) {
    let url = this.apiUrl + "/pricing/transactionHistory";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("pageNumber", page);
    params = params.append("pageSize", size);
    return this.http.get(url, { params: params });
  }

  sendContactDetails(data: any) {
    const url = this.apiUrl + "/contactus";
    return this.http.post(url, data);
  }

  getDefaultProductExclusion() {
    const url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/getDefaultProductExclusionsForOffers";
    return this.http.get(url);
  }

  saveDefaultProductExclusion(data) {
    const url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/saveDefaultProductExclusionsForOffers";
    return this.http.post(url, data);
  }

  getJourneyNamesForOffer(offerId) {
    const url =
      this.apiUrl +
      "/offer/merchant/" +
      this.tokenStorage.getMerchantId() +
      "/"+offerId
      +"/journeys";
    return this.http.get(url);
  }

  getRateOrCountInsights(data) {
    const url = this.apiUrl + "/comAnalytics/insights";
    return this.http.post(url, data);
  }

  getOfferRedeemedCount(offerId) {
    const url =
      this.apiUrl +
      "/comAnalytics/redeemedOffers/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      offerId;
    return this.http.get(url);
  }

  getModulesForInsightData(moduleName) {
    const url =
      this.apiUrl +
      "/comAnalytics/modules/" +
      moduleName +
      "/" +
      this.tokenStorage.getMerchantId();
    return this.http.get(url);
  }

  getSubModulesOfJourney(strategyId) {
    const url =
      this.apiUrl +
      "/comAnalytics/campaign/submodules/" +
      this.tokenStorage.getMerchantId() +
      "/" +
      strategyId;
    return this.http.get(url);
  }

  getDetailedCustomerDataForChannel(channel, data, page, size) {
    let url = this.apiUrl + "/comAnalytics/details/" + channel;
    let params = new HttpParams();
    params = params.append("pageNumber", page);
    params = params.append("recordsPerPage", size);
    return this.http.post(url, data, { params: params });
  }

  getClickPerformanceData(data) {
    const url = this.apiUrl + "/comAnalytics/clickPerformance";
    return this.http.post(url, data);
  }

  createAnalyticsCustomList(data){
    let url = this.apiUrl + "/comAnalytics/customList"
    return this.http.post(url, data);
  }
  getActivePlatforms() {
    let url = this.apiUrl + "/reviews/activePlatforms";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getReviewPlatforms() {
    const url = this.apiUrl + "/reviews/getReviewPlatforms";
    return this.http.get(url);
  }
  
  getPlaceId(storeId){
    const url = this.apiUrl + "/reviews/getPlaceId";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("storeInfoId", storeId);
    return this.http.get(url, { params: params });
  }

  getDailyReviewStatus(platform,storeId){
    const url = this.apiUrl + "/reviews/getReviewConfigStatus";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("platform", platform);
    params = params.append("storeInfoId", storeId);
    return this.http.get(url, { params: params });
  }

  updateReviewConfig(data){
    const url = this.apiUrl + "/reviews/updateReviewConfig";
    return this.http.post(url, data);
  }

  getAllReviews(page, size,platform,storeQid?,) {
    let url = this.apiUrl + "/reviews/getAllReviews";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("page", page);
    params = params.append("size", size);
    if (platform !== 'None') {
      params = params.append("platform", platform);
    }
    if (storeQid) {
      params = params.append("storeId", storeQid);
    } else {
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
    }
    return this.http.get(url, { params: params });
  }

  getIndividualReviewDashboard(reviewId) {
    let url = this.apiUrl + "/reviews/" + reviewId;
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

  getOverallReviewdashboard(platform,storeQid?) {
    let url = this.apiUrl + "/reviews/getReviewNPSScore";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (platform !== 'None') {
      params = params.append("platform", platform);
    }
    if (storeQid) {
      params = params.append("storeId", storeQid);
    } else {
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
    }
    return this.http.get(url, { params: params });
  }

  getReviewTopicDivision(platform,storeid?) {
    let url = this.apiUrl + "/reviews/getReviewTopicsAndSentiment";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (platform !== 'None') {
      params = params.append("platform", platform);
    }
    if (storeid) {
      params = params.append("storeId", storeid);
    } else {
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
    }
    return this.http.get(url, { params: params });
  }

  getReviewsTextAnalysisTopicCount(platform,storeid?) {
    let url = this.apiUrl + "/reviews/getReviewTopicsCount";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    if (platform !== 'None') {
      params = params.append("platform", platform);
    }
    if (storeid) {
      params = params.append("storeId", storeid);
    } else {
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
    }
    return this.http.get(url, { params: params });
  }

  getReviewsTopicTimeSeries(timeSeriesType, platform, storeid?) {
    let url = this.apiUrl + "/reviews/getReviewTopicCountTimeSeries";
    let params = new HttpParams();
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    params = params.append("type", timeSeriesType);
    if (platform !== 'None') {
      params = params.append("platform", platform);
    }
    if (storeid) {
      params = params.append("storeId", storeid);
    } else {
      if (this.tokenStorage.getqId() !== "null") {
        params = params.append("storeId", this.tokenStorage.getqId());
      }
    }
    return this.http.get(url, { params: params });
  }

  getReviewsActiveStore() {
    let params = new HttpParams();
    let url = this.apiUrl + "/reviews/activeStoresOfMerchant";
    params = params.append("merchantId", this.tokenStorage.getMerchantId());
    return this.http.get(url, { params: params });
  }

}
