import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Component, Injectable } from "@angular/core";

@Injectable()

export class SnackbarCollection {

  constructor(private snackBar: MatSnackBar) { }

  openSnackBar(message: string, time : number) {
    let config = new MatSnackBarConfig();
    config.duration = time;
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.panelClass = ['snackBarStyle']
    let action = ''
    this.snackBar.open(message, action, config);
  }

  dismiss() {
    this.snackBar.dismiss();
  }
}