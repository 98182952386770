import { 
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  Inject
 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ConfirmationDialogBoxComponent, SuccessDialogComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";


@Component({
  selector: 'app-add-new-customer-in-list-dialog-box',
  templateUrl: './add-new-customer-in-list-dialog-box.component.html',
  styleUrls: ['./add-new-customer-in-list-dialog-box.component.css']
})
export class AddNewCustomerInListDialogBoxComponent implements OnInit {

  validWidgets = null;
  selected = "Purchase Frequency";
  merchantID = this.tokenService.getMerchantId();
  revenueSortBy = "All-Time";
  numberOfOrdersSortBy = "All-Time";
  revenueTime = "ALL";
  numberOfOrdersTime = "ALL";
  pageSize = 10;
  pageIndex = 1;
  customers = [];
  popupElementID;
  totalCustomers;
  sortBy = "";
  filters = {
    segments: [],
  };
  showCustomers = "all";
  searchValue = "";
  filterSegments = [];
  selectedSortBy = "Desc";
  timeout;
  sub: Subscription;
  @ViewChild("search") searchElement: ElementRef;
  revCurrency;
  mobileView;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  showFiltersDiv = false;
  segments = {};
  loading = true;
  overallTotalCustomers=null;
  selectedCustomerList = []; 
  customerListName =  "";
  submit = false;

  
  constructor(
    private _WidgetConfigService: WidgetConfigService,
    private apiCall: ApiCallService,
    private tokenService: TokenStorageService,
    private date_format: getdateformatService,
    private dialog: MatDialog,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private router: Router,
    public dialogRef: MatDialogRef<AddNewCustomerInListDialogBoxComponent>,
    private snackBar: SnackbarCollection,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    window["screen-name"] = "ALL CUSTOMERS";
  }

  @HostListener("document:click", ["$event"])
  closeSegmentsDiv() {
    let ele = document.getElementById(this.popupElementID);
    if (ele) ele.setAttribute("style", "display:none");
  }
  ngOnInit() {
    if (this.mobileMedia.matches) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.getWidgets();
    this.getCustomers();
    console.log(this.data);
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  getCustomers(resetPagination?) {
    this.loading = true;
    if(resetPagination) this.pageIndex = 1;
    this.apiCall
      .getCustomersV2(
        this.merchantID,
        this.pageSize,
        this.pageIndex,
        this.filterSegments
      )
      .subscribe((response) => {
        this.showCustomers = "all";
        if (this.mobileView) {
          (response["body"]).forEach((element) => {
            this.customers.push(element);
          });
        } else {
          this.customers = (response["body"]['customerDataList']);
        }
        // if (
        //   this.filterSegments.indexOf("ALL") != -1 ||
        //   this.filterSegments.length == 0
        // ){
        //   // this.getTotalCustomerCount();
        //   this.totalCustomers=this.overallTotalCustomers;
        // }
        //  this.getFilteredCustomersCount();
        // this.sort(this.selectedSortBy);
        // let res;
        // this.apiCall.getAllCustomerSegments().subscribe((response) => {
        //   res = response;
        // this.customers.forEach((customer) => {
        //   let segments = [];
        //   customer["customerSegments"]?.forEach((element) => {
        //     let i = 0;
        //     (res["body"]).forEach((element2) => {
        //       if(element2["id"] === element["id"]) {
        //         i++;
        //       }
        //                 });
        //                 if(i == 0) {
        //                   segments.push(element.segmentName);
        //                 }
        //   });
        //   customer.allSegments = segments.toString();

        //   });
        // });
        this.totalCustomers = response["body"]['totalCount'];
        this.customers.forEach((customer)=>{
          if(this.alreadyIncludesCustomer(customer)) {
            customer['alreadyInList'] = true;
          } else {
            customer['alreadyInList'] = false;
          }
          if(customer['customerSegments'] && customer['customerSegments'].length>0) {
            customer.segments = customer['customerSegments'].map((segment)=>segment.segmentName);
          } else {
            customer.segments = [];
          }
        });
        this.loading = false;
      },(err)=>{
        this.snackBar.openSnackBar('Failed to fetch custom list',5000);
      });
  }

  showSegments(event, i) {
    let ele = document.getElementById(this.popupElementID);
    if (ele) ele.setAttribute("style", "display:none");
    let id = event.srcElement.parentElement.id;
    event.stopPropagation();
    let element = document
      .getElementById(id)
      .getElementsByClassName("qb-segment-popup")[0];
    element.setAttribute("style", "display:flex");
    this.popupElementID = "popup" + i;
  }

  refreshPaginatedData(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if (this.showCustomers === "all") this.getCustomers();
    else this.searchCustomer(this.searchValue, false);
  }

  showSearch() {
    this.searchElement.nativeElement.focus();
  }

  onKeySearch(event: any, resetPagination) {
    clearTimeout(this.timeout);
    var $this = this;
    if(event.length !==0){
      this.timeout = setTimeout(function () {
        // if (event.keyCode != 13) {
          $this.searchCustomer(event, resetPagination); //Your function goes here
        // }
      }, 600);
    }
    else{//if search value is 0
      this.getCustomers(resetPagination);
    }
  }

  searchCustomer(searchValue, resetPagination) {
    this.loading = true;
    if (resetPagination) this.pageIndex = 1;
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.apiCall
      .searchCustomers(
        this.merchantID,
        searchValue,
        this.revenueTime,
        this.numberOfOrdersTime,
        this.pageSize,
        this.pageIndex,
        this.filterSegments
      )
      .subscribe((response) => {
        this.showCustomers = "search";
        if (!this.mobileView) {
          this.customers = (response["body"]['customerDataList']);
        } else {
          if (this.searchValue !== searchValue) {
            this.customers = (response["body"]['customerDataList']);
          } else {
            (response["body"]['customerDataList']).forEach((element) => {
              this.customers.push(element);
            });
          }
        }
        this.searchValue = searchValue;
        this.totalCustomers = response["body"]['totalCount'];
        this.customers.forEach((customer)=>{
          if(customer['customerSegments'] && customer['customerSegments'].length>0) {
            customer.segments = customer['customerSegments'].map((segment)=>segment.segmentName);
          } else {
            customer.segments = [];
          }
          if(this.alreadyIncludesCustomer(customer)) {
            customer['alreadyInList'] = true;
          } else {
            customer['alreadyInList'] = false;
          }
        });
        this.loading = false;
      },(err)=>{
        this.snackBar.openSnackBar('Failed to search customer',5000);
      });
    if (searchValue === "") this.getCustomers();
  }

checkWhetherSelected(id){
  if(this.selectedCustomerList.indexOf(id) === -1){
    return false;
  }
  else{
    return true;
  }
}
addCustomerToList(id){
  console.log(id);
  if(this.selectedCustomerList.indexOf(id) === -1){
    this.selectedCustomerList.push(id);
  }
  else{
    const index = this.selectedCustomerList.indexOf(id);
    this.selectedCustomerList.splice(index, 1);
  }
  console.log(this.selectedCustomerList)
}
customerListUpdateApi(data): Promise<object>{
  return new Promise((resolve, reject) =>{
    this.apiCall.customerListUpdate(data).subscribe(
      (res) => {
        console.log(res);
        resolve(res);
      },
      (err) => {
        console.log(err.error);
        reject(false);
      }
    );
  });
}

employeeListUpdateApi(data): Promise<object>{
  return new Promise((resolve, reject) =>{
    this.apiCall.employeeListUpdate(data).subscribe(
      (res) => {
        console.log(res);
        resolve(res);
      },
      (err) => {
        console.log(err.error);
        reject(false);
      }
    );
  });
}

close(decision){
  this.dialogRef.close(decision);
}
notAlreadyAdded(id){
  if(this.data.customerIds.indexOf(id) === -1){
    return true;
  }
  else{
    return false;
  }
}

  getDateInFormatForDisplay(dateInp: number): string | null {
    if (!dateInp) return null; 
    let date = new Date(dateInp);
    const year = date.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }

  submitCustomerListData(){
    this.submit = true;
    if(this.selectedCustomerList.length > 0){
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Save Custom List", ['POS_PAGE']),
          message: this._i18nDynamicTranslate.transform("Do you wish to Save the Cusomer List?", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          successButtonText: this._i18nDynamicTranslate.transform("Save", ['POS_PAGE']),
          "data-widget": "CUSTOMER LIST CREATION",
          "data-button": "Confirm: Save Customer List",
          "data-item": "",
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          let data :any = {};
          data.customerListName = this.data["customer-list-name"];
          data.merchantId = this.tokenService.getMerchantId();
          data.customerListId = this.data["data-item"]
          data.customerIds = this.selectedCustomerList;
          data.operation = "update";
          this.customerListUpdateApi(data).then((result) => {
            if(result){
             console.log(result)
             const dialogRef = this.dialog.open(SuccessDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              width: "20%",
              disableClose: true,
              data: {
                message: this._i18nDynamicTranslate.transform("Your Custom List has been successfully created", ['POS_PAGE']),
                buttonText: this._i18nDynamicTranslate.transform("Back to Custom List", ['POS_PAGE']),
              },
            });
            dialogRef.afterClosed().subscribe((re) => {
               this.close(true);
            });
            }
          });
        }
      });
    }
  }

  submitEmployeeListData(){
    this.submit = true;
    if(this.selectedCustomerList.length > 0){
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Save Employee List", ['POS_PAGE']),
          message: this._i18nDynamicTranslate.transform("Do you wish to Save the Employee List?", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          successButtonText: this._i18nDynamicTranslate.transform("Save", ['POS_PAGE']),
          "data-widget": "CUSTOMER LIST CREATION",
          "data-button": "Confirm: Save Employee List",
          "data-item": "",
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          let data :any = {};
          data.employeeListName = this.data["customer-list-name"];
          data.employeeDesignationName = this.data["employee-designation-name"]
          data.merchantId = this.tokenService.getMerchantId();
          data.employeeListId = this.data["data-item"]
          data.customerIds = this.selectedCustomerList;
          data.operation = "update";
          this.employeeListUpdateApi(data).then((result) => {
            if(result){
             console.log(result)
             const dialogRef = this.dialog.open(SuccessDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              width: "20%",
              disableClose: true,
              data: {
                message: this._i18nDynamicTranslate.transform("Your Employee List has been successfully created", ['POS_PAGE']),
                buttonText: this._i18nDynamicTranslate.transform("Back to Employee List", ['POS_PAGE']),
              },
            });
            dialogRef.afterClosed().subscribe((re) => {
               this.close(true);
            });
            }
          });
        }
      });
    }
  }

  alreadyIncludesCustomer(customer) {
    return this.data.customers.some(cus=>cus.merchantCustomerId == customer.merchantCustomerId)
  }

}
