import { Component, HostListener, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { ConfirmationDialogBoxComponent } from "../confirmation-dialog-box/confirmation-dialog-box.component";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";

@Component({
  selector: "app-analytics-customer-list-dialog-box",
  templateUrl: "./analytics-customer-list-dialog-box.component.html",
  styleUrls: ["./analytics-customer-list-dialog-box.component.css"],
})
export class AnalyticsCustomerListDialogBoxComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AnalyticsCustomerListDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private snackBar: SnackbarCollection,
    private apiCall: ApiCallService,
    private tokenService: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText
  ) {}

  pageSize = 50;
  pageIndex = 1;
  customerDetails = [];
  urlDetails = [];
  totalCount = 0;
  loading = false;
  urlLoading = false;

  ngOnInit(): void {
    console.log(this.data);
    if (!this.data.isClicked) {
      this.getCustomers();
    } else {
      this.getUrlInfo();
    }
  }

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {
      if (this.data.payLoadData.event !== "redeemed") {
        this.pageIndex += 1;
        if(this.totalCount != this.customerDetails.length)
          this.getCustomers();
      }
    }
  }

  submit(decision) {
    this.dialogRef.close(decision);
  }

  getCustomers() {
    if (this.data.payLoadData.event !== "redeemed") {
      this.loading = true;
      this.getDetailedCustomerDataForChannelApi(
        this.data.payLoadData.channel,
        this.data.payLoadData,
        this.pageIndex,
        this.pageSize
      ).then(
        (response) => {
          if(response["body"]["analyticsCustomerList"]){
            this.customerDetails = [
              ...this.customerDetails,
              ...response["body"]["analyticsCustomerList"],
            ];
          }
          if(!this.data.payLoadData["url"]){
            this.totalCount = response["body"]["customerCount"];            
          }
          this.loading = false;
        },
        (reject) => {}
      );
    } else {
      this.customerDetails = this.data.customerData.customerIdRedeemMap;
      this.totalCount = this.data.customerData.customerCount;
    }
  }

  getUrlInfo() {
    this.urlLoading = true;
    this.getClickPerformanceDataApi(this.data.payLoadData).then(
      (response) => {
        this.urlDetails = response["body"];
        this.urlLoading = false;
      },
      (reject) => {
        this.urlLoading = false;
      }
    );
  }

  showUrlCustomerDetails(url, count) {
    this.data.payLoadData["url"] = url;
    this.totalCount = count;
    this.getCustomers();
    this.data.isClicked = false;
    this.data.header = "Customer Details";
  }

  backToUrlListing() {
    this.data.payLoadData["url"] = null;
    this.data.header = "Click Performance";
    this.data.isClicked = true;
    this.customerDetails = [];
    this.pageIndex = 1;
  }

  submitCustomerListData() {
    const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Create Custom List", [
          "POS_PAGE",
        ]),
        // message: this._i18nDynamicTranslate.transform("Do you wish to Save the Cusomer List?", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", [
          "POS_PAGE",
        ]),
        saveustomerList: this._i18nDynamicTranslate.transform("Save", [
          "POS_PAGE",
        ]),
        "data-widget": "CUSTOMER LIST CREATION",
        "data-button": "Confirm: Save Custom List",
        "data-item": "",
        apiData: this.data
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const dialogRef = this.dialog.open(SuccessDialogComponent, {
          panelClass: "no-padding-dialog-popup",
          width: "20%",
          disableClose: true,
          data: {
            message: this._i18nDynamicTranslate.transform(
              "Your Custom List has been successfully created",
              ["POS_PAGE"]
            ),
            buttonText: this._i18nDynamicTranslate.transform("OK", [
              "POS_PAGE",
            ]),
          },
        });
        dialogRef.afterClosed().subscribe((re) => {
          this.dialogRef.close();
        });
      }
    });
  }

  selectedCustomerList() {
    let customerIds = [];
    this.data["customerDetails"].forEach((data) => {
      customerIds.push(data.customer_id);
    });
    return customerIds;
  }

  customerListCreateApi(data): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.createAnalyticsCustomList(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(false);
        }
      );
    });
  }

  getDetailedCustomerDataForChannelApi(
    channel,
    data,
    page,
    size
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall
        .getDetailedCustomerDataForChannel(channel, data, page, size)
        .subscribe(
          (res) => {
            console.log(res);
            resolve(res);
          },
          (err) => {
            console.log(err.error);
            reject(err.error);
          }
        );
      // let res = {
      //   message: "SUCCESS",
      //   body: {
      //     customerCount: 2,
      //     analyticsCustomerList: [
      //       {
      //         customerId: "abc123",
      //         customerName: "John Doe",
      //         phoneNumber: "1234567890",
      //         email: "john.doe@example.com",
      //         url: "https://example.com/customer/abc123", //for Clicked Event
      //         eventDate: "2024-10-22",
      //         eventTime: "14:30:00",
      //       },
      //       {
      //         customerId: "def456",
      //         customerName: "Jane Smith",
      //         phoneNumber: "9876543210",
      //         email: "jane.smith@example.com",
      //         url: "https://example.com/customer/def456",
      //         eventDate: "2024-10-22",
      //         eventTime: "15:45:00",
      //       },
      //     ],
      //   },
      // };
      // resolve(res);
    });
  }

  getClickPerformanceDataApi(data): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getClickPerformanceData(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
      // let res = {
      //   message: "SUCCESS",
      //   body: {
      //     clickPerformance: [
      //       {
      //         allClicks: "100",
      //         uniqueUrls: "xyeyeyq.com",
      //         uniqueClicks: "80",
      //       },
      //       {
      //         allClicks: "200",
      //         uniqueUrls: "qoqmoq.com",
      //         uniqueClicks: "150",
      //       },
      //     ],
      //   },
      // };
      // resolve(res);
    });
  }
}