<div style="display: flex;
display: flex;
flex-direction: column;background: #efefef;padding: 30px;">


  <div style="width: 100%;display:flex;justify-content: space-between;align-items: center;">
    <div>{{'SHARED_PAGE.OFFER_SUMMARY' | i18nTranslate}}</div>
    <div>
      <mat-icon (click)="closedialog()" style="cursor: pointer;">close</mat-icon>
    </div>
  </div>
  <div *ngIf="!loading">
    <div class="offer-summary-card">
      <div class="label-title">{{'SHARED_PAGE.SUMMARY' | i18nTranslate}}</div>
      <div style="width:100%;    margin-top: 16px;">
        <div *ngIf="offerTemplateName.length>0" style="font-weight: 600;">{{offerTemplateName}}</div>
        <!-- <div *ngIf="offerDiscountCode.length>2"> <span><span>
              <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
            </span><span>Discount Code&nbsp;</span><span style="font-weight:600;">{{offerDiscountCode}}</span></span>
        </div> -->


        <div *ngIf="hurdleRewardType=='PERCENTAGE_OFF'&&percentageOff">
        <span><span><mat-icon style="font-size:12px">fiber_manual_record</mat-icon> </span> {{'SHARED_PAGE.PERCENTAGE_OFF' | i18nTranslate : percentageOff}} <span *ngIf="discountAndShippingType=='ORDER'"> {{'SHARED_PAGE.ON_ALL_PRODUCTS' | i18nTranslate}}</span>
          <span *ngIf="discountAndShippingType=='COLLECTION'">
            <span> on </span>
            <span *ngIf="selectedRewardCollectionList?.length > 0">
              <span *ngFor="let collection of selectedRewardCollectionList" style="padding: 0px 3px;
              border: 1px solid var(--main-brd-grey);
              border-radius: 3px;
              margin-left: 4px;">
                  {{collection.collectionName}}
              </span>
            </span>
            <!-- <span *ngIf="rewardPersonalizationType!='PERSONALIZED'" style="margin-left: 4px;">
              collection(s)
            </span>
            <span *ngIf="rewardPersonalizationType=='PERSONALIZED' && selectedRewardProductList?.length > 0">
              personalized collection(s)<span style="    padding: 1px;
              border: 1px solid var(--main-bg-yellow);
              border-radius: 3px;
              color: var(--main-bg-yellow);
              font-size: 10px;">AI Assistance</span>
            </span> -->
          </span>
          <span
            *ngIf="discountAndShippingType=='PRODUCT'">
             <span>on </span>
             <span *ngIf="selectedRewardProductList?.length > 0">
              <span *ngFor="let product of selectedRewardProductList" style="padding: 0px 3px;
              border: 1px solid var(--main-brd-grey);
              border-radius: 3px;
              margin-left: 4px;">
                  {{product.variantName}}
              </span>
             </span>
             <span *ngIf="rewardPersonalizationType!='PERSONALIZED'" style="margin-left: 4px;">
              product(s)
              </span>
              <span *ngIf="rewardPersonalizationType=='PERSONALIZED'" style="margin-left: 4px;">
                personalized product(s) <span style="    padding: 1px;
                border: 1px solid var(--main-bg-yellow);
                border-radius: 3px;
                color: var(--main-bg-yellow);
                font-size: 10px;">AI Assistance</span>
              </span>
            </span>
             .</span>
      </div>

      <div *ngIf="hurdleRewardType=='FIXED_AMOUNT_OFF'&& amountOff">
          <span><span><mat-icon style="font-size:12px">fiber_manual_record</mat-icon> </span>
        {{currencySymbol}} {{amountOff}} off <span *ngIf="discountAndShippingType=='ORDER'"> {{'SHARED_PAGE.ON_ALL_PRODUCTS' | i18nTranslate}}</span>
        <span *ngIf="discountAndShippingType=='COLLECTION'">
            <span> on </span>
            <span *ngIf="selectedRewardCollectionList?.length > 0">
              <span *ngFor="let collection of selectedRewardCollectionList" style="padding: 0px 3px;
              border: 1px solid var(--main-brd-grey);
              border-radius: 3px;
              margin-left: 4px;">
                  {{collection.collectionName}}
              </span>
            </span>
            <span *ngIf="rewardPersonalizationType!='PERSONALIZED' && selectedRewardCollectionList?.length > 0" style="margin-left: 4px;">
              collection(s)
            </span>
            <span *ngIf="rewardPersonalizationType=='PERSONALIZED' && selectedRewardCollectionList?.length > 0">
              personalized collection(s)<span style="    padding: 1px;
              border: 1px solid var(--main-bg-yellow);
              border-radius: 3px;
              color: var(--main-bg-yellow);
              font-size: 10px;">AI Assistance</span>
            </span>
        </span>
          <span
            *ngIf="discountAndShippingType=='PRODUCT'">
             <!-- </span> -->
             <span>on </span>
             <span *ngIf="selectedRewardProductList?.length > 0">
              <span *ngFor="let product of selectedRewardProductList" style="padding: 0px 3px;
              border: 1px solid var(--main-brd-grey);
              border-radius: 3px;
              margin-left: 4px;">
                  {{product.variantName}}
              </span>
             </span>
             <span *ngIf="rewardPersonalizationType!='PERSONALIZED'" style="margin-left: 4px;">
                product(s)
             </span>
             <span *ngIf="rewardPersonalizationType=='PERSONALIZED'" style="margin-left: 4px;">
              personalized product(s) <span style="    padding: 1px;
              border: 1px solid var(--main-bg-yellow);
              border-radius: 3px;
              color: var(--main-bg-yellow);
              font-size: 10px;">AI Assistance</span>
             </span>
            </span>
             .</span>
      </div>


      <div *ngIf="hurdleRewardType!='BUY_X_GET_Y'">
        <span><span>
            <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
          </span>
          <span *ngIf="rewardMinReqs=='NONE'">{{'SHARED_PAGE.NO_MINIMUM_REQ' | i18nTranslate}}</span>
          <span *ngIf="rewardMinReqs=='MIN_ORDER_AMOUNT'&&rewardMinReqsAmount>0">{{'SHARED_PAGE.MINUIMUM_PURCHASE' | i18nTranslate}}
            {{currencySymbol}} {{rewardMinReqsAmount}} </span>
          <span *ngIf="rewardMinReqs=='MIN_ORDER_QUANTITY'&&rewardMinReqsQuantity>0">{{'SHARED_PAGE.MINIMUM_PURCHASE_QUANTITY' | i18nTranslate : rewardMinReqsQuantity}} </span>
        </span>

      </div>



      <div *ngIf="hurdleRewardType=='BUY_X_GET_Y'">


        <div>
          <span>
            <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
          </span>

          <span *ngIf="barrierType=='PRODUCT_BASED'">{{'SHARED_PAGE.BUY' | i18nTranslate}} {{hurdleProductCount}} <span *ngIf="hurdleProductCount>1">{{'SHARED_PAGE.ITEMS' | i18nTranslate}}</span><span *ngIf="hurdleProductCount<2">{{'SHARED_PAGE.ITEM' | i18nTranslate}}</span></span>
          <span></span>
          <span *ngIf="barrierType=='SPENT_BASED'">{{'SHARED_PAGE.BUY_FOR' | i18nTranslate}} {{currencySymbol}}
            {{hurdleSpentAmount}}
          </span>


          <span>&nbsp;, {{'SHARED_PAGE.GET' | i18nTranslate}} {{hurdleRewardProductCount}} <span *ngIf="hurdleRewardProductCount>1">{{'SHARED_PAGE.ITEMS' | i18nTranslate}}</span><span *ngIf="hurdleRewardProductCount<2">{{'SHARED_PAGE.ITEM' | i18nTranslate}}</span></span>
          <span *ngIf="atADiscountedValue=='PERCENTAGE_OFF'&&rewardpercentageOff>0">&nbsp;{{'SHARED_PAGE.FOR_OFF' | i18nTranslate : rewardpercentageOff}} </span>
          <span *ngIf="atADiscountedValue=='FREE'">&nbsp;{{'SHARED_PAGE.FOR_FREE' | i18nTranslate}}</span>


        </div>

        <!-- <div>
          <span>
            <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
          </span>

          <span *ngIf="barrierType=='PRODUCT_BASED'">Customer has to buy atleast {{hurdleProductCount}} item</span>
          <span></span>
          <span *ngIf="barrierType=='SPENT_BASED'">Customer has to buy for atleast {{currencySymbol}}
            {{hurdleSpentAmount}}
          </span>


        </div> -->


        <!-- <div style="display: flex;">
          <span>
            <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
          </span>

          <div *ngIf="hurdleProductInnerType=='PRODUCT'"><span *ngIf="barrierParamType=='PERSONALIZED'">Buy
              {{productPropensity}} difficulty level product </span><span
              *ngIf="barrierParamType!='PERSONALIZED'&& selectedBarrierProductList.length>0">Buy
              {{selectedBarrierProductList.length}}
              item</span></div>
          <div *ngIf="hurdleProductInnerType=='CATEGORY'"><span *ngIf="barrierParamType=='PERSONALIZED'">Buy <span
                *ngIf="barrierType=='PRODUCT_BASED'"> {{hurdleProductCount}} </span> item from
              {{productPropensity}} difficulty level collection </span> <span
              *ngIf="barrierParamType!='PERSONALIZED'&&selectedBarrierCollectionList.length>0">Buy any item from
              {{selectedBarrierCollectionList.length}} collection </span></div>


          <div *ngIf="buyXGetYType=='PRODUCT'&&selectedRewardProductList.length>0">, get
            {{hurdleRewardProductCount}} quantity of {{selectedRewardProductList.length}}
            product </div>
          <div *ngIf="buyXGetYType=='COLLECTION'&&selectedRewardCollectionList.length>0">, get any
            {{hurdleRewardProductCount}} item from {{selectedRewardCollectionList.length}} collection</div>

          <div *ngIf="atADiscountedValue=='PERCENTAGE_OFF'&&rewardpercentageOff>0"> &nbsp;for
            {{rewardpercentageOff}}% off.</div>
          <div *ngIf="atADiscountedValue=='FREE'">&nbsp; for free.</div>

        </div> -->

      </div>


      <div>
        <div> <span *ngIf="(islimitNoOfTry&&!((hurdleRewardType!='FREE_SHIPPING'&&hurdleRewardType!='BUY_X_GET_Y'&&rewardPersonalizationType === 'PERSONALIZED')||(hurdleRewardType==='BUY_X_GET_Y'&&barrierParamType === 'PERSONALIZED')))||islimitToOneUsePerCustomer">
            <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
          </span>
          <span *ngIf="islimitNoOfTry&&!((hurdleRewardType!='FREE_SHIPPING'&&hurdleRewardType!='BUY_X_GET_Y'&&rewardPersonalizationType === 'PERSONALIZED')||(hurdleRewardType==='BUY_X_GET_Y'&&barrierParamType === 'PERSONALIZED'))">Limit of {{noOfTry}} use<span *ngIf="noOfTry>1">s</span></span> <span
            *ngIf="islimitNoOfTry&&noOfTry&&islimitToOneUsePerCustomer&&!(((hurdleRewardType!='FREE_SHIPPING'&&hurdleRewardType!='BUY_X_GET_Y'&&rewardPersonalizationType === 'PERSONALIZED')||(hurdleRewardType==='BUY_X_GET_Y'&&barrierParamType === 'PERSONALIZED')))">,&nbsp;</span> <span
            *ngIf="islimitToOneUsePerCustomer">{{'SHARED_PAGE.ONE_USE_PER_CUSTOMER' | i18nTranslate}}.</span>
        </div>
      </div>

      <div *ngIf="rewardTimeToComplete && !isLImitedTime">
        <span><span>
            <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
          </span>{{'SHARED_PAGE.VALID_FOR_DAY' | i18nTranslate : rewardTimeToComplete}}<span *ngIf="rewardTimeToComplete>1">s</span></span>
      </div>
      <div *ngIf="isLImitedTime">
        <span><span>
            <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
          </span>{{'OFFER_PAGE.VALID_TIME_PERIOD' | i18nTranslate : (startDate.value | date: getDateFormat("MMM/dd/yyyy")) : (endDate.value | date: getDateFormat("MMM/dd/yyyy")) : (transformTo24HourFormat(startTime.value) | customTime) : (transformTo24HourFormat(endTime.value) | customTime)}}</span>
      </div>
    </div>

    <div *ngIf="redeemableAtStores?.length > 0">
      <span>
        <span>
          <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
        </span>
        <span *ngIf="redeemableAtStores.includes('All Stores');else redeemableAtStoresDisplay">Redeemable at all stores</span>
        <ng-template #redeemableAtStoresDisplay>
          Redeemable at <span *ngFor="let storeName of redeemableAtStores" style="padding: 0px 3px;
          border: 1px solid var(--main-brd-grey);
          border-radius: 3px;
          margin-left: 4px;">{{storeName}}</span> Store(s)
        </ng-template>
      </span>

    </div>

    <div *ngIf="excludedProductList?.length > 0">
      <span>
        <span>
          <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
        </span>
        <span>Excluded Products: </span>
        <span *ngIf="excludedProductList?.length > 0">
          <span *ngFor="let product of excludedProductList" style="padding: 0px 3px;
          border: 1px solid var(--main-brd-grey);
          border-radius: 3px;
          margin-left: 4px;">
              {{product.productName}}
          </span>
        </span>
      </span>

    </div>

    <div *ngIf="excludedCategoryList?.length > 0">
      <span>
        <span>
          <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
        </span>
        <span>Excluded Cateogry </span>
        <span *ngIf="excludedCategoryList?.length > 0">
          <span *ngFor="let product of excludedCategoryList" style="padding: 0px 3px;
          border: 1px solid var(--main-brd-grey);
          border-radius: 3px;
          margin-left: 4px;">
              {{product.productCategory}}
          </span>
        </span>
      </span>

    </div>


  </div>

  <div *ngIf="loading" style="width: 100%;display: flex;justify-content: center;align-items: center;min-height: 400px;">
    <mat-spinner diameter="15"></mat-spinner>
  </div>



</div>