<div class="header" *ngIf="!dataBean">
    <span style="text-align: center; font-weight: bold;" *ngIf="showData.type === 'product'">PRODUCT LIST</span>
    <span style="text-align: center; font-weight: bold;" *ngIf="showData.type === 'category'">CATEGORY LIST</span>
    <span style="text-align: center; font-weight: bold;" *ngIf="showData.type === 'excludedProduct'">EXCLUDED LIST</span>
    <mat-icon class="close-icon" (click)="submit(false)">close</mat-icon>
  </div>
  <!-- <mat-spinner diameter="15" style="margin-left: 45%" *ngIf="apiloading"></mat-spinner> -->
  <div style="overflow-y: auto; height: 420px;" *ngIf="listOfItems.length !== 0">
    <div *ngFor="let list of listOfItems; let i = index" style="padding: 16px;
    font-size: 14px;">
    <div style="display: flex; justify-content: space-between;">
      <li>{{list.product_name}}</li>
    </div>
    </div>
  </div>
  