import { DatePipe, getCurrencySymbol } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-offer-summary-dialog",
  templateUrl: "./offer-summary-dialog.component.html",
  // styleUrls: ["../../pos-management/offer-creation/offer-creation.component.css"],
})
export class OfferSummaryDialogComponent implements OnInit {
  submitClicked = false;
  offerTemplateName = "";
  offerDiscountCode = "";
  islimitNoOfTry = false;
  noOfTry = 1;
  islimitToOneUsePerCustomer = false;
  previewmode = false;
  validWidgets;
  saveAndScheduleButtonLoading = false;
  saveAndExitButtonLoading = false;
  noneBarrierCount = 0;
  buyXGetYCount = 0;
  productCategory = null;
  hurdleRewardProductCategory = null;
  message = "";
  //discountType="";
  hurdleProduct = null;
  hurdleRewardType = "PERCENTAGE_OFF";
  percentageOff;
  amountOff = null;
  currencySymbol;
  revCurrency;
  // hurdleProductInnerType = "ANY_PRODUCT";
  barrierType = "PRODUCT_BASED";
  barrierParamType = "NON_PERSONALIZED";
  rewardPersonalizationType = "NON_PERSONALIZED";
  rewardPropensity = "LOW";
  rewardMinReqs = "NONE";
  rewardMinReqsAmount = null;
  rewardMinReqsQuantity = null;
  rewardTimeToComplete = null;
  productCategoryType = null;
  fullproductListLength = null;
  fullproductCategoryListLength = null;
  productType;
  offerReady = false;
  readyToBuild = false;
  fieldsetDisabled = "false";
  apiProductList = [];
  masterOffer;
  queryparamofferId = null;
  showofferTemplateSkeleton = false;

  hurdleProductCount = null;
  hurdleProductVariant = null;
  hurdleProductCategory = null;
  rewardProductVariant = null;
  hurdleRewardProduct = null;
  hurdleRewardProductCount = null;
  amountOrPercentDiscount = null;
  discountAndShippingType = "ORDER";
  freeShippingType = "NONE";
  buyXGetYDiscount = null;
  freeShippingDiscount = null;
  product = null;
  barrierListList = null;
  rewardListList = null;
  freeShippingMinimumAmount = null;
  freeShippingMinimumOrderCount = null;
  productPropensity = "LOW";
  hurdleTimeToComplete = null;
  hurdleRewardToggle = false;
  hurdleSpentInnerType = "AMOUNT";
  rewardTemplate = null;
  rewardSavedId = null;
  rewardSMSSavedId = null;
  smsTemplateId = null;
  rewardsmsName = null;
  smsTemplateName = null;
  channel = null;
  offerMaster;
  isBuyXGetYDetected = false;
  // loadingblock = null;
  oneTimeSaved: boolean = false;
  // responseLoading = null;
  oneTimeSavedId = null;
  dispatchRecAction = null;
  saveActionType = null;
  openScheduleOverlay = false;
  offerReqBodyObj;
  offerJson;
  offerMasterUpdate;
  importedData;
  BarrierRewardFull = null;
  noneBarrierDetected = true;
  hurdleProductInnerType = "CATEGORY";
  buyXGetYType = "COLLECTION";
  hurdleSpentAmount = null;
  atADiscountedValue = "PERCENTAGE_OFF";
  rewardpercentageOff = null;
  atBuyxGetYProducts = "";
  atBuyxGetYCollections = "";
  atdiscountAndPercentageOffProducts = "";
  atdiscountAndPercentageOffCollections = "";
  hurdleAtAnyTime = true;
  hurdleEverydayOfTheWeek = true;
  selectedRewardProductList = [];
  selectedRewardCollectionList = [];
  selectedBarrierCollectionList = [];
  selectedBarrierProductList = [];
  excludedProductList = [];
  excludedCategoryList=[];
  timeout;
  previousData = "";
  iscouponcodeCheckLoading = false;
  couponCodeVerified = false;
  couponCodeMsg = "";
  couponcodeCheckInitialised = false;
  loading = true;
  isLImitedTime = false;
  startTime = new FormControl();
  endTime = new FormControl();
  startDate = new FormControl([Validators.required]);
  endDate = new FormControl([Validators.required]);

  constructor(
    private apiCall: ApiCallService,
    public dialogRefer: MatDialogRef<OfferSummaryDialogComponent>,
    private snackbar: SnackbarCollection,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private datePipe: DatePipe,
    private tokenstorage: TokenStorageService,
    private date_format: getdateformatService
  ) {}

  offerId;
  customerId;
  ngOnInit() {
    this.getStores();
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    if (
      this.data.hasOwnProperty("offerId") &&
      this.data.hasOwnProperty("customerId")
    ) {
      this.offerId = this.data.offerId;
      this.customerId = this.data.customerId;
      this.getOfferDetails(this.offerId, this.customerId);
    } else {
      this.offerId = this.data;
      this.getOfferDetails(this.offerId);
    }
  }

  redeemableAtStores = [];
  storeData = [];
  createdBy = {};
  storeDataLoaded = false;
  offerDataLoaded = false;
  getStores() {
    this.loading = true;
    this.apiCall.getStoreData().subscribe(
      (response) => {
        this.storeData = response["body"];
        this.loading = false;
        this.storeDataLoaded = true;
        this.checkAndRunOfferStoresLogic();
      },
      (error) => {
        this.loading = false;
        this.storeData = [];
        this.snackbar.openSnackBar("Error fetching stores list", 2000);
      }
    );
  }

  getOfferDetails(offerId, customerId?) {
    this.loading = true;
    if (offerId && customerId) {
      this.apiCall
        .getParticularOfferDetailsOfCustomer(offerId, customerId)
        .subscribe(
          (response) => {
            if (response["message"] == "success") {
              this.masterOffer = JSON.parse(response["body"]);
              this.offerJson = JSON.parse(this.masterOffer["offerjson"])[
                "barrierRewards"
              ];
            }
            console.log(this.offerJson);
            this.offerMasterUpdate = this.offerJson;
            this.setConstants("updation");
            this.offerDataLoaded = true;
            this.checkAndRunOfferStoresLogic();
          },
          (err) => {
            this.loading = false;
            this.snackbar.openSnackBar(
              this._i18nDynamicTranslate.transform(
                "Error fetching offer details",
                ["SHARED_PAGE"]
              ),
              2000
            );
          }
        );
    } else {
      this.apiCall.getParticularOfferDetails(offerId).subscribe(
        (response) => {
          if (response["message"] == "success") {
            this.masterOffer = JSON.parse(response["body"]);

            this.offerJson = JSON.parse(this.masterOffer["offerjson"])[
              "barrierRewards"
            ];
            // this.selectedbarrier=this.offerJson;
          }
          console.log(this.offerJson);

          // if (response["message"] === "success") {
          //   this.offerMasterUpdate = JSON.parse(response["body"]);
          // }
          // for (let i = 0; i < this.offerJson.length; i++) {
          //   if (
          //     true
          //   ) {
          //     this.offerMasterUpdate = this.offerJson;
          //     this.importedData = true;
          //   }
          // }
          this.offerMasterUpdate = this.offerJson;
          this.setConstants("updation");
          this.offerDataLoaded = true;
          this.checkAndRunOfferStoresLogic();
        },
        (err) => {
          this.loading = false;
          this.snackbar.openSnackBar(
            this._i18nDynamicTranslate.transform(
              "Error fetching offer details",
              ["SHARED_PAGE"]
            ),
            2000
          );
        }
      );
    }
  }

  checkAndRunOfferStoresLogic() {
    // Only run the logic if both store data and offer data are loaded
    if (this.storeDataLoaded && this.offerDataLoaded) {
      this.redeemableAtStores = JSON.parse(this.masterOffer["offerjson"])[
        "redeemableAtStores"
      ];
      this.createdBy = JSON.parse(this.masterOffer["offerjson"])["createdBy"];

      if (this.createdBy["qid"] != null) {
        this.redeemableAtStores = [this.createdBy["storeName"]];
      } else {
        if (!this.redeemableAtStores || this.redeemableAtStores.length == 0) {
          this.redeemableAtStores = ["All Stores"];
        } else {
          for (let i = 0; i < this.redeemableAtStores.length; i++) {
            this.redeemableAtStores[i] = this.storeData.find(
              (store) => store.storeId == this.redeemableAtStores[i]
            )?.storeName;
          }
        }
      }
      console.log("Redeemable at Stores:", this.redeemableAtStores);
    }
  }

  setConstants(operation) {
    this.productCategoryType = [];
    this.productType = [];
    this.offerReady = false;
    this.apiCall.getConstantProducts().subscribe(
      (response) => {
        if (response["message"] === "success") {
          let result: any = response["body"];
          result = JSON.parse(result);

          // this.apiCall
          //   .getOfferManagementProductsList("", 1, 50)
          //   .subscribe((resultList) => {
          // this.apiProductList = JSON.parse(resultList["body"]);
          // this.fullproductListLength = this.apiProductList.length;
          for (let i = 0; i < result["productCategories"].length; i++) {
            this.productCategoryType.push({
              collectionName:
                result["productCategories"][i].productCategoryName,
              collectionId: result["productCategories"][i].productCategoryId,
            });
          }

          this.fullproductCategoryListLength = this.productCategoryType.length;

          if (1 === 1) {
            if (operation === "updation") {
              this.addFilledForm(this.offerMasterUpdate);
            } else {
            }
            this.readyToBuild = true;
            this.offerReady = true;
            this.showofferTemplateSkeleton = true;
          } else {
          }
        } else {
          this.offerReady = true;
          this.showofferTemplateSkeleton = false;
        }
      },
      (error) => {
        this.offerReady = true;
        this.showofferTemplateSkeleton = false;
      }
    );
  }

  addFilledForm(offerMaster) {
    let barrierList = offerMaster["barrierList"];

    let barrierSet = offerMaster["barrier"];
    let rewardSet = offerMaster["reward"];
    // (this.offerTemplate.get("offerBarrier")).removeAt(0);
    // for (let i = 0; i < offerMaster.length; i++) {
    // const control = new FormGroup({

    this.barrierParamType =
      offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["barrier"]["barrierParamType"]
        : "NON_PERSONALIZED";
    this.hurdleAtAnyTime = offerMaster[0]["barrier"]
      ? offerMaster[0]["barrier"]["hurdleAtAnyTime"]
      : true;
    this.hurdleEverydayOfTheWeek = offerMaster[0]["barrier"]
      ? offerMaster[0]["barrier"]["hurdleEverydayOfTheWeek"]
      : true;

    (this.productPropensity =
      offerMaster[0]["barrier"]["barrierParamType"] === "PERSONALIZED"
        ? offerMaster[0]["barrier"]["productPropensity"]
          ? offerMaster[0]["barrier"]["productPropensity"]
          : offerMaster[0]["barrier"]["productCategoryPropensity"]
        : "LOW"),
      (this.barrierType =
        offerMaster[0]["barrier"]["barrierType"] == "NONE"
          ? "PRODUCT_BASED"
          : offerMaster[0]["barrier"]["barrierType"]),
      //  offerMaster[0]["barrier"]["barrierType"]),
      // (this.temphurdleSegmentFilter = ""),
      // (this.temphurdleVariantSegmentFilter = ""),
      // (this.temprewardVariantSegmentFilter = ""),
      // (this.temprewardSegmentFilter = ""),
      (this.hurdleProductInnerType =
        offerMaster[0]["barrier"]["barrierParamType"] === "PERSONALIZED"
          ? offerMaster[0]["barrier"]["productPropensity"]
            ? "PRODUCT"
            : "CATEGORY"
          : offerMaster[0]["barrier"]["product"]
          ? "PRODUCT"
          : offerMaster[0]["barrier"]["productCategory"]
          ? "CATEGORY"
          : offerMaster[0]["barrier"]["anyproduct"]
          ? "ANY_PRODUCT"
          : "CATEGORY");
    // (this.hurdleProduct =
    //   offerMaster[0]["barrier"]["product"] != null
    //     ? this.getMainProductJson(
    //         offerMaster[0]["barrier"]["product"]["productId"]
    //       )
    //     : null),
    // (this.hurdleProductVariant =
    //   offerMaster[0]["barrier"]["productVariant"] != null &&
    //   offerMaster[0]["barrier"]["product"] != null
    //     ? this.getvariantProductJson(
    //         offerMaster[0]["barrier"]["productVariant"]["variantId"],
    //         this.getMainProductJson(
    //           offerMaster[0]["barrier"]["product"]["productId"]
    //         )
    //       )
    //     : null),

    this.hurdleProductCategory = offerMaster[0]["barrier"]["productCategory"]
      ? offerMaster[0]["barrier"]["productCategory"]["productCategoryName"]
      : null;
    this.hurdleProductCount = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["prerequisiteQuantity"]
        : 1
      : 1;
    this.hurdleSpentInnerType =
      offerMaster[0]["barrier"]["barrierParamType"] === "NON_PERSONALIZED"
        ? offerMaster[0]["barrier"]["barrierType"] === "SPENT_BASED"
          ? offerMaster[0]["barrier"]["spentType"]
          : "AMOUNT"
        : "AMOUNT";

    (this.hurdleSpentAmount =
      offerMaster[0]["barrier"]["barrierParamType"] === "NON_PERSONALIZED"
        ? offerMaster[0]["barrier"]["barrierType"] === "SPENT_BASED"
          ? offerMaster[0]["barrier"]["spentBarrier"]
          : null
        : null),
      (this.hurdleRewardToggle = offerMaster[0]["reward"] ? true : false);
    // rewardPersonalizationType: offerMaster[0]["reward"]
    //   ? offerMaster[0]["barrier"]["productPropensity"] !== null ||
    //     offerMaster[0]["barrier"]["productCategoryPropensity"] !== null
    //     ? "PERSONALIZED")
    //     : "NON_PERSONALIZED")
    //   : "NON_PERSONALIZED"),
    this.rewardPersonalizationType = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["productPropensity"] !== null ||
        offerMaster[0]["reward"]["productCategoryPropensity"] !== null
        ? "PERSONALIZED"
        : "NON_PERSONALIZED"
      : "NON_PERSONALIZED";

    this.rewardPropensity = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["productPropensity"] !== null
        ? offerMaster[0]["reward"]["productPropensity"]
        : offerMaster[0]["reward"]["productCategoryPropensity"] !== null
        ? offerMaster[0]["reward"]["productCategoryPropensity"]
        : "LOW"
      : "LOW";

    this.offerDiscountCode = offerMaster[0]["reward"]["title"]
      ? offerMaster[0]["reward"]["title"]
      : "";

    this.islimitToOneUsePerCustomer =
      offerMaster[0]["reward"]["isOncePerCustomer"];

    this.islimitNoOfTry =
      offerMaster[0]["reward"]["usageLimit"] != null ? true : false;

    this.noOfTry =
      offerMaster[0]["reward"]["usageLimit"] != null
        ? offerMaster[0]["reward"]["usageLimit"]
        : 0;

    this.hurdleRewardType = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"]
      : "BUY_X_GET_Y";
    this.buyXGetYType = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"]
        : "PRODUCT"
      : "PRODUCT";
    this.discountAndShippingType = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF" ||
        offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF"
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "entitledType"
          ]
        : "PRODUCT"
      : "PRODUCT";
    this.percentageOff = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF"
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "discountValue"
          ]
        : null
      : null;
    this.amountOff = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF"
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "discountValue"
          ]
        : null
      : null;

    // this.hurdleRewardProduct = offerMaster[0]["reward"]
    //   ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
    //     ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"] ===
    //       "PRODUCT"
    //       ? this.getMainProductJson(
    //           offerMaster[0]["reward"]["buyXGetYDiscount"][
    //             "entitledProducts"
    //           ][0]["productId"]
    //         )
    //       : null
    //     : offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF" &&
    //       !offerMaster[0]["reward"]["productPropensity"]
    //     ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //         "entitledType"
    //       ] === "PRODUCT"
    //       ? this.getMainProductJson(
    //           offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //             "entitledProducts"
    //           ][0]["productId"]
    //         )
    //       : null
    //     : offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF" &&
    //       !offerMaster[0]["reward"]["productPropensity"]
    //     ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //         "entitledType"
    //       ] === "PRODUCT"
    //       ? this.getMainProductJson(
    //           offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //             "entitledProducts"
    //           ][0]["productId"]
    //         )
    //       : null
    //     : null
    //   : null;

    let amountOrPercentageOffDiscountMinAmount = offerMaster[0]["reward"][
      "amountOrPercentageOffDiscount"
    ]
      ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
          "minPurchaseAmount"
        ]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "minPurchaseAmount"
          ]
        : null
      : null;
    let amountOrPercentageOffDiscountMinOrder = offerMaster[0]["reward"][
      "amountOrPercentageOffDiscount"
    ]
      ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
          "minPurchaseQuantity"
        ]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "minPurchaseQuantity"
          ]
        : null
      : null;
    let freeshippingMinOrder = offerMaster[0]["reward"]["freeShippingDiscount"]
      ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderQuantity"]
        ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderQuantity"]
        : null
      : null;
    let freeshippingMinAmount = offerMaster[0]["reward"]["freeShippingDiscount"]
      ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderAmount"]
        ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderAmount"]
        : null
      : null;

    this.isLImitedTime = this.masterOffer["isLimitedTime"];
    if (this.isLImitedTime) {
      var startDateValue = this.toMerchantTimeZome(
        this.masterOffer["offerStartTime"]
      );
      var endDateValue = this.toMerchantTimeZome(
        this.masterOffer["offerEndTime"]
      );
      this.startTime.setValue(this.getInTimeFormat(startDateValue));
      this.endTime.setValue(this.getInTimeFormat(endDateValue));
      console.log(this.startTime, this.endTime);
      this.startDate.setValue(startDateValue);
      this.endDate.setValue(endDateValue);
    }
    this.rewardMinReqsAmount =
      amountOrPercentageOffDiscountMinAmount || freeshippingMinAmount;
    this.rewardMinReqsQuantity =
      amountOrPercentageOffDiscountMinOrder || freeshippingMinOrder;
    this.rewardMinReqs =
      amountOrPercentageOffDiscountMinAmount || freeshippingMinAmount
        ? "MIN_ORDER_AMOUNT"
        : amountOrPercentageOffDiscountMinOrder || freeshippingMinOrder
        ? "MIN_ORDER_QUANTITY"
        : "NONE";

    let buyxgetyBarrierProdList = offerMaster[0]["reward"]["buyXGetYDiscount"]
      ? offerMaster[0]["reward"]["buyXGetYDiscount"]["prerequisiteVariants"]
      : [];
    let buyxgetyBarrierCollList = offerMaster[0]["reward"]["buyXGetYDiscount"]
      ? offerMaster[0]["reward"]["buyXGetYDiscount"]["prerequisiteCollection"]
      : [];
    let buyxgetyRewardProdList = offerMaster[0]["reward"]["buyXGetYDiscount"]
      ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledVariants"]
      : [];
    let buyxgetyRewardCollList = offerMaster[0]["reward"]["buyXGetYDiscount"]
      ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledCollections"]
      : [];
    let percentageoramountoffProdList = offerMaster[0]["reward"][
      "amountOrPercentageOffDiscount"
    ]
      ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
          "entitledVariants"
        ]
      : [];
    let percentageoramountoffCollList = offerMaster[0]["reward"][
      "amountOrPercentageOffDiscount"
    ]
      ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
          "entitledCollection"
        ]
      : [];

    let selrewardProdlist = offerMaster[0]["reward"][
      "amountOrPercentageOffDiscount"
    ]
      ? percentageoramountoffProdList
      : buyxgetyRewardProdList || [];
    // percentageoramountoffProdList || buyxgetyRewardProdList;
    let selrewardCollList = offerMaster[0]["reward"][
      "amountOrPercentageOffDiscount"
    ]
      ? percentageoramountoffCollList
      : buyxgetyRewardCollList || [];

    this.selectedBarrierProductList = buyxgetyBarrierProdList || [];
    this.selectedBarrierCollectionList = buyxgetyBarrierCollList || [];
    this.selectedRewardProductList = selrewardProdlist || [];
    this.selectedRewardCollectionList = selrewardCollList || [];
    this.excludedProductList = offerMaster[0]["reward"]["excludedProducts"] || [];
    this.excludedCategoryList = offerMaster[0]["reward"]["excludedCategories"] || [];

    // console.log(this.selectedRewardProductList);

    // this.selectedRewardProductList  = offerMaster[0]["reward"]
    //       ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
    //         ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"] ===
    //           "PRODUCT"
    //           ?
    //               offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledVariants"]
    //                 ? offerMaster[0]["reward"]["buyXGetYDiscount"][
    //                     "entitledVariants"
    //                   ]
    //                 : []
    //           : []
    //         : offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF" &&
    //           !offerMaster[0]["reward"]["productPropensity"]
    //         ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //             "entitledType"
    //           ] === "PRODUCT"
    //           ?
    //               offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //                 "entitledVariants"
    //               ]
    //                 ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //                     "entitledVariants"
    //                   ]
    //                 : []

    //           : []
    //         : offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF" &&
    //           !offerMaster[0]["reward"]["productPropensity"]
    //         ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //             "entitledType"
    //           ] === "PRODUCT"
    //           ?
    //               offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //                 "entitledVariants"
    //               ]
    //                 ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //                     "entitledVariants"
    //                   ]
    //                 : []
    //           : []
    //         : []
    //       : [];
    // this.selectedBarrierCollectionList = offerMaster[0]["reward"]?offerMaster[0]["reward"]["buyXGetYDiscount"]?offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledCollections"]?offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledCollections"]:[]:[]:[];
    // this.selectedRewardCollectionList = offerMaster[0]["reward"]
    // ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
    //   ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"] ===
    //     "COLLECTION"
    //     ?
    //         offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledCollection"]
    //           ? offerMaster[0]["reward"]["buyXGetYDiscount"][
    //               "entitledVariants"
    //             ]
    //           : []
    //     : []
    //   : offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF" &&
    //     !offerMaster[0]["reward"]["productPropensity"]
    //   ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //       "entitledType"
    //     ] === "PRODUCT"
    //     ?
    //         offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //           "entitledVariants"
    //         ]
    //           ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //               "entitledVariants"
    //             ]
    //           : []

    //     : []
    //   : offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF" &&
    //     !offerMaster[0]["reward"]["productPropensity"]
    //   ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //       "entitledType"
    //     ] === "PRODUCT"
    //     ?
    //         offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //           "entitledVariants"
    //         ]
    //           ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //               "entitledVariants"
    //             ]
    //           : []
    //     : []
    //   : []
    // : [];

    this.hurdleRewardProductCount = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledQuantity"]
        : 1
      : 1;
    // this.percentageOff=offerMaster[0]["reward"]? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"?offerMaster[0]["reward"]["buyXGetYDiscount"][
    //   "entitledItemDiscountPercent"] :null:null;

    this.atADiscountedValue = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"][
            "entitledItemDiscountPercent"
          ] == 100
          ? "FREE"
          : "PERCENTAGE_OFF"
        : "PERCENTAGE_OFF"
      : "PERCENTAGE_OFF";
    this.rewardpercentageOff = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"][
            "entitledItemDiscountPercent"
          ]
        : null
      : null;
    (this.hurdleRewardProductCategory = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"] ===
          "COLLECTION"
          ? offerMaster[0]["reward"]["buyXGetYDiscount"][
              "entitledCollection"
            ][0]["collectionName"]
          : null
        : offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF" &&
          !offerMaster[0]["reward"]["productCategoryPropensity"]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "entitledType"
          ] === "COLLECTION"
          ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
              "entitledCollection"
            ][0]["collectionName"]
          : null
        : offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF" &&
          !offerMaster[0]["reward"]["productCategoryPropensity"]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "entitledType"
          ] === "COLLECTION"
          ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
              "entitledCollection"
            ][0]["collectionName"]
          : null
        : null
      : null),
      (this.freeShippingType = offerMaster[0]["reward"]
        ? offerMaster[0]["reward"]["rewardType"] === "FREE_SHIPPING"
          ? offerMaster[0]["reward"]["freeShippingDiscount"]["entitledType"]
          : "ORDER"
        : "ORDER");
    this.freeShippingMinimumAmount = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "FREE_SHIPPING"
        ? offerMaster[0]["reward"]["freeShippingDiscount"]["entitledType"] ===
          "ORDER"
          ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderAmount"]
          : ""
        : ""
      : "";
    this.freeShippingMinimumOrderCount = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "FREE_SHIPPING"
        ? offerMaster[0]["reward"]["freeShippingDiscount"]["entitledType"] ===
          "MIN_ORDER_QUANTITY"
          ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderQuantity"]
          : ""
        : ""
      : "";
    this.hurdleTimeToComplete =
      offerMaster[0]["barrier"]["barrierDates"]["endDayCount"];
    this.rewardTimeToComplete = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["daysToRedeem"]
      : null;

    this.rewardTemplate = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] == "BUY_X_GET_Y"
        ? "BUY_X_GET_Y_REWARD_EMAIL"
        : offerMaster[0]["reward"]["rewardType"] == "PERCENTAGE_OFF"
        ? "PERCENTAGE_OFF_REWARD_EMAIL"
        : offerMaster[0]["reward"]["rewardType"] == "FIXED_AMOUNT_OFF"
        ? "FIXED_AMOUNT_OFF_REWARD_EMAIL"
        : offerMaster[0]["reward"]["rewardType"] == "FREE_SHIPPING"
        ? "FREE_SHIPPING_REWARD_EMAIL"
        : null
      : null;
    this.rewardSavedId = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["emailLayoutId"]
      : null;
    this.channel = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["channel"]
      : null;
    this.rewardsmsName = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardLayoutName"]
      : null;
    this.rewardSMSSavedId = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["layoutId"]
      : null;
    // this.hurdleCompletionDay =
    //   offerMaster[0]["barrier"]["barrierDates"]["daysOfTheWeek"] == null
    //     ? []
    //     : offerMaster[0]["barrier"]["barrierDates"]["daysOfTheWeek"];

    // for (let i = 0; i < this.hurdleCompletionDay.length; i++) {
    //   for (var j = 0; j <= this.weekDaysChips.length; j++) {
    //     if (this.hurdleCompletionDay[i] == this.weekDaysChips[j]["full"]) {
    //       this.weekDaysChips[j]["selected"] = true;
    //       break;
    //     }
    //   }
    // }

    // this.hourFrom =
    //   offerMaster[0]["barrier"]["hurdleAtAnyTime"] == true
    //     ? "00:00"
    //     : offerMaster[0]["barrier"]["barrierDates"]["startTime"];

    // this.hourTo =
    //   offerMaster[0]["barrier"]["hurdleAtAnyTime"] == true
    //     ? "23:59"
    //     : offerMaster[0]["barrier"]["barrierDates"]["endTime"];

    // });
    //(this.offerTemplate.get("offerBarrier")).push(control);

    // }

    this.readyToBuild = true;
    this.loading = false;
  }
  getMainProductJson(id) {
    // console.log(id, this.apiProductList.length);
    if (id == null) {
      return "";
    }
    for (var i = 0; i < this.apiProductList.length; i++) {
      if (this.apiProductList[i]["productId"] == id) {
        return this.apiProductList[i];
      }
    }
    return "";
  }

  getvariantProductJson(id, parent) {
    // console.log(parent);
    if (id == null) {
      return "";
    }
    for (var i = 0; i < parent.variants.length; i++) {
      if (parent.variants[i]["variantId"] == id) {
        return parent.variants[i];
      }
    }
    return "";
  }

  closedialog() {
    this.dialogRefer.close();
  }

  toMerchantTimeZome(utcDate) {
    // const date = new Date(utcDate);
    // const nowUTC = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    // var merchantTime = new Intl.DateTimeFormat(
    //   "en-US", {
    //     timeZone: this.tokenstorage.getMerchantTimeZone(),
    //     year: 'numeric',
    //     month: '2-digit',
    //     day: '2-digit',
    //     hour: '2-digit',
    //     minute: '2-digit',
    //     second: '2-digit',
    //     hour12: false,
    //   }
    // ).format(nowUTC);
    return this.datePipe.transform(utcDate, "yyyy-MM-dd HH:mm:ss");
  }

  getInTimeFormat(time) {
    let dateTime: any = new Date(time);

    // Get the time in hh:mm format
    var hours = dateTime.getHours();
    var minutes = dateTime.getMinutes();

    // Convert single digit hours and minutes to double digits
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    let timeOp: any = hours + ":" + minutes;
    return timeOp;
  }

  getDateFormat(type?) {
    // this.date_format.getDateFormat();
    return this.date_format.getDateFormat(type);
  }

  transformTo24HourFormat(time12hr: string): string {
    if (sessionStorage.getItem("serviceCountry") === "US") {
      // Use the DatePipe to format the time in 12-hour format
      let formattedTime = time12hr;

      // Parse the formatted time to get the hour and minute
      let parts = formattedTime.split(":");
      let hour = parseInt(parts[0], 10);
      let minute = parseInt(parts[1], 10);

      // Check if it's PM, and if it is, add 12 to the hour
      if (time12hr.toLowerCase().includes("pm") && hour < 12) {
        hour += 12;
      } else if (time12hr.toLowerCase().includes("am") && hour == 12) {
        hour = 0;
      }

      // Format the hour and minute as two-digit strings
      let hourStr = hour.toString().padStart(2, "0");
      let minuteStr = minute.toString().padStart(2, "0");

      // Return the time in 24-hour format
      return `${hourStr}:${minuteStr}`;
    } else {
      return time12hr;
    }
  }
}
