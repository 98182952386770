<div style="display: flex;flex-direction: column;height: 100%;">

    <div *ngIf="!dataBean" style="    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0px 15px;
    height: 40px;">
        <div class="tree-title">{{'SHARED_PAGE.ADD_FROM_SPECIFIC_PRODUCT' | i18nTranslate}}</div>
        <mat-icon style="    color: #efefef;
            background: #a9a9a9;
            border-radius: 50%;
            height: 18px;
            width: 18px;
            display: flex;
            justify-content: center;
            align-items: center;cursor: pointer;" (click)="cancel()" data-button-name="Close">cancel</mat-icon>
    </div>

    <div class="formDiv-search" style="border-radius: 4px;
    background: #F8F8F8;
    margin: 0px 15px;
    height: 40px;">
        <input class="formInput" autocomplete="off" matInput [(ngModel)]="search"
            placeholder="Search by name (Minimum 3 characters)" (ngModelChange)="getInputField($event);">
        <span class="material-icons-outlined icons-primary cursor-pointer" *ngIf="search.length==0">
            search
        </span>
        <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="search.length>0" (click)="search='';getInputField(search);">
            close
          </span>
    </div>
    <small style="color: red; font-size:  12px; padding: 0px 15px;" *ngIf="search.length < 3 && search.length > 0 && thresholdCrossed">Minimum 3 characters requied for search</small>
    

    <!-- <div style="margin-bottom: 13px;margin-left: 13px;" *ngIf="search.length<3 && !singleSelectMode">
        <mat-checkbox
          class="example-margin"
          [checked]="parentProductIdAll.length === apiProductList.length && apiProductList.length != 0"
          (change)="selectAllCategoryAndProduct($event.checked)"
          color="primary">
        <div style="display: flex;">
          <div>Select All</div>
        </div>
        </mat-checkbox> 
    </div> -->

    <div style="flex-grow: 1;
    overflow: auto;
    height: calc(100% - 100px);
    padding: 0px 15px;
    margin-bottom: 10px;">
        <div *ngFor="let product of apiProductList;let i= index" style="margin-bottom: 15px;">

            <div style="display: flex;">
                <div *ngIf="databean.actionType === null">
                    <span class="material-icons-outlined parent-arrow"
                        (click)="parentArrowClicked(i); getDataForTable($event)">
                        chevron_right
                    </span>
                </div>

                <!-- [indeterminate]="someComplete(product)"  -->
                <section class="example-section">
                    <span class="parent-product-ln">
                        <mat-checkbox class="example-margin"
                            #myCheckbox
                            [checked]="ifProductChecked(product)"
                            [indeterminate]="ifProductPartialyChecked(product)"
                            (change)="setAllChecked($event.checked,product.variants, product)" color="primary"
                            [disabled]="singleSelectMode && productSelected && !myCheckbox.checked ">
                            {{product.productName}}
                        </mat-checkbox>
                    </span>
                    <span class="variant-product-ln active" [id]="'variant-section-'+i">
                        <div>
                            <div *ngFor="let variant of product['variants']">
                                <mat-checkbox color="primary" [checked]="varaintProductIdArray.indexOf(variant.gfId)!=-1"
                                    (change)="updateSelection(variant.gfId,product.gfId,$event.checked,variant)"
                                    [disabled]="singleSelectMode && productSelected &&  varaintProductIdArray.indexOf(variant.gfId)==-1">
                                    {{variant.variantName}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </span>
                </section>

            </div>

        </div>
        <div *ngIf="apiProductList.length<1&&!loading">

            <div
                style="display: flex;justify-content: center;align-items: center;color: rgba(34, 34, 34, 0.6);font-style: italic;">
                <span style="font-size: 14px;">{{'SHARED_PAGE.NO_PRODUCT_FOUND' | i18nTranslate}}</span>
            </div>
        </div>

        <div *ngIf="(apiProductList.length==totalProductCount)&&!loading&&apiProductList.length!=0"
            style="display: flex;justify-content: center;margin: 5px 0px;width: 100%;color: rgba(34, 34, 34, 0.6);font-style: italic;">
            <span>{{'SHARED_PAGE.END_OF_RESULT' | i18nTranslate}}</span>
        </div>
        <div *ngIf="loading" style="display:flex;height: 100%;width: 100%;justify-content: center;align-items: center;">
            <app-qubriux-loading [size]="25" [loadingText]="'Fetching Products...'"></app-qubriux-loading>
        </div>

    </div>



    <div style="    height: 60px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0px 15px;
    box-shadow: 2px -2px 20px rgb(0 0 0 / 8%);">
    <div style="display: flex;justify-content: left;gap: 13px;">
        <button class="primary-button button-hover-effect" *ngIf="search.length<3 && !singleSelectMode" (click)="selectAllCategoryAndProduct(true)">Select All</button>
        <button class="primary-button-border button-hover-effect" *ngIf="search.length<3 && !singleSelectMode" (click)="selectAllCategoryAndProduct(false)">Clear All</button>
    </div>
    <div style="display: flex;justify-content: left;gap: 13px;">
        <div class="cancel-btn" (click)="cancel()">{{'SHARED_PAGE.CANCEL' | i18nTranslate}}</div>
        <div class="primary-button" (click)="save()">{{'SHARED_PAGE.SAVE' | i18nTranslate}}</div>
    </div>

</div>