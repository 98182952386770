<div *ngIf="loading || isStoresLoading" class="loader-container">
    <app-qubriux-loading [size]="65" [loadingText]="loadingText"></app-qubriux-loading>
</div>
<div *ngIf="!loading && !isStoresLoading" class="page-container">
    
    <!-- <div class="top-bar">
        <app-breadcrumb></app-breadcrumb>
        
    </div> -->

    <div class="main-white-panel top-bar">
        <div class="segment-name">
            <div class="segment-name-inp-container  bigger-inp pos-relative">
                <label class="main-label-style"> Segment Name </label>
                <input type="text" (keydown)="stringInputHandler($event, 50)"  [(ngModel)]="segmentName" maxlength="50" #inp2
                class="input-style" [ngClass]="{'cs-error-border':isSegmentNameInvalid}" placeholder="eg. Highspender customer segment">
                <div class="remaining-text-left-2">{{inp2.value.length}}/{{inp2.getAttribute('maxlength')}}</div>
            </div>
            <div *ngIf="isSegmentNameInvalid" class="segment-name-error">{{this.segmentErrortext}}</div>
        </div>
        <div class="buttons-container">
            <button  class="primary-button margin-auto" [disabled]="isLogicalExpressionsInvalid(true)"
                appButtonDebounce
                [throttleTime]="2000"
                (throttledClick)="loadPreviewPopup()"
                [ngClass]="{
                    'button-hover-effect': !(isLogicalExpressionsInvalid(true)),
                    'loading-style': previewLoading
                  }">
                <span *ngIf="!previewLoading" class="material-icons-outlined qb-button-icon">visibility</span>
                <span *ngIf="!previewLoading">{{'SEGMENT_PAGE.PREVIEW' | i18nTranslate}}</span>
                <mat-spinner diameter="15" *ngIf="previewLoading" color="primary"></mat-spinner>
             </button>
            <button  class="primary-button margin-auto button-hover-effect" (click)="validateAndOpenSavePopup()">
                <span class="material-icons-outlined qb-button-icon">
                    save
                </span>
                {{'SEGMENT_PAGE.SAVE' | i18nTranslate}}
            </button>
        </div>
    </div>

    <div class="panel-height-sp">
        <div class="main-container" #mainContainer>
            <div *ngFor="let logicalExpression of logicalExpressions;let logExpIndex = index" class="logical-expression pos-relative" [class.first-logical-expression]="logExpIndex === 0">
                <div class="le-top">
                    <div class="display-flex">
                        <div class="box-preview" *ngIf="logicalExpressions.length>1">
                            <div class="bold-text">Group: {{logExpIndex+1}}</div>
                        </div>
                        <div class="display-flex" *ngIf="logicalExpressions.length>1">
                            <div class="cs-top-icons" title="{{'COMMON_PAGE.DELETE_GROUP' | i18nTranslate}}" (click)="deleteLogicalExpression(logExpIndex)">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.53125 1.5C5.4375 1.5 5.375 1.5625 5.3125 1.625L4.71875 2.5H9.25L8.65625 1.625C8.625 1.5625 8.53125 1.5 8.4375 1.5H5.53125ZM11.0625 2.5H11.4688H13H13.25C13.6562 2.5 14 2.84375 14 3.25C14 3.6875 13.6562 4 13.25 4H12.875L12.125 14.1562C12.0312 15.2188 11.1875 16 10.125 16H3.84375C2.78125 16 1.9375 15.2188 1.84375 14.1562L1.09375 4H0.75C0.3125 4 0 3.6875 0 3.25C0 2.84375 0.3125 2.5 0.75 2.5H1H2.5H2.90625L4.0625 0.78125C4.375 0.3125 4.9375 0 5.53125 0H8.4375C9.03125 0 9.59375 0.3125 9.90625 0.78125L11.0625 2.5ZM11.375 4H2.59375L3.34375 14.0625C3.375 14.3125 3.59375 14.5 3.84375 14.5H10.125C10.375 14.5 10.5938 14.3125 10.625 14.0625L11.375 4Z" fill="#ff4500"/>
                                </svg> 
                            </div>
                        </div>
                    </div>
                    <div class="lo-and-or-selector" *ngIf="logicalExpression.comparisonStatements.length > 1">
                        <div class="each-selector selector-left right-border-radius-none" (click)="selectCsCondition('AND',logicalExpression)"
                            [ngClass]="{'lo-active': logicalExpression.comparisonStatements[1].logicalOperator === 'AND' }">
                            {{'SEGMENT_PAGE.AND' | i18nTranslate}}
                        </div>
                        <div class="each-selector selector-right left-border-radius-none" (click)="selectCsCondition('OR',logicalExpression)"
                            [ngClass]="{'lo-active': logicalExpression.comparisonStatements[1].logicalOperator === 'OR' }">
                            {{'SEGMENT_PAGE.OR' | i18nTranslate}}
                        </div>
                    </div>
                </div>
                
                <div *ngFor="let comparisonStatement of logicalExpression.comparisonStatements;let compIndex = index" class="comparison-statement pos-relative" [id]="'comparison-statement-'+logExpIndex+'-'+compIndex">
                    
                    <div  *ngIf="logicalExpression.comparisonStatements.length>1" class="cs-create-delete" title="{{'COMMON_PAGE.DELETE_CONDITION' | i18nTranslate}}" (click)="deleteComparisonStatement(compIndex,logicalExpression)">
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.53125 1.5C5.4375 1.5 5.375 1.5625 5.3125 1.625L4.71875 2.5H9.25L8.65625 1.625C8.625 1.5625 8.53125 1.5 8.4375 1.5H5.53125ZM11.0625 2.5H11.4688H13H13.25C13.6562 2.5 14 2.84375 14 3.25C14 3.6875 13.6562 4 13.25 4H12.875L12.125 14.1562C12.0312 15.2188 11.1875 16 10.125 16H3.84375C2.78125 16 1.9375 15.2188 1.84375 14.1562L1.09375 4H0.75C0.3125 4 0 3.6875 0 3.25C0 2.84375 0.3125 2.5 0.75 2.5H1H2.5H2.90625L4.0625 0.78125C4.375 0.3125 4.9375 0 5.53125 0H8.4375C9.03125 0 9.59375 0.3125 9.90625 0.78125L11.0625 2.5ZM11.375 4H2.59375L3.34375 14.0625C3.375 14.3125 3.59375 14.5 3.84375 14.5H10.125C10.375 14.5 10.5938 14.3125 10.625 14.0625L11.375 4Z" fill="#ff4500"/>
                        </svg>    
                    </div>
    
                    <div class="width-40">
                        <div class="main-label-style">{{'SEGMENT_PAGE.PARAMETER' | i18nTranslate}}</div>
                        <div class="attribute-value" (click)="toggleDropdown($event,logExpIndex,compIndex,'parameter')">
                            <div class="attribute-value-text" [title]="comparisonStatement.operand1.displayName">
                                {{comparisonStatement.operand1.displayName}}
                            </div>
                            <mat-icon class="down-arrow" [id]="'down-arrow-parameter'+logExpIndex+compIndex">keyboard_arrow_down</mat-icon>
                            <div class="dropdown parameter-dropdown" [id]="'parameter-dropdown'+logExpIndex+compIndex">
                                <div class="category-container">
                                    <div *ngFor="let category of segementVariableCategoryList" class="category" title="{{category}}"
                                        (click)="setAttribute('CATEGORY',category,comparisonStatement)"
                                        [ngClass]="{'active-category': comparisonStatement.category === category}"   >
                                        {{category}}
                                    </div>
                                </div>
                                <div class="parameters-container">
                                    <div *ngFor="let parameter of segmentVariables[comparisonStatement.category]" class="dropdown-opt" title="{{parameter.displayName}}"
                                            (click)="setAttribute('PARAMETER',parameter,comparisonStatement)"
                                            [ngClass]="{'active-opt': comparisonStatement.operand1.displayName === parameter.displayName}">
                                            <!--setAttribute('PARAMETER',parameter,comparisonStatement) also sets the value type on input field -->
                                        {{parameter.displayName}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="width-28">
                        <div class="main-label-style">{{'SEGMENT_PAGE.CONDITIONS' | i18nTranslate}}</div>
                        <div class="attribute-value" (click)="toggleDropdown($event,logExpIndex,compIndex,'condition')">
                            <div class="attribute-value-text" [title]="comparisonStatement.operator.operator">
                                {{comparisonStatement.operator.operator}}
                            </div>
                            <mat-icon class="down-arrow" [id]="'down-arrow-condition'+logExpIndex+compIndex">keyboard_arrow_down</mat-icon>
                            <div class="dropdown" [id]="'condition-dropdown'+logExpIndex+compIndex">
                                <div *ngFor="let operator of comparisonStatement.operand1.operators" class="dropdown-opt"
                                    (click)="setAttribute('OPERATOR',operator,comparisonStatement)"
                                    [ngClass]="{'active-opt': comparisonStatement.operator === operator}">
                                    {{operator.operator}}
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="width-30" *ngIf="comparisonStatement.operator">
                        <div class="main-label-style flex-with-space-between" [ngClass]="{'disabled-color':comparisonStatement.operand2.type.toLowerCase() == 'boolean'}"><div>{{'SEGMENT_PAGE.VALUE' | i18nTranslate}}</div> <div *ngIf="comparisonStatement.operand1.dataType.toLowerCase() == 'date'" class="date-note-text">Note: Date format here is dd/mm/yyyy</div></div>
                        <div *ngIf="comparisonStatement.operand2.type.toLowerCase() == 'boolean'">
                            <input class="value-input-segmentation disabled-style" [value]="'Not required'" readonly disabled/>
                        </div>
                        <div *ngIf="comparisonStatement.operand1.dataType.toLowerCase() == 'date'" class="pos-relative">
                            <input
                                [(ngModel)] = "comparisonStatement.operand2.value"
                                class="value-input-segmentation"
                                matInput
                                [matDatepicker]="picker1"
                                placeholder="Choose a date"
                                readonly
                                (click)="picker1.open()"
                                [ngClass]="{'cs-error-border':comparisonStatement.hasError}"
                                (dateInput)="dateChanged($event.value,comparisonStatement)"
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker1" class="cal-icon-pos">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <div *ngIf="comparisonStatement.hasFutureDate" class="future-date-warn">
                                <span class="material-symbols-outlined warn-size">warning</span>
                                <span>You have selected future date </span>
                                <span class="qb-i-div"
                                    placement="auto"
                                    [ngbPopover]="popRuleSetup"
                                    triggers="mouseenter:mouseleave"
                                    >i
                                </span>
                                <ng-template #popRuleSetup>
                                    <div class="qb-ng-template-text">
                                        The date you have selected is in the future, this condition will not be true for any customers in the present
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div *ngIf="comparisonStatement.operand2.type == 'string'">
                            <input type="text" (keydown)="stringInputHandler($event, 50, comparisonStatement)" #inp1 maxlength="50" 
                            class="value-input-segmentation" [(ngModel)]="comparisonStatement.operand2.value" [ngClass]="{'cs-error-border':comparisonStatement.hasError}">
                            <div class="remaining-text-left">{{inp1.getAttribute('maxlength') - inp1.value.length}} {{'SEGMENT_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
                        </div>
                        <div *ngIf="comparisonStatement.operand2.type == 'email'">
                            <input type="email" (keydown)="stringInputHandler($event,50,comparisonStatement)"
                            class="value-input-segmentation" [(ngModel)]="comparisonStatement.operand2.value" #inp maxlength="50" [ngClass]="{'cs-error-border':comparisonStatement.hasError}">
                            <div class="remaining-text-left">{{inp.getAttribute('maxlength') - inp.value.length}} {{'SEGMENT_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
                        </div>
                        <div *ngIf="comparisonStatement.operand2.type == 'number'">
                            <input type="number" (keydown)="numberInputHandler($event, 15, comparisonStatement)" class="value-input-segmentation" #inp maxlength="15"
                            [(ngModel)]="comparisonStatement.operand2.value" [ngClass]="{'cs-error-border':comparisonStatement.hasError}" min="0" max="999999999999999">
                            <div class="remaining-text-left">{{inp.getAttribute('maxlength') - inp.value.length}} {{'SEGMENT_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
                        </div>
                        <div *ngIf="comparisonStatement.operand2.type == 'product'">
                            <div class="attribute-value" (click)="!isProductsLoading && !isModifiersLoading && toggleDropdown($event,logExpIndex,compIndex,'value',comparisonStatement['operand2Category'])">
                                <div *ngIf="!isProductsLoading" class="attribute-value-text" [title]="comparisonStatement.operand2.value">
                                    {{comparisonStatement.operand2.value}}
                                </div>
                                <div *ngIf="isProductsLoading || isModifiersLoading" class="margin-auto">
                                    <app-qubriux-loading [size]="25"></app-qubriux-loading>
                                </div>
                                <mat-icon class="down-arrow" [id]="'down-arrow-value'+logExpIndex+compIndex">keyboard_arrow_down</mat-icon>
                                <div class="dropdown parameter-dropdown" [id]="'value-dropdown'+logExpIndex+compIndex">
                                    <div class="category-container">
                                        <div *ngFor="let category of productCategoryMap | keyvalue" class="category" title="{{getDisplayName(category.key)}}"
                                            (click)="setAttribute('OPERAND2CATEGORY',category.key,comparisonStatement)"
                                            [ngClass]="{'active-category': comparisonStatement.operand2Category === category.key}"   >
                                            {{getDisplayName(category.key)}}
                                        </div>
                                    </div>
                                    <div class="parameters-container">
                                        <input class="op-search" placeholder="Search" type="text" (keydown)="stringInputHandler($event,50,comparisonStatement)" [(ngModel)]="op2ListSearch"/>
                                        <div class="search-filtered-dropdown">
                                            <div *ngFor="let productName of viewList | filterPipe : op2ListSearch" class="dropdown-opt"
                                                (click)="setAttribute('VALUE',productName,comparisonStatement)"
                                                [ngClass]="{'active-opt': comparisonStatement.operand2.value === productName}">
                                                <span [matTooltip]="productName">{{productName}}</span>
                                            </div>
                                            <div class="no-product" *ngIf="(viewList | filterPipe : op2ListSearch).length==0" (click)="$event.stopPropagation()">
                                                {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="comparisonStatement.operand2.type == 'tag'">
                            <div class="attribute-value" (click)="toggleDropdown($event,logExpIndex,compIndex,'tag',comparisonStatement['operand2Category'])">
                                <div class="attribute-value-text" [title]="comparisonStatement.operand2.value">
                                    {{comparisonStatement.operand2.value}}
                                </div>
                                <mat-icon class="down-arrow" [id]="'down-arrow-tag'+logExpIndex+compIndex">keyboard_arrow_down</mat-icon>
                                <div class="dropdown parameter-dropdown" [id]="'tag-dropdown'+logExpIndex+compIndex">
                                    <div class="parameters-container">
                                        <input class="op-search" placeholder="Search" type="text" (keydown)="stringInputHandler($event,50,comparisonStatement)" [(ngModel)]="op2ListSearch"/>
                                        <div class="search-filtered-dropdown">
                                            <div *ngFor="let productName of viewList | filterPipe : op2ListSearch" class="dropdown-opt"
                                                (click)="setAttribute('VALUE',productName,comparisonStatement)"
                                                [ngClass]="{'active-opt': comparisonStatement.operand2.value === productName}">
                                                <span [matTooltip]="productName">{{productName}}</span>
                                            </div>
                                            <div class="no-product" *ngIf="(viewList | filterPipe : op2ListSearch).length==0" (click)="$event.stopPropagation()">
                                                {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="comparisonStatement.operand2.type == 'emailsender'">
                            <div class="attribute-value" (click)="toggleDropdown($event,logExpIndex,compIndex,'value',comparisonStatement['operand2Category'])">
                                <div class="attribute-value-text" [title]="comparisonStatement.operand2.value">
                                    {{comparisonStatement.operand2.value}}
                                </div>
                                <mat-icon class="down-arrow" [id]="'down-arrow-value'+logExpIndex+compIndex">keyboard_arrow_down</mat-icon>
                                <div class="dropdown parameter-dropdown" [id]="'value-dropdown'+logExpIndex+compIndex">
                                    <div class="category-container">
                                        <div *ngFor="let category of emailSenderCategoryMap | keyvalue" class="category" title="{{getDisplayName(category.key)}}"
                                            (click)="setAttribute('OPERAND2CATEGORY',category.key,comparisonStatement)"
                                            [ngClass]="{'active-category': comparisonStatement.operand2Category === category.key}"   >
                                            {{getDisplayName(category.key)}}
                                        </div>
                                    </div>
                                    <div class="parameters-container">
                                        <input class="op-search" placeholder="Search" type="text" (keydown)="stringInputHandler($event,50,comparisonStatement)" [(ngModel)]="op2ListSearch"/>
                                        <div class="search-filtered-dropdown">
                                            <div *ngFor="let item of searchFiltered(viewList)" class="dropdown-opt"
                                                (click)="setAttribute('VALUE',item.name,comparisonStatement)"
                                                [ngClass]="{'active-opt': comparisonStatement.operand2.value === item.name}">
                                                <span  [matTooltip]="item.name">{{item.name}}</span>
                                            </div>
                                            <div class="no-product" *ngIf="searchFiltered(viewList).length==0" (click)="$event.stopPropagation()">
                                                {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div *ngIf="comparisonStatement.operand1.showRange" class="optional-range-container width-30">
                        <div class="optional-range miner">
                            <div class="range-add" *ngIf="!comparisonStatement.hasOperandStartN" (click)="comparisonStatement.hasOperandStartN = true">
                                Add Min
                            </div>
                            <div *ngIf="comparisonStatement.hasOperandStartN" class="opt-value-container">
                                <div class="flex-with-space-between">
                                    <span class="material-symbols-outlined cursor-pointer small-text x-style button-hover-effect" (click)="comparisonStatement.hasOperandStartN = false">
                                        close
                                    </span>
                                    <span class="small-text">Atleast</span>
                                </div>
                                <input type="number" (keydown)="numberInputHandler($event, 5, comparisonStatement)" class="value-input-segmentation mini-size" #inp8 maxlength="5" min="0" max="99999"
                                    [(ngModel)]="comparisonStatement.operandStartN" 
                                    [ngClass]="{'cs-error-border':comparisonStatement.hasError && comparisonStatement.operand1.showRange && comparisonStatement.hasOperandStartN}">
                                <div>
                                    <span class="small-text" *ngIf="!comparisonStatement.hasOperandEndN">Times</span>
                                    <span class="small-text" *ngIf="comparisonStatement.hasOperandEndN">And</span>
                                </div>
                            </div>
                        </div>
                        <div class="optional-range maxer">
                            <div class="range-add" *ngIf="!comparisonStatement.hasOperandEndN" (click)="comparisonStatement.hasOperandEndN = true"> 
                                Add Max
                            </div>
                            <div *ngIf="comparisonStatement.hasOperandEndN" class="opt-value-container">
                                <div>
                                    <span class="small-text">Atmost</span>
                                </div>
                                <input type="number" (keydown)="numberInputHandler($event, 5, comparisonStatement)" class="value-input-segmentation mini-size" #inp9 maxlength="5" min="0" max="99999"
                                    [(ngModel)]="comparisonStatement.operandEndN"
                                    [ngClass]="{'cs-error-border':comparisonStatement.hasError && comparisonStatement.operand1.showRange && comparisonStatement.hasOperandEndN}">
                                <div class="flex-with-space-between">
                                    <span class="small-text">Times</span>
                                    <span class="material-symbols-outlined cursor-pointer small-text x-style button-hover-effect" (click)="comparisonStatement.hasOperandEndN = false">
                                        close
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div *ngIf="comparisonStatement.hasError" class="cs-error-text-popup">
                        {{comparisonStatement.hasError}}
                    </div>
    
                    <div class="condition-linker" *ngIf="compIndex!=0">
                        <div class="comparison-statement-logical-operator" *ngIf="comparisonStatement.logicalOperator === 'AND'">
                            {{'SEGMENT_PAGE.AND' | i18nTranslate}}
                        </div>
                        <div class="comparison-statement-logical-operator" *ngIf="comparisonStatement.logicalOperator === 'OR'">
                            {{'SEGMENT_PAGE.OR' | i18nTranslate}}
                        </div>
                    </div>
    
                </div>
    
                <div class="add-condition-container" (click)="addComparisionStatement(logicalExpression)">
                    <mat-icon class="mat-icon special-plus">add</mat-icon>
                    <span>{{'SEGMENT_PAGE.ADD_CONDITION' | i18nTranslate}}</span>
                </div>
        
                <div *ngIf="logExpIndex!=0" class="selector-container logical-exp-add-pos">
                    <div class="and-selector hover-effect-2 left-hover" (click)="selectLeCondition('AND',logicalExpression)"
                    [ngClass]="{'left-hover-active': logicalExpression.logicalOperator === 'AND' }">
                        {{'SEGMENT_PAGE.AND' | i18nTranslate}}
                    </div>
                    <div class="or-selector hover-effect-2 right-hover" (click)="selectLeCondition('OR',logicalExpression)"
                    [ngClass]="{'right-hover-active': logicalExpression.logicalOperator === 'OR' }">
                        {{'SEGMENT_PAGE.OR' | i18nTranslate}}
                    </div>
                    <div class="selector-line"></div>
                </div>
        
            </div>
        
            <div class="add-condition-container">
                <button class="primary-button margin-auto margin-bottom-20 button-hover-effect" (click)="addLogicalExpression('AND')">
                    {{'SEGMENT_PAGE.ADD_NEW_GROUP' | i18nTranslate}}
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="showSavePopup" class="save-popup-container" (click)="closeSavePopup()">
        <div class="save-popup" (click)="$event.stopPropagation()">
            <div class="popup-header">
                <div class="big-font width-175 margin-right-10">
                    {{'SHARED_PAGE.SELECT_STORES' | i18nTranslate}}
                </div>
                <div class="acp-close" (click)="closeSavePopup()">
                    <span class="material-symbols-outlined cursor-pointer close-pos">
                        close
                    </span>
                </div>
            </div>

            <div class="store-list" *ngIf="storeAvailability">
                <div class="check-row">
                    <mat-checkbox color="primary" (change)="addAllStores($event.checked)" [checked]="allStoresSelected()" [disabled]="qId || isSaving"></mat-checkbox>
                    <div>{{'SHARED_PAGE.SELECT_ALL' | i18nTranslate}}</div>
                </div>
                <div *ngFor="let store of storeList" class="check-row">
                    <mat-checkbox color="primary" [checked]="selectedStores.includes(store)" (change)="updateStoreData($event.checked, store)" [disabled]="qId || isSaving"></mat-checkbox>
                    <div>{{store.storeName}}</div>
                </div>
            </div>
            <div *ngIf="!storeAvailability" class="no-data">
                {{'SHARED_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
            </div>
            <div class="buttons-container-2">
                <button class="primary-button cancel-style button-hover-effect" (click)="closeSavePopup()">
                    {{'SHARED_PAGE.CANCEL' | i18nTranslate}}
                </button>
                <button class="primary-button button-hover-effect" (click)="saveSegment()" [disabled]="(storeAvailability && this.selectedStores.length==0)"
                    [style.background]="isSaving?'#fff':'#4379EE'">
                    <span *ngIf="!isSaving">
                        {{'SHARED_PAGE.SAVE' | i18nTranslate}}
                    </span>
                    <app-qubriux-loading *ngIf="isSaving" [size]="25" [loadingText]="'Saving...'"></app-qubriux-loading>
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="showPreviewPopup" class="save-popup-container" (click)="showPreviewPopup = false;">
        <div class="preview-popup" (click)="$event.stopPropagation()">
            <div class="popup-header">
                <div class="big-font width-175 margin-right-10">
                    {{'SEGMENT_PAGE.PREVIEW' | i18nTranslate}} 
                </div>
                <div class="acp-close" (click)="showPreviewPopup = false;">
                    <span class="material-symbols-outlined cursor-pointer close-pos">
                        close
                    </span>
                </div>
            </div>
            <div>
                Segment Customer Count : {{segmentCustomerCount}}
            </div>
            <div class="segmentation-default-info">
                <span class="material-symbols-outlined">info</span> Segmentation by default uses last 365 days of data.
                Please contact customer support for customized settings.
            </div>
        </div>
    </div>
    
</div>  
