<div class="activity-spinner-container" *ngIf="dataLoading">
    <mat-spinner diameter="45"></mat-spinner>
</div>
<div class="qb-h-100" *ngIf="!isNoDataAvailable">
    <div class="qb-analytics-main-container" *ngIf="!dataLoading">
        <div class="qb-analytics-main-sub-container">
            <app-breadcrumb></app-breadcrumb>
            <div class="graph-filters">
                <div class="qb-each-filter">
                    <h6 class="graph-options-text">View By:</h6>
                    <div class="matSelect-filters">
                        <mat-select class="margin-right-10" [(ngModel)]="totalCustomerRevenueTimeFrame" (selectionChange)="totalCustomerRevenueTimeFrameDropDownHandler()">
                            <mat-option *ngFor="let val of totalCustomerRevenueFrameList" [value]="val" selected>{{val}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div *ngFor="let filter of availableFilter | keyvalue">
                    <div class="qb-each-filter" *ngIf="filter.key === 'group_value'">
                        <div style="display: flex; flex-direction: row; gap: 20px;">
                            <div>
                                <h8 class="graph-options-text">Start Period</h8>
                                <div class="month-matSelect-filters" style="margin-top: 5px;">
                                    <mat-select class="margin-right-10" [(ngModel)]="selectedStartPeriod" #startPeriod (selectionChange)="startPeriodChange()">
                                        <span *ngFor="let val of filter.value; let i = index">
                                            <mat-option *ngIf="i < filter.value.indexOf(selectedEndPeriod)" [value]="val" selected>{{convertToMonthName(val)}}</mat-option>
                                        </span>
                                    </mat-select>
                                </div>
                            </div>
                            <div>
                                <h8  class="graph-options-text">End Period</h8>
                                <div class="month-matSelect-filters"style="margin-top: 5px;">
                                    <mat-select class="margin-right-10" [(ngModel)]="selectedEndPeriod" #endPeriod (selectionChange)="endPeriodChange(false)" (closed)="endPeriodChange(true)">
                                        <span *ngFor="let val of filter.value; let i = index">
                                            <mat-option [value]="val" *ngIf="i > filter.value.indexOf(selectedStartPeriod)" selected>{{convertToMonthName(val)}}</mat-option>                                            
                                        </span>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Advanced Filters
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-expansion-panel-content>
                    <div class="qb-d-flex" style="justify-content: space-between; flex-direction: column;">
                        <div class="graph-filters">
                            <div *ngFor="let filter of availableFilter | keyvalue">
                                <div class="qb-each-filter" *ngIf="filter.key !== 'Segment' && filter.key !== 'group_value'">
                                    <h6 class="graph-options-text">{{getDisplayFilterName(filter.key)}}</h6>
                                    <div class="matSelect-filters">
                                        <mat-select placeholder="Select" multiple class="margin-right-10" [(ngModel)]="selectedFilter[filter.key]">
                                            <div class="qb-all-select" (click)="selectAllOptions(filter.key, filter.value)">
                                                <mat-checkbox color="primary" style="pointer-events: none;" [checked]="selectedFilter[filter.key].length === filter.value.length">Select All</mat-checkbox>
                                            </div>
                                            <mat-option *ngFor="let val of filter.value" [value]="val" selected>{{val}}</mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                                <div class="qb-each-filter" *ngIf="filter.key === 'Segment'">
                                    <h6 class="graph-options-text" style="text-transform: capitalize;">{{getDisplayFilterName(filter.key)}}</h6>
                                    <div class="matSelect-filters">
                                        <mat-select placeholder="Select" multiple class="margin-right-10" [(ngModel)]="selectedFilter[filter.key]">
                                            <div class="qb-all-select" (click)="selectAllOptions(filter.key, filter.value)">
                                                <mat-checkbox color="primary" style="pointer-events: none;" [checked]="selectedFilter[filter.key].length === filter.value.length">Select All</mat-checkbox>
                                            </div>
                                            <mat-option *ngFor="let val of filter.value" [value]="val.id" selected>{{val.name}}</mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="apply-filterbutton">
                            <button class="primary-button" (click)="getAnalyticsForTotalCustomerSales(true)">
                                Apply Filter
                            </button>
                        </div>
                    </div>
                  </mat-expansion-panel-content>
                </mat-expansion-panel>
            </mat-accordion>
            <!-- <div class="graph-options">
                <div class="qb-d-flex">
                    <h6 class="qb-d-flex qb-ai-center qb-mr-6">View By:</h6>
                    <div class="mat-slect-timeframe">
                        <mat-select class="margin-right-10" [(ngModel)]="totalCustomerRevenueTimeFrame" (selectionChange)="totalCustomerRevenueTimeFrameDropDownHandler()">
                            <mat-option *ngFor="let val of totalCustomerRevenueFrameList" [value]="val" selected>{{val}}</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div> -->
            <div class="qb-analytics-data-container" style="justify-content: space-evenly;">
                <div  class="qb-analytics-graph-container qb-flex-1">
                    <h6 class="qb-analytics-heading">Total Revenue of Selected Period</h6>
                    <div class="qb-d-flex">
                        <div echarts [options]="totalSalesPieChart" style="height: 200px; width: 65%;"></div>
                        <div class="donut-legend-trend qb-fd-coloumn">
                            <div *ngFor="let data of totalRevenuePie">
                                <div class="qb-d-flex qb-ai-center qb-gap-10">
                                    <div [style.backgroundColor]="data.color" class="donut-legend-color-box qb-d-flex"></div>
                                    <div>
                                        <div>
                                            {{data.name}}
                                        </div>
                                        <div>
                                            {{currencySymbol}} {{data.count}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>
                </div>
                <div class="qb-analytics-graph-container qb-flex-1">
                    <h6 class="qb-analytics-heading">Customer Count of Selected Period</h6>
                    <div class="qb-d-flex">
                        <div echarts [options]="totalCustomerPieChart" style="height: 200px; width: 65%;"></div>
                        <div class="donut-legend-trend qb-fd-coloumn">
                            <div *ngFor="let data of totalCustomerPie">
                                <div class="qb-d-flex qb-ai-center qb-gap-10">
                                    <div [style.backgroundColor]="data.color" class="donut-legend-color-box qb-d-flex"></div>
                                    <div>
                                        <div>
                                            {{data.name}}
                                        </div>
                                        <div>
                                            {{data.count}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                </div>
                <div  class="qb-analytics-graph-container qb-flex-1">
                    <h6 class="qb-analytics-heading">Product's Count of Selected Period</h6>
                    <div class="qb-d-flex">
                        <div echarts [options]="totalProductPieChart" style="height: 200px; width: 65%;"></div>
                        <div class="donut-legend-trend qb-fd-coloumn">
                            <div *ngFor="let data of totalProductsPie">
                                <div class="qb-d-flex qb-ai-center qb-gap-10">
                                    <div [style.backgroundColor]="data.color" class="donut-legend-color-box qb-d-flex"></div>
                                    <div>
                                        <div>
                                            {{data.name}}
                                        </div>
                                        <div>
                                            {{data.count}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>
                </div>
            </div>
            <div class="qb-analytics-data-container">
                <div class="qb-analytics-graph-container qb-w-49">
                    <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
                        <div>
                            <h6 class="qb-analytics-heading">Revenue Over Time<span class="qb-i-div"
                                popoverclass="qb-my-custom-class"
                                placement="auto"
                                [ngbPopover]="popTierIamgeSetup1"
                                triggers="mouseenter:mouseleave"
                                >i
                            </span></h6>
                        </div>
                        <div class="qb-targeting-tactics-icon">
                            <span
                            class="qb-button"
                            appButtonDebounce
                            [throttleTime]="600"
                            (throttledClick)="openInsight('trend_report')"
                            >
                            <span class="material-icons-outlined icons-primary qb-segmet-buttons qb-rotated"> wb_incandescent </span>
                            &nbsp;&nbsp; Insights
                            </span>
                            <!-- <span class="qb-with-icon">
                              <md-icon>wb_incandescent</md-icon>Targeting Tactics
                            </span> -->
                        </div>
                    </div>
                    <ng-template #popTierIamgeSetup1>
                        <div class="qb-ng-template-text">
                            Track your business's total sales trends over time with our Monthly Total Sales graph. The Y-axis shows sales, while the X-axis displays years and months. Gain quick insights into peak periods and growth opportunities. Hover over data points for detailed information and make informed decisions for business success
                        </div>
                    </ng-template>
                    <div class="qb-d-flex">
                        <div class="qb-d-flex" style="flex-direction: column; justify-content: center; gap: 5px;">
                            <div class="customer-evolution-graph-ylabel" style="margin-left: 5px;">
                                <span>
                                    Revenue ({{currencySymbol}})
                                </span>
                            </div>
                        </div>
                        <div echarts [options]="totalSalesLinerChart" style="height: 300px; width: 100%;"></div>
                    </div>
                    
                </div>
                <div  class="qb-analytics-graph-container qb-w-49">
                    <h6 class="qb-analytics-heading">Customer Count Over Time<span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="auto"
                        [ngbPopover]="popTierIamgeSetup2"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span></h6>
                    <ng-template #popTierIamgeSetup2>
                        <div class="qb-ng-template-text">
                            Dive into your customer base evolution with our Monthly Customer Count graph. The Y-axis represents the total customer count, while the X-axis breaks down the data by year and month 
                        </div>
                    </ng-template>
                    <div class="qb-d-flex">
                        <div class="qb-d-flex" style="flex-direction: column; justify-content: center; gap: 5px;">
                            <div class="customer-evolution-graph-ylabel" style="margin-left: 5px;">
                                <span>
                                    Customer Count
                                </span>
                            </div>
                        </div>
                        <div echarts [options]="totalCustomerLineChart" style="height: 300px; width: 100%;"></div>                
                    </div>
                </div>
            </div>
            <div class="qb-analytics-data-container">
                <div  class="qb-analytics-graph-container qb-w-100">
                    <h6 class="qb-analytics-heading">Average Spend Per Visit<span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="auto"
                        [ngbPopover]="popTierIamgeSetup3"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span></h6>
                    <ng-template #popTierIamgeSetup3>
                        <div class="qb-ng-template-text">
                            Average Spend Per Visit (ASPV) is the average amount a user spends each time they visit, calculated by dividing total revenue by the number of visits. ASPV can be analyzed monthly, quarterly, and weekly to reveal spending trends. This helps businesses understand customer behavior and refine pricing and marketing strategies, enhancing customer engagement and profitability.
                        </div>
                    </ng-template>
                    <div echarts [options]="avgSpendPerVisitLinearChart" style="height: 300px; width: 100%;"></div>            
                </div>
            </div>
            <!-- <div class="graph-options">
                <div class="qb-d-flex">
                    <h6 class="select-month qb-mr-6">Selected {{totalCustomerRevenueTimeFrame}}:</h6>
                    <div class="mat-slect-timeframe">
                        <mat-select class="margin-right-10" [(ngModel)]="selectedMonth" (selectionChange)="monthChangeDropdown()">
                            <mat-option *ngFor="let val of availableMonths" [value]="val" selected>{{val}}</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div> -->
            <div class="graph-filters">
                <div class="qb-each-filter">
                    <h6 class="graph-options-text">Selected {{totalCustomerRevenueTimeFrame}}:</h6>
                    <div class="matSelect-filters">
                        <mat-select class="margin-right-10" [(ngModel)]="selectedMonth" (selectionChange)="monthChangeDropdown()">
                            <mat-option *ngFor="let val of selectedFilter['group_value']" [value]="val" selected>{{convertToMonthName(val)}}</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
            <div class="qb-analytics-data-container">
                <div  class="qb-analytics-graph-container qb-w-49">
                    <h6 class="qb-analytics-heading">Sales Growth in %<span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="auto"
                        [ngbPopover]="popTierIamgeSetup3"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span></h6>
                    <ng-template #popTierIamgeSetup3>
                        <div class="qb-ng-template-text">
                            Compare the current period sales data with respect to the previous period in the same year and same period as of the previous year
                        </div>
                    </ng-template>
                    <div echarts [options]="totalSalesComparison" style="height: 300px; width: 100%;"></div>
                    
                </div>
                <div  class="qb-analytics-graph-container qb-w-49">
                    <h6 class="qb-analytics-heading">Customer Growth in %<span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="auto"
                        [ngbPopover]="popTierIamgeSetup4"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span></h6>
                    <ng-template #popTierIamgeSetup4>
                        <div class="qb-ng-template-text">
                            Compare the current period customer count with respect to the previous period in the same year and same period as of the previous year
                        </div>
                    </ng-template>
                    <div echarts [options]="totalCustomerComparison" style="height: 300px; width: 100%;"></div>                
                </div>
            </div>
        </div>
    </div>
</div>
<div class="no-program-created" *ngIf="isNoDataAvailable">
    <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/graphNoData.svg" alt=""
        style="padding-top: 100px;" height="400px">
    <div style="margin-top: 15px;">
        No Data Available.
    </div>
</div>