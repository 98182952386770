<div style="display: flex; flex-direction: column; height: 100%">
  <div
    style="
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding: 0px 15px;
      height: 40px;
    "
  >
    <div class="tree-title">
      {{ "OFFER_PAGE.ADD_DEFAULT_EXCLUDION" | i18nTranslate }}
    </div>
    <mat-icon
      style="
        color: #efefef;
        background: #a9a9a9;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      "
      (click)="cancel()"
      data-button-name="Close"
      >cancel</mat-icon
    >
  </div>
  <div>
    <div class="qb-default-tabs">
      <div class="qb-tab-container">
        <div
          class="tab-label"
          [ngClass]="{ active: mainTopic == 'product' }"
          (click)="changeTab('product')"
          data-button-name="store_details"
        >
          Products
        </div>
        <div
          class="tab-label"
          [ngClass]="{ active: mainTopic == 'category' }"
          (click)="changeTab('category')"
        >
          Category
        </div>
      </div>
    </div>
  </div>
  <div id="productListDiv" class="qb-dialog-height" style="display: block" *ngIf="data.actionType.toUpperCase() === 'EXCLUSION'">
    <app-product-list-dialog
      #productList
      [dataBean]="dataBeanProduct"
      (getDataBeanData)="getData($event, true)"
    ></app-product-list-dialog>
  </div>
  <div id="categoryListDiv" class="qb-dialog-height" style="display: none" *ngIf="data.actionType.toUpperCase() === 'EXCLUSION'">
    <app-collection-list-dialog
      #categoryList
      [dataBean]="dataBeanCategory"
      (getDataBeanData)="getData($event, false)"
    ></app-collection-list-dialog>
  </div>
  <div *ngIf="data.actionType.toUpperCase() === 'SHOW'" class="qb-dialog-height">
    <app-personal-offer-list-view-dialog-box
    [dataBean]="dataBeanShow"
    >
    </app-personal-offer-list-view-dialog-box>
  </div>
</div>
