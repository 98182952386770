<div class="qb-main-conatiner-table">
    <div class="qb-header-wrapper">
        <div class="qb-d-flex qb-gap-4">
            <div class="qb-heading-text">
                {{data.heading}}
            </div>
        </div>
        <div class="cursor-pointer" (click)="close()">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill="#D8DEE6" />
                <g clip-path="url(#clip0_59_1782)">
                    <path d="M19 19L29 29M19 29L29 19" stroke="#4C5664" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_59_1782">
                        <rect width="24" height="24" fill="white" transform="translate(12 12)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
    <div class="qb-subheading-text-not" *ngIf="data['subHeading']">
        {{data.subHeading}}
    </div>

    <div *ngIf="data['type'] === 'CustomList' || data['type'] === 'Segments'">
        <p class="qb-subheading-text-not" style="margin: 0;">
            {{ data['type'] === 'CustomList' ? ('SHARED_PAGE.DELETE_CUSTOMER_LIST' | i18nTranslate) : ('SHARED_PAGE.DELETE_SEGMENTS' | i18nTranslate) }}
          </p>
        <div style="margin-top: 8px;">
            <div class="qb-table-data-wrapper-not-2" *ngFor="let key of data.entityMap | keyvalue">
                <span><strong> {{key.key}} : </strong></span> 
                <span *ngFor="let value of key.value; let last = last">{{ value }}<ng-container *ngIf="!last">, </ng-container></span>
              </div>
        </div>
    </div>
    <div class="qb-table-data-container">
        <div class="qb-table-data-scoll-no-wrapper">
            <div class="qb-table-data-wrapper-not">
                <ul *ngIf="data['type'] === 'OFFERS'">
                    <li *ngFor="let item of data['notDeletedList']"><b>{{ item.moduleName }}</b> :- {{item.responseMessage}}</li>
                </ul>
                <ul *ngIf="data['type'] === 'CAMPAIGN'">
                    <li class="qb-subheading-text-list" *ngFor="let item of data['notDeletedList']"><b>{{ item.moduleName }}</b> :- {{item.responseMessage}}</li>
                </ul>
                <ul *ngIf="data['type'] === 'SURVEY'">
                    <li *ngFor="let item of data['notDeletedList'].slice(1)"><b>{{item}}</b> </li>
                </ul>
                <div *ngIf="data['type'] === 'OFFERINJOURNEY'">
                    Offer is present in <span *ngFor="let journeyName of data['journeyList']" class="ls-name">{{journeyName}}</span> journey(s).You can either
                    <ul >
                        <li>Clone this offer and send the cloned offer </li>
                        <li>Remove this offer from the above listed journeys </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>