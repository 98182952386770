import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DialogPosition, MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { DatePipe, KeyValue } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { FilterDataServiceService } from "src/app/core/services/filter-data-service.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { CampaignStrategyActivateDialogComponent, ConfirmationDialogBoxComponent, ContactUsDialogComponent, NotDeletedListDialogBoxComponent } from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-campaign-overview",
  templateUrl: "./campaign-overview.component.html",
  styleUrls: ["./campaign-overview.component.css"],
})
export class CampaignOverviewComponent implements OnInit {
  @ViewChild("search") searchElement: ElementRef;
  activeCampaigns = 0;
  activeCampaignsRevenue = 0;
  totalRevenue = 0;
  searchArray = {};
  validWidgets = null;
  message: any = [];
  showAllSegments = false;
  subscription: Subscription;
  sortAsc = true;
  paginate = {
    pageSize: 10,
    pageIndex: 0,
  };
  campaignSegments = { key: "item" };
  isLeads = {key: "item"};
  isCustomer = {key: "item"};
  id;
  segementMap = {};
  changeOrder = false;
  filter: any;
  filterSub: Subscription;
  segment: any;
  segmentSub: Subscription;
  selected = "Last Modified";
  date;
  popupElementID = null;
  time;
  length;
  filters = {
    star: [],
    status: [],
    segments: [],
    tiers: [],
    customerLists: [],
  };

  tableLoading = true;
  data = {
    merchantId: this.tokenStorage.getMerchantId(),
    status: ["ACTIVE"],
    pageSize: this.paginate.pageSize,
    pageNumber: this.paginate.pageIndex,
    segmentIds: null,
    tierIds: null,
    customerListIds: null,
    segmentedFlag: false,
    statusList: [],
    favoriteFlag: null,
    lastModifiedFlag: false,
  };
  analyticsData = {
    merchantId: this.tokenStorage.getMerchantId(),
    status: ["ACTIVE"],
    storeId: this.tokenStorage.getqId() === 'null' ? null : this.tokenStorage.getqId()
  };
  index = 1;
  size = 10;
  searching = null;
  now;
  appSubscriptionPlan;
  selectedSortBy = "Desc";
  timeout;
  sub: Subscription;
  openRate = 0;
  clickedRate = 0;
  emailsSent = 0;
  revCurrency;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  mobileView;
  showFiltersDiv = false;
  segments = [];
  tiers = [];
  customerLists = [];
  loading = true;
  showClearAll = false;
  favouriteArray = ["STARRED", "UNSTARRED"];
  statusArray = ["ACTIVE", "DRAFT", "ENDED", "SCHEDULED"];
  store = "";
  isDemo;
  filtersBackup = {
    star: [],
    status: [],
    segments: [],
    tiers: [],
    customerLists: [],
  };
  mainTopic = "";
  customerSegmentAndTiers = [];
  customerSegmentAndTiersMap = {};
  selectedCampaignListPageWise = {
    1 : []
  };

  constructor(
    private router: Router,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private apiCall: ApiCallService,
    private _WidgetConfigService: WidgetConfigService,
    private tokenStorage: TokenStorageService,
    private filterData: FilterDataServiceService,
    private snackBar: MatSnackBar,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private snackbar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService
  ) {
    // setInterval(() => {
    //   this.now = new Date();
    // }, 1);
    window["screen-name"] = "CAMPAIGNS";
    // window.dataLayer.push({
    //   userId: this.tokenStorage.getMerchantId(),
    // });
  }

  @HostListener("document:click", ["$event"])
  clickout2(event2) {
    let ele = document.getElementById(this.popupElementID);
    if (ele) ele.setAttribute("style", "display:none");
  }

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    if (this.mobileView)
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight - 1
      ) {
        this.index += 1;
        this.onSearchChange(this.searching);
      }
  }

  ngOnInit() {
    this.isDemo = this.tokenStorage.getIsDemo();
    this.store = this.tokenStorage.getMerchantOnlineStore();
    if (this.mobileMedia.matches) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
    // this.getSegments();
    this.getCustomerSegmentsAndTiers();
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    // this.descendingOrder();
    this.getAnalytics();
    this.getWidgets();
    this.route.queryParams
      .subscribe(params => {

        if(params.type){
        this.mainTopic = params.type;
        }
        else{
          this.campaignTabChange('campaign')
        }
      }
    );
  }

  getAnalytics(){
    this.apiCall.getTotalRevenue(this.analyticsData).subscribe((response) => {
      this.activeCampaigns = response["body"].activeCampaigns;
      this.activeCampaignsRevenue =
        Math.round(
          (response["body"].activeCampaignsRevenue + Number.EPSILON) * 100
        ) / 100;
        if(this.isDemo) {
          this.totalRevenue = 1734.68;
        }
      this.totalRevenue =
        Math.round((response["body"].totalRevenue + Number.EPSILON) * 100) /
        100;
    });
  }

  addSegmentFilter(value,segmentType?) {
    if (this.filters[segmentType].indexOf(value) != -1) {
      this.filters[segmentType].splice(
        this.filters[segmentType].indexOf(value),
        1
      );
      if (this.filters[segmentType].indexOf("ALL") != -1 && value !== "ALL") {
        this.filters[segmentType].splice(
          this.filters[segmentType].indexOf("ALL"),
          1
        );
      }
    } else {
      this.filters[segmentType].push(value);
      if (value === "ALL")
        for (let i = 0; i < this.segments.length; i++) {
          if (
            this.filters[segmentType].indexOf(this.segments[i].id) == -1 &&
            this.segments[i].isActive === true
          )
            this.filters[segmentType].push(this.segments[i].id);
        }
    }
    for (let i = 0; i < this.segments.length; i++) {
      if (this.filters[segmentType].indexOf(this.segments[i].id) == -1)
        if (this.filters[segmentType].indexOf("ALL") != -1)
          this.filters[segmentType].splice(
            this.filters[segmentType].indexOf("ALL"),
            1
          );
    }
    if (value === "ALL") {
      if (this.filters[segmentType].indexOf("ALL") == -1) {
        this.filters[segmentType] = [];
      }
    }
    if (
      this.filters["star"].length > 0 ||
      this.filters["status"].length > 0 ||
      this.filters[segmentType].length > 0
    ) {
      this.showClearAll = true;
    } else {
      this.showClearAll = false;
    }
  }

  clear() {
    this.filters["star"] = [];
    this.filters["status"] = [];
    this.filters["segments"] = [];
    this.filters["tiers"] = [];
    this.filters["customerLists"] = [];
    this.showClearAll = false;
  }

  addStatusFilter(value) {
    if (this.filters["status"].indexOf(value) != -1) {
      this.filters["status"].splice(this.filters["status"].indexOf(value), 1);
      if (this.filters["status"].indexOf("ALL") != -1 && value !== "ALL") {
        this.filters["status"].splice(this.filters["status"].indexOf("ALL"), 1);
      }
    } else {
      this.filters["status"].push(value);
      if (value === "ALL")
        this.statusArray.forEach((customer) => {
          if (this.filters["status"].indexOf(customer) == -1)
            this.filters["status"].push(customer);
        });
    }
    this.statusArray.forEach((customer) => {
      if (this.filters["status"].indexOf(customer) == -1)
        if (this.filters["status"].indexOf("ALL") != -1)
          this.filters["status"].splice(
            this.filters["status"].indexOf("ALL"),
            1
          );
    });
    if (value === "ALL") {
      if (this.filters["status"].indexOf("ALL") == -1) {
        this.filters["status"] = [];
      }
    }
    if (
      this.filters["star"].length > 0 ||
      this.filters["status"].length > 0 ||
      this.filters["segments"].length > 0 ||
      this.filters["tiers"].length > 0 ||
      this.filters["customerLists"].length > 0
    ) {
      this.showClearAll = true;
    } else {
      this.showClearAll = false;
    }
  }

  addFilter(value) {
    if (this.filters["star"].indexOf(value) != -1) {
      this.filters["star"].splice(this.filters["star"].indexOf(value), 1);
      if (this.filters["star"].indexOf("ALL") != -1 && value !== "ALL") {
        this.filters["star"].splice(this.filters["star"].indexOf("ALL"), 1);
      }
    } else {
      this.filters["star"].push(value);
      if (value === "ALL")
        this.favouriteArray.forEach((customer) => {
          if (this.filters["star"].indexOf(customer) == -1)
            this.filters["star"].push(customer);
        });
    }
    this.favouriteArray.forEach((customer) => {
      if (this.filters["star"].indexOf(customer) == -1)
        if (this.filters["star"].indexOf("ALL") != -1)
          this.filters["star"].splice(this.filters["star"].indexOf("ALL"), 1);
    });
    if (value === "ALL") {
      if (this.filters["star"].indexOf("ALL") == -1) {
        this.filters["star"] = [];
      }
    }

    if (
      this.filters["star"].length > 0 ||
      this.filters["status"].length > 0 ||
      this.filters["segments"].length > 0 ||
      this.filters["tiers"].length > 0 ||
      this.filters["customerLists"].length > 0
    ) {
      this.showClearAll = true;
    } else {
      this.showClearAll = false;
    }
  }

  toggleStrategyStatus(event, item, qid, storeName) {
    if(this.tokenStorage.getqId() === qid || qid === undefined || (this.tokenStorage.getqId() ==='null' && qid === null)){
      let status = this.searchArray[item.key].strategy.status;
      let time = new Date().toString().split(" ")[4];
      let date = new Date();
      let latest_date = this.datepipe.transform(
        new Date(date).toISOString(),
        "yyyy-MM-dd"
      );
      let data = {
        repeat: null,
        repeatEnd: null,
        startDate: latest_date + " " + time,
      };
      if (
        !(
          item.value.strategy.status == "ACTIVE" ||
          item.value.strategy.status == "SCHEDULED"
        )
      ) {
        this.apiCall.activateStrategy(item.key, data).subscribe(
          (response) => {
            this.searchArray[item.key].strategy.status = "ACTIVE";
            this.descendingOrder();
            this.getAnalytics();
          },
          (error) => {
            this.searchArray[item.key].strategy.status = status;
            event.source.checked = false;
            this.snackBar.open(this._i18nDynamicTranslate.transform("Journey cannot be activated", ['POS_PAGE']), "", {
              duration: 2000,
            });
          }
        );
      } else {
        event.source.checked = true;
        const dialogRef = this.dialog.open(
          CampaignStrategyActivateDialogComponent,
          {
            panelClass: "no-padding-dialog-popup",
            width: this.mobileMedia.matches ? "90%" : "30%",
            data: {
              operation: "DEACTIVATE",
              campaignName: item.value.strategy.strategyName,
            },
          }
        );
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.apiCall.deactivateStrategy(item.key).subscribe(
              (response) => {
                this.searchArray[item.key].strategy.status = "ENDED";
                event.source.checked = false;
                this.descendingOrder();
                this.getAnalytics();
              },
              (err) => {
                this.searchArray[item.key].strategy.status = status;
                event.source.checked = true;
                this.snackBar.open(this._i18nDynamicTranslate.transform("Journey cannot be deactivated", ['POS_PAGE']), "", {
                  duration: 2000,
                });
              }
            );
          }
        });
      }
    }
    else{
      event.source.checked = true;
      // const dialogRef = this.dialog.open(ContactUsDialogComponent, {
      //   panelClass: "no-padding-dialog-popup",
      //   height: "auto",
      //   disableClose: true,
      //   width: "380px",
      //   data: {
      //     header: "Access Denied",
      //     body: qid === null ? this._i18nDynamicTranslate.transform("Admin created, You don't have the permission to deactivate.", ['POS_PAGE']) : this._i18nDynamicTranslate.transform("This Journey is created by", ['POS_PAGE']) + " " + storeName + " " + this._i18nDynamicTranslate.transform("You don't have the permission to deactivate.", ['POS_PAGE']),
      //   },
      // });
      // dialogRef.afterClosed().subscribe((result) => {
      //   // this.disabled = false;
      // });
      const dialogRefDeleted = this.dialog.open(NotDeletedListDialogBoxComponent, {
        panelClass: "customer-dialog-popup",
        width: "540px",
        disableClose: false,
        data:{
          heading: "OOPS!",
          type: "CAMPAIGN",
          subHeading: qid === null ? this._i18nDynamicTranslate.transform("Admin created, You don't have the permission to deactivate.", ['POS_PAGE']) : this._i18nDynamicTranslate.transform("This Journey is created by", ['POS_PAGE']) + " " + storeName + " " + this._i18nDynamicTranslate.transform("You don't have the permission to deactivate.", ['POS_PAGE']),
        },
      });
      dialogRefDeleted.afterClosed().subscribe((res)=>{
        
      });
    }
  }

  showSearch() {
    this.searchElement.nativeElement.focus();
  }

  refreshPaginateddata(event) {
    if(this.size !== event.pageSize){
      this.clearCampaignList();
    }
    this.index = event.pageIndex + 1;
    this.size = event.pageSize;
    if(Object.keys(this.selectedCampaignListPageWise).indexOf(this.index.toString()) === -1){
      this.selectedCampaignListPageWise[this.index] = [];
    }
    console.log(this.selectedCampaignListPageWise);
    if (this.index >= 1) this.onSearchChange(this.searching);
  }

  showSegments(event, i) {
    let ele = document.getElementById(this.popupElementID);
    if (ele) ele.setAttribute("style", "display:none");
    let id;
    if (event.srcElement.id === "") id = event.srcElement.parentElement.id;
    event.stopPropagation();
    let element = document
      .getElementById(id)
      .getElementsByClassName("campaign-overview-segment-popup")[0];
    element.setAttribute("style", "display:flex");
    this.popupElementID = "popup" + i;
  }

  private onKeySearch(event,value: any) {
    clearTimeout(this.timeout);
    var $this = this;
    let searchValue2 = value?.trim();
      this.timeout = setTimeout(function () {
        if (event.data !== ' '){
          $this.onSearchChange(searchValue2);
        }
      }, 600);
    }

  array = {};

  onSearchChange(searchValue: string): void {
    if (this.mobileView) {
      this.tableLoading = false;
    } else {
      this.tableLoading = true;
    }
    if (searchValue !== this.searching && this.mobileView) {
      this.index = 1;
      this.array = {};
    }
    this.searching = searchValue;
    if (this.searching != null)
      if (this.searching.length <= 0) {
        searchValue = null;
        this.searching = null;
        this.index = 1;
      }
    if (!this.mobileView) {
      this.searchArray = {};
    }
    this.campaignSegments = { key: "item" };
    this.isLeads = {key: "item"};
    this.isCustomer = {key: "item"};
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.apiCall
      .getSearchCampaign(
        this.tokenStorage.getMerchantId(),
        this.searching,
        this.index,
        this.size,
        this.data.statusList,
        this.data.segmentIds,
        this.data.tierIds,
        this.data.customerListIds,
        this.data.favoriteFlag
      )
      .subscribe(
        (response) => {
          if (!this.mobileView) {
            this.array = (response["body"])["strategies"];
          } else {
            for (let key in (response["body"])["strategies"]) {
              this.array[key] = (response["body"])["strategies"][key];
            }
          }
          this.length = (response["body"])["count"];
          for (let key in this.array) {
            let segmentIdArray = [];
            let value = this.array[key];
            let json = JSON.parse(value["strategy"].definition);
            this.campaignSegments[key] = [...(json.segmentIds || []), ...(json.tierIds || []), ...(json.customerListIds || []), ...(json.employeeListIds || [])];
            this.campaignSegments[key].forEach((element) => {
              let val = this.customerSegmentAndTiersMap[element];
              if(!val) {
                val = this.customerSegmentAndTiersMap[element]; //fetching tier name from tierId
              }
              segmentIdArray.push(val);
            });
            this.campaignSegments[key] = segmentIdArray;
            this.isCustomer[key] = json.allCustomer !== undefined ? json.allCustomer : null;
            this.isLeads[key] = json.allLead !== undefined ? json.allLead : null;
          }
          this.searchArray = this.array;
          this.addDate();
          for (let key in this.searchArray) {
            if (this.searchArray[key].analytics.emailStats === null) {
              this.searchArray[key].analytics.addCartCount = 0;
              this.searchArray[key].analytics.openRate = 0;
              this.searchArray[key].analytics.clickRate = 0;
              this.searchArray[key].analytics.numberOfEmailsSent = 0;
            } else {
              this.searchArray[key].analytics.addCartCount =
                this.searchArray[key].analytics.emailStats.addCartCount;
              this.searchArray[key].analytics.openRate =
                this.searchArray[key].analytics.emailStats.openedRate;
              this.searchArray[key].analytics.clickRate =
                this.searchArray[key].analytics.emailStats.clickedRate;
              this.searchArray[key].analytics.numberOfEmailsSent =
                this.searchArray[key].analytics.emailStats.totalEmailsSent;
            }
          }
          this.tableLoading = false;
        },
        (err) => {
          this.tableLoading = false;
        }
      );
  }

  viewMoreID;

  viewMore(id) {
    if (this.viewMoreID == id) {
      this.viewMoreID = -1;
    } else {
      this.viewMoreID = id;
    }
  }

  addDate() {
    for (let item in this.searchArray) {
      let dates = this.searchArray[item]["strategy"]["lastModifiedDate"];
      this.date = this.datepipe.transform(
        new Date(dates.replace(' ','T')).toISOString(),
        "dd MMM yyyy"
      );
      let x = dates.split(" ");
      let y = x[1].split(":");
      if (x[1][0] + x[1][1] > 12)
        this.time = x[1][0] + x[1][1] + ":" + x[1][3] + x[1][4];
      else this.time = x[1][0] + x[1][1] + ":" + x[1][3] + x[1][4];
      this.searchArray[item]["strategy"]["date"] = this.date;
      this.searchArray[item]["strategy"]["time"] = this.time;
    }
  }

  // getSegments() {
  //   this.apiCall.getAllCustomerSegments().subscribe((response) => {
  //     (response["body"]).forEach((element) => {
  //       if(this.tokenStorage.getMerchantOnlineStore() === 'shopify' || (element['segmentType'] !== 'STANDARD' && this.tokenStorage.getMerchantOnlineStore() !== 'shopify'))
  //       this.segments.push({
  //         id: element["id"],
  //         segmentName: element["segmentName"],
  //         isActive: element["isActive"],
  //       });
  //     });
  //     let json = (response["body"]);
  //     json.forEach((element) => {
  //       this.segementMap[element.id] = element.segmentName;
  //     });
  //     this.loading = false;
  //   });
  // }


  getCustomerSegmentsAndTiers() {
    this.apiCall.getCustomerSegmentsAndTiers().subscribe(response => {
      let res = (response['body']);
      for (let i = 0; i < res.length; i++) {
        this.customerSegmentAndTiers.push({
          id: res[i]["id"],
          name: res[i]["segmentName"],
          isActive: res[i]["isActive"],
          status: false,
          category: res[i]["category"],
        });
        this.customerSegmentAndTiersMap[res[i]["id"]] = res[i]["segmentName"];
        if(res[i].category == 'Segment') {
          if(this.tokenStorage.getMerchantOnlineStore() === 'shopify' || (res[i]['segmentType'] !== 'STANDARD' && this.tokenStorage.getMerchantOnlineStore() !== 'shopify')) {
            this.segments.push({
              id: res[i]["id"],
              segmentName: res[i]["segmentName"],
              isActive: res[i]["isActive"],
            });
          }
        }
        if(res[i].category == 'Tier') {
          this.tiers.push({
            id: res[i]["id"],
            tierName: res[i]["segmentName"],
            isActive: res[i]["isActive"],
          });
        }
        if(res[i].category == 'CustomerList') {
          this.customerLists.push({
            id: res[i]["id"],
            customerListName: res[i]["segmentName"],
            isActive: res[i]["isActive"],
          });
        }
      }
      this.loading = false;
      this.descendingOrder();
    },
    error => {
      this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching getCustomerSegmentsAndTiers data", ['POS_PAGE']), 2000);
    });
  }

  segmentFilterSearch = '';
  tierFilterSearch = '';
  customerListFilterSearch = '';
  openFiltersDiv() {
    this.showFiltersDiv = true;
    this.segmentFilterSearch = '';
    this.tierFilterSearch = '';
    this.customerListFilterSearch = '';
    this.getFilteredSegments();
    this.getFilteredTiers();
    this.getFilteredCustomerList();
  }

  getFilteredSegments() {
    return this.segments.filter((segment)=>segment.segmentName.toLowerCase().includes(this.segmentFilterSearch.toLowerCase()));
  }

  getFilteredTiers() {
    return this.tiers.filter((tier)=>tier.tierName.toLowerCase().includes(this.tierFilterSearch.toLowerCase()));
  }

  getFilteredCustomerList() {
    return this.customerLists.filter((customerList)=>customerList.customerListName.toLowerCase().includes(this.customerListFilterSearch.toLowerCase()));
  }


  closeFiltersDiv() {
    this.filters = this.filtersBackup;
    this.showFiltersDiv = false;
  }

  async createCampaign() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      // this.apiCall.getCreditsThreshold(null, null).subscribe((response) => {
      //   if (response["body"] === "true") {
      //     this.snackBar.open("Not enough credits to create campaign");
      //   } else {
          this.router.navigate(
            [
              "../creation",
              {
                "return-url": "campaign/overview",
                id: null,
                recommendation: false,
              },
            ],
            { relativeTo: this.route }
          );
        }
    //   });
    // }
  }

  valueAscOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): any => {
    if (this.selected == "Last Modified") {
      if (this.sortAsc)
        if (a.value["strategy"]["date"] === b.value["strategy"]["date"])
          return a.value["strategy"]["time"] < b.value["strategy"]["time"]
            ? -1
            : b.value["strategy"]["time"] < a.value["strategy"]["time"]
            ? 1
            : 0;
        else
          return new Date(a.value["strategy"]["date"]) <
            new Date(b.value["strategy"]["date"])
            ? -1
            : new Date(b.value["strategy"]["date"]) <
              new Date(a.value["strategy"]["date"])
            ? 1
            : 0;
      else if (a.value["strategy"]["date"] === b.value["strategy"]["date"])
        return a.value["strategy"]["time"] > b.value["strategy"]["time"]
          ? -1
          : b.value["strategy"]["time"] > a.value["strategy"]["time"]
          ? 1
          : 0;
      else
        return new Date(a.value["strategy"]["date"]) >
          new Date(b.value["strategy"]["date"])
          ? -1
          : new Date(b.value["strategy"]["date"]) >
            new Date(a.value["strategy"]["date"])
          ? 1
          : 0;
    } else if (this.selected == "Orders Placed") {
      if (this.sortAsc)
        return a.value["analytics"]["totalNewOrders"] <
          b.value["analytics"]["totalNewOrders"]
          ? -1
          : b.value["analytics"]["totalNewOrders"] <
            a.value["analytics"]["totalNewOrders"]
          ? 1
          : 0;
      else
        return a.value["analytics"]["totalNewOrders"] >
          b.value["analytics"]["totalNewOrders"]
          ? -1
          : b.value["analytics"]["totalNewOrders"] >
            a.value["analytics"]["totalNewOrders"]
          ? 1
          : 0;
    } else if (this.selected == "Cart Additions") {
      if (this.sortAsc)
        return a.value["analytics"]["addCartCount"] <
          b.value["analytics"]["addCartCount"]
          ? -1
          : b.value["analytics"]["addCartCount"] <
            a.value["analytics"]["addCartCount"]
          ? 1
          : 0;
      else
        return a.value["analytics"]["addCartCount"] >
          b.value["analytics"]["addCartCount"]
          ? -1
          : b.value["analytics"]["addCartCount"] >
            a.value["analytics"]["addCartCount"]
          ? 1
          : 0;
    } else {
      if (this.sortAsc)
        return a.value["analytics"]["totalRevenue"] <
          b.value["analytics"]["totalRevenue"]
          ? -1
          : b.value["analytics"]["totalRevenue"] <
            a.value["analytics"]["totalRevenue"]
          ? 1
          : 0;
      else
        return a.value["analytics"]["totalRevenue"] >
          b.value["analytics"]["totalRevenue"]
          ? -1
          : b.value["analytics"]["totalRevenue"] >
            a.value["analytics"]["totalRevenue"]
          ? 1
          : 0;
    }
  };

  async deleteStrategy(id, qid, storeName) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      if(this.tokenStorage.getqId() === qid || qid === undefined || (this.tokenStorage.getqId() ==='null' && qid === null)){
        let dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
          panelClass: "no-padding-dialog-popup",
          width: this.mobileMedia.matches ? "90%" : "20%",
          data: {
            subject: this._i18nDynamicTranslate.transform("Delete Journey", ['POS_PAGE']),
            message: this._i18nDynamicTranslate.transform("Do you wish to delete this Journey?", ['POS_PAGE']),
            cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
            successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
            "data-widget": "CAMPAIGN LISTING",
            "data-button": "Confirm: Delete",
            "data-item": id,
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            Object.keys(this.searchArray).map((key) => {
              if (id == key)
                if (
                  this.searchArray[key]["strategy"]["status"] === "ACTIVE" ||
                  this.searchArray[key]["strategy"]["status"] === "SCHEDULED"
                ){
                  const dialogRefDeleted = this.dialog.open(NotDeletedListDialogBoxComponent, {
                    panelClass: "customer-dialog-popup",
                    width: "540px",
                    disableClose: false,
                    data:{
                      heading: "OOPS!",
                      type: "CAMPAIGN",
                      subHeading:" Journeys can't be deleted. Please deactivate Journeys and try again."
                    },
                  });
                }
                else
                  this.apiCall.deleteStrategy(id).subscribe(
                    (response) => {
                      this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Journey Deleted Successfully", ['POS_PAGE']), 2000);
                      // Object.keys(this.searchArray).map((key) => {
                      //   if (id == key) {
                      //     delete this.searchArray[id];
                      //   }
                      // });
                      if(this.searchElement.nativeElement.value.length === 0){
                        this.reOrder();
                      }
                      else{
                        this.onSearchChange(this.searchElement.nativeElement.value);
                      }
                    },
                    (err) => {
                      this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to delete", ['POS_PAGE']), 2000);
                    }
                  );
            });
          }
        });
      }
      else{
        const dialogRefDeleted = this.dialog.open(NotDeletedListDialogBoxComponent, {
          panelClass: "customer-dialog-popup",
          width: "540px",
          disableClose: false,
          data:{
            heading: "OOPS!",
            type: "CAMPAIGN",
            subHeading: qid === null ? this._i18nDynamicTranslate.transform("Admin created, You don't have the permission to delete.", ['POS_PAGE']) : this._i18nDynamicTranslate.transform("This Journey is created by", ['POS_PAGE']) + " " + storeName + " " + this._i18nDynamicTranslate.transform("You don't have the permission to delete.", ['POS_PAGE']),
          },
        });
        dialogRefDeleted.afterClosed().subscribe((res)=>{
          
        });
      }
      }
  }

  canEdit(qid){
    let curQid = this.tokenStorage.getqId()=='null'?null:this.tokenStorage.getqId();
    return (curQid === qid);
  }

  async editStrategy(item, qid, storeName) {
    console.log(qid, this.tokenStorage.getqId())
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      if(this.tokenStorage.getqId() === qid || qid === undefined || (this.tokenStorage.getqId() ==='null')){
        this.openStrategy(item);
      }
      else{
        const dialogRef = this.dialog.open(ContactUsDialogComponent, {
          panelClass: "no-padding-dialog-popup",
          height: "auto",
          disableClose: true,
          width: "380px",
          data: {
            acess: this._i18nDynamicTranslate.transform("Don't touch what's not yours...", ['POS_PAGE']),
            body: qid === null ? this._i18nDynamicTranslate.transform("You can not edit this item as it was created by another user. If you need to reuse the same you can clone it and edit your copy.", ['POS_PAGE']) : this._i18nDynamicTranslate.transform("This Journey is created by", ['POS_PAGE']) + " " + storeName + " " + this._i18nDynamicTranslate.transform("You don't have the permission to edit.", ['POS_PAGE']),
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          // this.disabled = false;
        });
      }
    }
  }

  async cloneStrategy(item) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      let adminCreated = {
        storeId:null,
        storeName:"ADMIN",
        qid:null
      };
      let data = {
        merchantId : this.tokenStorage.getMerchantId(),
        id : item.value.strategy.id,
        createdBy: this.tokenStorage.getqId() === "null" ?
          adminCreated: { storeId: this.tokenStorage.getqId(),storeName:this.tokenStorage.getStoreName(),qid:this.tokenStorage.getqId()}
      };
      this.apiCall.cloneStrategy(data).subscribe(
        result => {
          this.snackbar.openSnackBar('Journey Cloned Successfully', 2000);
          this.openStrategy({value:{strategy:{id:result['body']}}});
        },
        err => {
          this.snackbar.openSnackBar('Failed to clone Journey', 2000);
          console.log(err);
        }
      );
    }
  }

  openStrategy(item) {
    this.router.navigate(
      [
        "../creation",
        {
          "return-url": "campaign/overview",
          id: item["value"]["strategy"]["id"],
          recommendation: false,
        },
      ],
      { relativeTo: this.route }
    );
  }

  updateStrategy(strategyId) {
    this.id = strategyId;
    this.clearCampaignList();
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  applyFilter() {
    if (this.filters["segments"].indexOf("ALL") == -1)
      this.data.segmentIds = this.filters["segments"];
    else this.data.segmentIds = null;

    if (this.filters["tiers"].indexOf("ALL") == -1)
      this.data.tierIds = this.filters["tiers"];
    else this.data.tierIds = null;

    if (this.filters["customerLists"].indexOf("ALL") == -1)
      this.data.customerListIds = this.filters["customerLists"];
    else this.data.customerListIds = null;

    if (this.filters["status"]?.length > 0 && this.filters["status"].length != 4)
      this.data.statusList = this.filters["status"];
    else this.data.statusList = null;


    if (this.filters["star"].indexOf("ALL") == -1)
      if (this.filters["star"].length == 1)
        if (this.filters["star"].indexOf("STARRED") == -1)
          this.data.favoriteFlag = false;
        else this.data.favoriteFlag = true;
      else this.data.favoriteFlag = null;
    else this.data.favoriteFlag = null;


    this.filters["star"] = this.filters["star"];
    this.filters["status"] = this.filters["status"];
    this.filters["segments"] = this.filters["segments"];
    this.filters["tiers"] = this.filters["tiers"];
    this.filters["customerLists"] = this.filters["customerLists"];
    this.index = 1;
    this.filtersBackup = this.filters
    this.onSearchChange(this.searching);
    this.closeFiltersDiv();
  }

  removeFilter(filter,id?) {
    if(filter == 'STARRED' || filter == 'UNSTARRED')
      this.addFilter(filter);
    else if(filter == 'ACTIVE' || filter == 'DRAFT' || filter == 'SCHEDULED' || filter == 'ENDED')
      this.addStatusFilter(filter);
    else if(filter == 'segments' || filter == 'tiers' || filter == 'customerLists')
      this.addSegmentFilter(id,filter);
    else {}

    this.applyFilter();
  }

  hasFilter() {
    for(let eachFilterType in this.filters) {
      if(this.filters[eachFilterType]?.length>0)
        return true;
    }
    return false;
  }

  ascendingOrder() {
    this.sortAsc = true;
    this.changeOrder = true;
    this.onSearchChange(null);
    this.selectedSortBy = "Asc";
  }

  descendingOrder() {
    this.sortAsc = false;
    this.changeOrder = true;
    this.onSearchChange(null);
    this.selectedSortBy = "Desc";
  }

  reOrder() {
    if (this.selectedSortBy === "Desc") {
      this.descendingOrder();
    } else {
      this.ascendingOrder();
    }
  }

  sort() {
    this.changeOrder = true;
    this.onSearchChange(null);
  }

  addToFavourite(item, value) {
    this.apiCall
      .addStrategyTofavourite(
        this.tokenStorage.getMerchantId(),
        item.key,
        value
      )
      .subscribe((response) => {
        item.value.strategy.isFavorite = value;
        this.descendingOrder()
      });
  }

  campaignTabChange(value){
    this.mainTopic = value;
    this.router.navigate(["/app/campaign/overview"], {
      queryParams: { type: value },
    });
  }

  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }

  getCreatedByData(data, type){
    let datajson = JSON.parse(data);
    if(type === 'qid'){
      return datajson?.qid
    }
    else{
      return datajson?.storeName ? datajson?.storeName : "-";
    }
  }

  async deleteSelectedCampaign(){
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      let dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: this.mobileMedia.matches ? "90%" : "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Delete Journey", ['POS_PAGE']),
          message: this._i18nDynamicTranslate.transform("Do you wish to delete these Journey?", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
          "data-widget": "CAMPAIGN LISTING",
          "data-button": "Confirm: Delete",
          "data-item": null,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        let selectedCampaignList = Object.values(this.selectedCampaignListPageWise).flat();
        if (result) {
          this.deletSelectCampaignApi(selectedCampaignList).then(
            (res) => {
              this.loading = false;
              if (res && res["body"]) {
                const notDeletedList: any[] = res['body'].filter(item => !item.deleted);
                if(notDeletedList.length === 0){
                  this.clearCampaignList();
                  this.snackbar.openSnackBar("Journey Deleted Successfully", 5000);
                  if(this.searchElement.nativeElement.value.length === 0){
                    this.reOrder();
                  }
                  else{
                    this.onSearchChange(this.searchElement.nativeElement.value);
                  }
                }
                else{
                  const dialogRefDeleted = this.dialog.open(NotDeletedListDialogBoxComponent, {
                    panelClass: "customer-dialog-popup",
                    width: "540px",
                    disableClose: false,
                    data:{
                      notDeletedList: notDeletedList,
                      heading: "OOPS!",
                      type: "CAMPAIGN",
                      subHeading: notDeletedList.length + "/" + selectedCampaignList.length + " Journeys can't be deleted:"
                    },
                  });
                  dialogRefDeleted.afterClosed().subscribe((res)=>{
                    if(notDeletedList.length !== selectedCampaignList.length){
                      if(this.searchElement.nativeElement.value.length === 0){
                        this.reOrder();
                      }
                      else{
                        this.onSearchChange(this.searchElement.nativeElement.value);
                      }             
                    }
                    this.clearCampaignList();
                  });
                }

              } else {
              }
            },
            (err) => {
              console.log(JSON.parse(err));
            }
          );
        }
      });
    }
  }

  checkCampaignList(event, type, id){
    console.log(event)
    if(event){
      if(type === 'single'){
        this.selectedCampaignListPageWise[this.index].push(id);
      }
      else{
        for(let data in this.searchArray){
          console.log(data);
          let i = this.selectedCampaignListPageWise[this.index].indexOf(data);
          if(i === -1){
            this.selectedCampaignListPageWise[this.index].push(data);
          }
        }
      }
    }
    else{
      if(type === 'single'){
        let index = this.selectedCampaignListPageWise[this.index].indexOf(id);
        this.selectedCampaignListPageWise[this.index].splice(index, 1);
      }
      else{
        this.selectedCampaignListPageWise[this.index] = [];
      }
    }
  }

  getCampaignListLength(myObj){
    return !myObj?0:Object.keys(myObj).length;
  }

  checkIfPresent(id){
    return this.selectedCampaignListPageWise[this.index]?.length > 0 && (this.selectedCampaignListPageWise[this.index].includes(id.toString()) || this.selectedCampaignListPageWise[this.index].includes(id));
  }

  deletSelectCampaignApi(data){
    return new Promise((resolve, reject) => {
      this.apiCall.deleteCampaigns(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error.body);
        }
      );
    });
  }

  clearCampaignList(){
    for (const key in this.selectedCampaignListPageWise) {
      if (this.selectedCampaignListPageWise.hasOwnProperty(key)) {
        this.selectedCampaignListPageWise[key] = [];
      }
    }
  }
}
